import { CancelRounded, CheckCircle } from '@mui/icons-material'
import { ToggleButton, Tooltip } from '@mui/material'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useTranslation } from 'react-i18next'

const InactiveButtonGroup = ({
  value,
  onChange,
  iconified = false,
  style,
}: {
  value?: boolean
  onChange: (event: React.MouseEvent<HTMLElement>, newValue: boolean) => void
  iconified?: boolean
  style?: React.CSSProperties
}) => {
  const { t } = useTranslation()
  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      exclusive
      value={value ? 'inactive' : 'active'}
      onChange={(event, value) => onChange(event, value === 'inactive')}
      sx={{ ...style }}
    >
      <ToggleButton value="active" color="success">
        {iconified ? (
          <Tooltip title={String(t('Active'))}>
            <CheckCircle />
          </Tooltip>
        ) : (
          t('Active')
        )}
      </ToggleButton>
      <ToggleButton value="inactive" color="error">
        {iconified ? (
          <Tooltip title={String(t('Inactive'))}>
            <CancelRounded />
          </Tooltip>
        ) : (
          t('Inactive')
        )}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default InactiveButtonGroup
