export const multipleFormatToIso = (value: string): string => {
  let d = value.split('/')
  if (d.length === 1) {
    d = value.split('-')
  }
  const year = d[2].startsWith('+') ? d[2].substring(1) : d[2]
  return `${year}-${d[1]}-${d[0]}`
}
export const formatDateToSlash = (value: string): string => {
  let d = value.split('/')
  if (d.length === 1) {
    d = value.split('-')
  }
  return d[2] + '/' + d[1] + '/' + d[0]
}
