import { Search } from '@mui/icons-material'
import { InputAdornment, Stack } from '@mui/material'
import FormTextField from 'components/FormTextField'
import { pickBy } from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FiltersForm = {
  name?: string
  endpoint?: string
}

const SearchField = ({
  onSubmit,
  filters,
  ...textFieldProps
}: {
  placeholder?: string
  endpoint?: string
  filters?: Record<string, string>
  isLoading: boolean
  onSubmit: (data: Record<string, string>) => void
}) => {
  const { t } = useTranslation()
  const { handleSubmit, control } = useForm<FiltersForm>({
    mode: 'all',
  })

  const submitHandler = (data: FiltersForm): void => {
    onSubmit(
      pickBy(
        {
          name: data.name && data?.name.length ? data.name : undefined,
        },
        (v) => v !== undefined
      ) as Record<string, string>
    )
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      void handleSubmit(submitHandler)(event)
    }
  }

  return (
    <Stack direction="row">
      <FormTextField
        control={control}
        name={'name'}
        placeholder={textFieldProps.placeholder ?? t('Search')}
        style={{
          backgroundColor: 'white',
          borderRadius: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '15rem',
          height: '4rem',
          boxShadow: '0 2px 2px 0 #bababa',
        }}
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search sx={{ color: '#e0e0e0' }} />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        onKeyDown={handleKeyDown}
      />
    </Stack>
  )
}

export default SearchField
