import { CssBaseline, ThemeProvider } from '@mui/material'
import App from 'App'
import Progress from 'components/Progress'
import theme from 'config/theme'
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'

const container = document.getElementById('root')
if (!container) {
  throw new Error('Illegal state: container not found')
}
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Suspense fallback={<Progress />}>
        <App />
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>
)
