import { Image } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { ACTIVITIES_PATH } from 'AppRoutes'
import FormAsynchronousAutocomplete from 'components/FormAsynchronousAutocomplete'
import FormCheckbox from 'components/FormCheckbox'
import FormDatePicker from 'components/FormDatePicker'
import FormSelect from 'components/FormSelect'
import FormSlider from 'components/FormSlider'
import FormTextField from 'components/FormTextField'
import ResponsiveDialog from 'components/ResponsiveDialog'
import {
  deleteActivity,
  getCategories,
  getOrganizations,
  postActivity,
  putActivity,
} from 'data/api'
import {
  ActivityDetails,
  activityTypes,
  Category,
  CreateActivity,
  Price,
} from 'data/models/Activity'
import { Address, administrativeAreas } from 'data/models/Address'
import DeleteConfirmationDialog from 'features/components/DeleteConfirmationDialog'
import FormCategoriesAutocomplete from 'features/components/FormCategoriesAutocomplete'
import ImageDialog from 'features/components/ImageDialog'
import { useMe } from 'features/ProtectedRoute'
import { pickBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import geocode from 'utils/geocoding'
import { showError, showInfoMessage } from 'utils/notifier'

type EditActivityForm = Omit<
  CreateActivity,
  'categories' | 'organization' | 'prices' | 'address'
> & {
  ageRange: [number, number]
  categories: Category[]
  organization: { id: string; label: string }
  prices?: { price?: string; currency?: string; description?: string }[]
  address?: Address
}

const EditActivityDialog = ({
  activity,
  open,
  onClose,
}: {
  activity?: ActivityDetails
  open: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const [defaultCountry, setDefaultCountry] = useState('Belgium')
  const navigate = useNavigate()
  const { id } = useParams() as { id: string }

  useEffect(() => {
    const translatedDefaultCountry = t('Belgium')
    setDefaultCountry(translatedDefaultCountry)
  }, [t])

  const { mutate: removeActivity, isLoading: removeActivityLoading } =
    useMutation(() => deleteActivity(id), {
      onSuccess: () => {
        showInfoMessage(snackbar, t('Activity deleted'))
        navigate(ACTIVITIES_PATH)
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    setValue,
    getValues,
    trigger,
  } = useForm<EditActivityForm>({
    mode: 'all',
    defaultValues: {
      ...activity,
      ageRange: [activity?.minAge ?? 2, activity?.maxAge ?? 6],
      tags: activity?.tags.map((tag) => tag.name) || [],
      organization: {
        id: activity?.organization.id,
        label: activity?.organization.id,
      },
      prices: activity?.prices?.map((price) => ({
        price: price.price.toString(),
        currency: price.currency,
        description: price.description,
      })),
    },
  })

  const [inputOrganization, setInputOrganization] = useState<string>('')
  const queryClient = useQueryClient()
  const snackbar = useSnackbar()

  const [isActivityImageDialogOpen, setIsActivityImageDialogOpen] =
    useState(false)
  const [isDeleteActivityDialogOpen, setDeleteActivityDialogOpen] =
    useState(false)

  const { data: categories, isLoading: categoriesIsLoading } = useQuery(
    ['categories'],
    getCategories,
    {
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { user } = useMe()

  const { data: organizationItems, isLoading: organizationsIsLoading } =
    useQuery(
      [
        'organizations',
        {
          name: inputOrganization,
          organizer:
            user.role === 'organizer' || user.role === 'organizer-premium'
              ? user.id
              : undefined,
        },
      ],
      () =>
        getOrganizations(
          pickBy({
            name: inputOrganization ? inputOrganization : undefined,
            organizer:
              user.role === 'organizer' || user.role === 'organizer-premium'
                ? user.id
                : undefined,
          }) as Record<string, string>
        ),
      {
        onError: (e) => showError(snackbar, e, t),
      }
    )

  const organizations = organizationItems?.items

  const { mutate: addActivity, isLoading: addActivityLoading } = useMutation(
    postActivity,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['activities'])
        showInfoMessage(snackbar, t('Activity added'))
        onClose()
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: updateActivity, isLoading: updateActivityLoading } =
    useMutation(putActivity, {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries([
          'activities',
          { id: variables.id },
        ])
        showInfoMessage(snackbar, t('Activity updated'))
        onClose()
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const handleImageSelected = (image: string) => {
    setIsActivityImageDialogOpen(false)
    setValue('image', image, { shouldDirty: true })
  }

  const onSubmit = async (data: EditActivityForm): Promise<void> => {
    const { organization, categories, ageRange, ...newActivity } = data
    const minAge = ageRange[0]
    const maxAge = ageRange[1]

    if (!data.status) {
      data.status = 'draft'
    }

    const status = data.status

    const address = data.address
      ? Object.values(data.address).filter((v) => v !== '').length > 0
        ? {
            ...data.address,
            country: 'Belgium',
            location: await geocode(
              `${String(data.address.street)}, ${String(
                data.address.postalCode
              )}, ${String(data.address.city)}, ${String(
                data.address.administrativeArea
              )}, ${String(data.address.country)}`
            ),
          }
        : undefined
      : undefined

    const prices = data.prices
      ?.map((rawPrice) => {
        try {
          const price =
            rawPrice?.price !== undefined
              ? parseFloat(rawPrice.price)
              : undefined

          if (price === undefined || price === null || isNaN(price)) {
            return null
          }

          return {
            price,
            currency: 'EUR',
            description:
              rawPrice.description && rawPrice.description.length
                ? rawPrice.description
                : undefined,
          }
        } catch (e) {
          return null
        }
      })
      .filter((v) => v !== null && (v?.price === 0 || v?.price > 0)) as Price[]

    const editedActivity = {
      ...newActivity,
      minAge,
      maxAge,
      categories: categories.map((c) => c.name),
      organization: organization.id,
      prices,
      address,
      status,
    }

    activity
      ? updateActivity({
          id: activity.id,
          ...editedActivity,
        })
      : addActivity({
          ...editedActivity,
          tags: [],
        })
  }

  const validateDate = (fromDate: string, toDate: string) => {
    return fromDate && toDate ? new Date(fromDate) < new Date(toDate) : true
  }

  const isFormValid = isDirty && isValid
  const isLoading =
    addActivityLoading ||
    updateActivityLoading ||
    categoriesIsLoading ||
    removeActivityLoading

  return (
    <>
      <ResponsiveDialog
        title={activity ? t('Edit activity') : t('Add activity')}
        open={open}
        onClose={onClose}
      >
        <DialogContent>
          <Grid container mt={1} spacing={2}>
            <Grid item xs={12}>
              <FormAsynchronousAutocomplete
                control={control}
                name="organization"
                label={t('Organization')}
                data-role="organization-select"
                options={
                  organizations?.map((o) => ({ id: o.id, label: o.id })) || []
                }
                loading={organizationsIsLoading}
                onInputChange={setInputOrganization}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name="name"
                label={t('Name of the activity')}
                data-role="name-input"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={4}>
              <FormSelect
                control={control}
                name="type"
                label={t('Type')}
                data-role="type-select"
                options={activityTypes}
                showNoneOption
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={8}>
              <FormCategoriesAutocomplete
                control={control}
                name="categories"
                data-role="categories-select"
                categories={categories || []}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name="description"
                label={t('Description')}
                data-role="description-input"
                fullWidth
                multiline
                rows={10}
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <Typography variant="subtitle1" color="text.secondary">
                {t('Dates')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDatePicker
                control={control}
                name="fromDate"
                label={t('From')}
                data-role="startDate-input"
                rules={{
                  validate: (value: string) => {
                    const toDate = getValues()['toDate']
                    return !toDate || (toDate && validateDate(value, toDate))
                  },
                }}
                fullWidth
                onChange={() => trigger('fromDate')}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDatePicker
                control={control}
                name="toDate"
                label={t('To')}
                data-role="endDate-input"
                rules={{
                  validate: (value: string) =>
                    validateDate(getValues()['fromDate'], value),
                }}
                fullWidth
                onChange={() => trigger('toDate')}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="text.secondary">
                {t('Age')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormSlider
                control={control}
                name="ageRange"
                min={0}
                max={18}
                data-role="age-range"
                marks={Array.from({ length: 37 }, (_, index) => {
                  const value = index * 0.5
                  return {
                    value: value,
                    label: Number.isInteger(value)
                      ? value.toString()
                      : undefined,
                  }
                })}
                step={0.5}
                valueLabelDisplay="auto"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="text.secondary">
                {t('Prices')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                control={control}
                name="prices[0].price"
                label={t('Amount')}
                data-role="price-input"
                fullWidth
                type="number"
                min={0}
                inputProps={{ min: 0 }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                control={control}
                name="prices[0].description"
                label={t('Description')}
                data-role="priceDescription-input"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                control={control}
                name="prices[1].price"
                label={t('Amount')}
                data-role="price-input"
                fullWidth
                type="number"
                min={0}
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                control={control}
                name="prices[1].description"
                label={t('Description')}
                data-role="priceDescription-input"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                control={control}
                name="prices[2].price"
                label={t('Amount')}
                data-role="price-input"
                fullWidth
                type="number"
                min={0}
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                control={control}
                name="prices[2].description"
                label={t('Description')}
                data-role="priceDescription-input"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                control={control}
                name="prices[3].price"
                label={t('Amount')}
                data-role="price-input"
                fullWidth
                type="number"
                min={0}
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                control={control}
                name="prices[3].description"
                label={t('Description')}
                data-role="priceDescription-input"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                control={control}
                name="prices[4].price"
                label={t('Amount')}
                data-role="price-input"
                fullWidth
                type="number"
                min={0}
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                control={control}
                name="prices[4].description"
                label={t('Description')}
                data-role="priceDescription-input"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <Typography variant="subtitle1" color="text.secondary">
                {t('Address')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name="address.street"
                label={t('Street')}
                data-role="street-input"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                control={control}
                name="address.postalCode"
                label={t('Postal code')}
                data-role="postCode-input"
                required
                fullWidth
                min={1000}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                control={control}
                name="address.city"
                label={t('City')}
                data-role="city-input"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSelect
                control={control}
                name="address.administrativeArea"
                label={t('Administrative area')}
                data-role="area-select"
                options={Object.values(administrativeAreas)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSelect
                control={control}
                name="address.country"
                label={t('Country')}
                data-role="country-select"
                options={[defaultCountry]}
                defaultValue={defaultCountry}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <Typography variant="subtitle1" color="text.secondary">
                {t('Options')}
              </Typography>
            </Grid>
            <Grid item>
              <FormCheckbox
                control={control}
                name="externalApproval"
                label={t('ONE approval')}
                data-role="one-checkbox"
              />
            </Grid>
            <Grid item>
              <FormCheckbox
                control={control}
                name="reducedMobilityAccess"
                label={t('PMR access')}
                data-role="pmr-checkbox"
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <Typography variant="subtitle1" color="text.secondary">
                {t('Image')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="image"
                control={control}
                defaultValue={undefined}
                render={({ field: { value } }) => (
                  <Box sx={{ width: 200, height: 100, position: 'relative' }}>
                    <Button onClick={() => setIsActivityImageDialogOpen(true)}>
                      {value ? (
                        <Box
                          component="img"
                          src={value}
                          alt={value}
                          sx={{
                            width: 300,
                            minHeight: 200,
                            backgroundColor: 'LightGray',
                          }}
                        />
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            width: 300,
                            minHeight: 200,
                            backgroundColor: 'LightGray',
                          }}
                        >
                          <Image color="disabled" fontSize="large" />
                        </Box>
                      )}
                    </Button>
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {activity && (
            <>
              <LoadingButton
                onClick={() => setDeleteActivityDialogOpen(true)}
                loading={isLoading}
              >
                {t('Delete')}
              </LoadingButton>
              <DeleteConfirmationDialog
                open={isDeleteActivityDialogOpen}
                onClose={() => setDeleteActivityDialogOpen(false)}
                onConfirm={removeActivity}
                entityName={activity.name}
              />
            </>
          )}

          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            data-role="submit-button"
            disabled={!isFormValid}
            loading={isLoading}
          >
            {t('Save')}
          </LoadingButton>
        </DialogActions>
        <ImageDialog
          open={isActivityImageDialogOpen}
          onClose={() => setIsActivityImageDialogOpen(false)}
          onImageSelected={handleImageSelected}
        />
      </ResponsiveDialog>
    </>
  )
}

export default EditActivityDialog
