import { Help } from '@mui/icons-material'
import {
  Avatar,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { passwordReset } from 'data/firebase'
import { useSnackbar } from 'notistack'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { showError, showInfoMessage } from 'utils/notifier'

const ForgotPassword = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const [email, setEmail] = useState('')

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault()
    }

    passwordReset(email)
      .then(() => {
        showInfoMessage(
          snackbar,
          t('An email with reset instructions has been sent')
        )
        navigate('/')
      })
      .catch((e) => {
        showError(snackbar, e, t)
      })
  }

  const isInvalid = email === ''

  return (
    <Container maxWidth="xs">
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        minHeight="90vh"
      >
        <Avatar sx={{ bgcolor: 'primary.main' }}>
          <Help />
        </Avatar>
        <Typography variant="h6">{t(`Reset Password`)}</Typography>
        <Typography variant="subtitle1" align="center">
          {t(
            `Enter your email and receive instructions on how to reset your password`
          )}
        </Typography>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
          component="form"
          onSubmit={handleSubmit}
          alignSelf="stretch"
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t('Email address')}
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            disabled={isInvalid}
            type="submit"
            variant="contained"
            color="primary"
          >
            {t(`Send`)}
          </Button>
        </Stack>
      </Stack>
    </Container>
  )
}

export default ForgotPassword
