import Progress from 'components/Progress'
import { UserDetails } from 'data/models/User'
import { Outlet, useOutletContext } from 'react-router'
import ParentPage from './ParentPage'
import SignInPage from './signin/SignInPage'
import useSession from './useSession'

type ContextType = { user: UserDetails }

const ProtectedRoute = () => {
  const session = useSession()

  if (session.isLoading || session.isFetching) {
    return <Progress />
  }

  if (session.data) {
    if (!session.data.role || session.data.role === 'parent') {
      return <ParentPage />
    } else {
      return <Outlet context={{ user: session.data }} />
    }
  }

  return <SignInPage />
}

export default ProtectedRoute

export const useMe = () => useOutletContext<ContextType>()
