import {
  FilterAlt,
  KeyboardArrowDown,
  KeyboardArrowUp,
  NavigateNext,
} from '@mui/icons-material'
import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { getOrganizationPath } from 'AppRoutes'
import { getOrganizations } from 'data/api'
import { Organization } from 'data/models/Organization'
import InactiveButtonGroup from 'features/components/InactiveButtonGroup'
import { useMe } from 'features/ProtectedRoute'
import { orderBy, pickBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { showError } from 'utils/notifier'
import transformSearchParams from 'utils/searchParams'
import OrganizationsFilters from './OrganizationsFilters'

const ROWS_PER_PAGE = 20

const OrganizationsTable = ({
  organizations: initialOrganizations,
  page,
  rowsPerPage = 20,
  totalCount,
  onPageChange,
  onInactiveChange,
}: {
  organizations: Organization[]
  page?: number
  rowsPerPage?: number
  totalCount?: number
  onPageChange: (page: number) => void
  onInactiveChange: (ids: string[] | string, value: boolean) => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()

  useEffect(() => {
    setOrganizations(initialOrganizations)
  }, [initialOrganizations])

  const [organizations, setOrganizations] = useState(initialOrganizations)
  const [sortColumn, setSortColumn] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')

  const sortTable = (column: string) => {
    return (event: React.MouseEvent<HTMLTableCellElement>) => {
      event.preventDefault()
      const sortedOrganizations = orderBy(
        organizations,
        column,
        sortDirection === 'asc' ? 'asc' : ['desc', 'asc']
      )
      setSortColumn(column)
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
      setOrganizations(sortedOrganizations)
    }
  }

  const [searchParams, setSearchParams] = useSearchParams()
  const { user } = useMe()

  const filters = useMemo(
    () =>
      pickBy(
        {
          ...transformSearchParams(searchParams),
          limit: String(ROWS_PER_PAGE),
          organizer:
            user.role === 'organizer' || user.role === 'organizer-premium'
              ? user.id
              : undefined,
        },
        (v) => v !== undefined
      ) as Record<string, string>,
    [searchParams, user]
  )

  const { isLoading: organizationsIsLoading, isPreviousData } = useQuery(
    ['organizations', filters],
    () => getOrganizations(filters),
    {
      keepPreviousData: true,
      onError: (e) => showError(snackbar, e, t),
      onSuccess: (data) => {
        if (
          !isPreviousData &&
          data?.items.length === 1 &&
          (user.role === 'organizer' || user.role === 'organizer-premium')
        ) {
          navigate(`/organizations/${data.items[0].id}`)
        }
      },
    }
  )

  const isLoading = organizationsIsLoading || isPreviousData

  const handleFiltersChange = (newFilters: Record<string, string>) => {
    setSearchParams({
      ...newFilters,
      offset: String(0),
    })
  }

  const [filtered, setFiltered] = useState(false)

  const handleFilterClick = () => {
    setFiltered(!filtered)
  }

  const EnhancedTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Tooltip title={String(t('Filters'))}>
          <FilterAlt
            sx={{
              width: '1.3rem',
              color: filtered ? 'primary.main' : '#bdbdbd',
              cursor: 'pointer',
              marginRight: !filtered ? '0.3rem' : 0,
            }}
            onClick={handleFilterClick}
          />
        </Tooltip>
        {filtered ? (
          <FilterTableToolbar />
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t('Organizations')}
          </Typography>
        )}
      </Toolbar>
    )
  }

  const FilterTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Stack direction={'row'} spacing={2}>
          <OrganizationsFilters
            isLoading={isLoading}
            onSubmit={handleFiltersChange}
            filters={filters}
          />
        </Stack>
      </Toolbar>
    )
  }

  const handleInactiveChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: boolean,
    organizationId: string
  ) => {
    onInactiveChange(organizationId, newValue)
  }

  return (
    <>
      <EnhancedTableToolbar />
      <TableContainer>
        <Table data-role="organization-list">
          <TableHead>
            <TableRow>
              <TableCell
                onClick={sortTable('createdAt')}
                sx={{ cursor: 'pointer' }}
              >
                <Stack
                  direction="row"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {t('Created at')}
                  {sortColumn === 'createdAt' &&
                    (sortDirection === 'asc' ? (
                      <KeyboardArrowUp fontSize="small" />
                    ) : (
                      <KeyboardArrowDown fontSize="small" />
                    ))}
                </Stack>
              </TableCell>
              <TableCell onClick={sortTable('id')} sx={{ cursor: 'pointer' }}>
                <Stack
                  direction="row"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {t('Name')}
                  {sortColumn === 'id' &&
                    (sortDirection === 'asc' ? (
                      <KeyboardArrowUp fontSize="small" />
                    ) : (
                      <KeyboardArrowDown fontSize="small" />
                    ))}
                </Stack>
              </TableCell>
              <TableCell
                onClick={sortTable('address.administrativeArea')}
                sx={{ cursor: 'pointer' }}
              >
                <Stack
                  direction="row"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {t('Administrative area')}
                  {sortColumn === 'address.administrativeArea' &&
                    (sortDirection === 'asc' ? (
                      <KeyboardArrowUp fontSize="small" />
                    ) : (
                      <KeyboardArrowDown fontSize="small" />
                    ))}
                </Stack>
              </TableCell>
              {user.role === 'admin' && (
                <TableCell
                  onClick={sortTable('inactive')}
                  sx={{ cursor: 'pointer' }}
                >
                  <Stack
                    direction="row"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {t('Status')}
                    {sortColumn === 'inactive' &&
                      (sortDirection === 'asc' ? (
                        <KeyboardArrowUp fontSize="small" />
                      ) : (
                        <KeyboardArrowDown fontSize="small" />
                      ))}
                  </Stack>
                </TableCell>
              )}
              <TableCell>{t('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((organization) => {
              return (
                <TableRow
                  data-role="organization-item"
                  key={organization.id}
                  hover
                >
                  <TableCell>
                    {new Date(organization.createdAt).toLocaleDateString(
                      'en-GB',
                      { year: '2-digit', month: '2-digit', day: '2-digit' }
                    )}
                  </TableCell>
                  <TableCell>{organization.id}</TableCell>
                  <TableCell>
                    {organization.address?.administrativeArea}
                  </TableCell>
                  {user.role === 'admin' && (
                    <TableCell>
                      <InactiveButtonGroup
                        value={organization.inactive}
                        onChange={(event, newValue) =>
                          handleInactiveChange(event, newValue, organization.id)
                        }
                        iconified
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <Stack direction="row">
                      <IconButton
                        data-role="organization-item-link"
                        onClick={() =>
                          navigate(getOrganizationPath(organization.id))
                        }
                      >
                        <NavigateNext />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount || initialOrganizations.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[{ label: '', value: -1 }]}
        page={page || 0}
        onPageChange={(event, newPage) => onPageChange(newPage)}
      />
    </>
  )
}

export default OrganizationsTable
