import { Activity } from './Activity'
import { Child } from './Child'
import { Communication } from './Communication'
import { User } from './User'

export type RegistrationStatus = 'confirmed' | 'rejected' | null

export type ActivityRegistration = {
  id: string
  activity: string
  child: Child
  comment?: string
  status: RegistrationStatus
  createdAt: string
  fromDate: string
  toDate: string
  imageRights: boolean
  price: { price: number; currency: string; description: string }
  paid: number
  communications: Communication[]
}

export type Registration = Omit<ActivityRegistration, 'activity' | 'child'> & {
  activity: Activity
  child: Child & { user: User }
  payments?: Payment[]
}

export type Payment = {
  createdAt: string
  amount: number
  registration: string // id
  child: string // id
}

export type UpdateRegistration = { id: string } & Partial<Registration>

export const isRegistration = (value: unknown): value is Registration =>
  typeof (value as Registration).activity === 'object'

export const isRegistrations = (value: unknown): value is Registration[] =>
  Array.isArray(value) && value.every(isRegistration)
