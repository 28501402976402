import {
  Delete,
  FilterAlt,
  KeyboardArrowDown,
  KeyboardArrowUp,
  NavigateNext,
} from '@mui/icons-material'
import {
  alpha,
  Checkbox,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { getActivityPath, getOrganizationPath } from 'AppRoutes'
import { getActivities } from 'data/api'
import { Activity, ActivityStatus } from 'data/models/Activity'
import ActivityStatusButtonGroup from 'features/components/ActivityStatusButtonGroup'
import { useMe } from 'features/ProtectedRoute'
import { orderBy, pickBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'
import { showError } from 'utils/notifier'
import transformSearchParams from 'utils/searchParams'
import ActivitiesFilters from './ActivitiesFilters'

const ROWS_PER_PAGE = 20

const ActivitiesTable = ({
  activities: initialActivities,
  page,
  rowsPerPage = 25,
  totalCount,
  onPageChange,
  onActivityStatusChange,
  hideOrganization = false,
  onDelete,
  onActivitiesStatusChange,
  selectedActivities: selected = [],
  onSelect,
  onExport,
}: {
  activities: Activity[]
  page?: number
  rowsPerPage?: number
  totalCount?: number
  onPageChange?: (page: number) => void
  onActivityStatusChange: (id: string, status: ActivityStatus) => void
  hideOrganization?: boolean
  onDelete: () => void
  onActivitiesStatusChange: (status: ActivityStatus) => void
  selectedActivities: Activity[]
  onSelect: (activities: Activity[]) => void
  onExport: () => void
}) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const [internalPage, setInternalPage] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const { user } = useMe()

  const filters = useMemo(
    () =>
      pickBy(
        {
          ...transformSearchParams(searchParams),
          limit: String(ROWS_PER_PAGE),
          organizer:
            user.role === 'organizer' || user.role === 'organizer-premium'
              ? user.id
              : undefined,
        },
        (v) => v !== undefined
      ) as Record<string, string>,
    [searchParams, user]
  )

  const { isLoading: activitiesIsLoading, isPreviousData } = useQuery(
    ['activities', filters],
    () => getActivities({ ...filters }),
    {
      keepPreviousData: true,
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const isLoading = activitiesIsLoading || isPreviousData

  const isItemSelected = (id: string) =>
    selected.find((a) => a.id === id) !== undefined

  useEffect(() => {
    setActivities(initialActivities)
  }, [initialActivities])

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (initialActivities && event.target.checked) {
      onSelect([...new Set([...selected, ...initialActivities])])
    } else {
      onSelect(
        selected.filter(
          (sa) => !initialActivities.map((a) => a.id).includes(sa.id)
        )
      )
    }
  }

  const handleFiltersChange = (newFilters: Record<string, string>) => {
    setSearchParams({
      ...newFilters,
      offset: String(0),
    })
  }

  const [activities, setActivities] = useState(initialActivities)
  const [sortColumn, setSortColumn] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')

  const sortTable = (column: string) => {
    return () => {
      const sortedActivities = orderBy(
        activities,
        column,
        sortDirection === 'asc' ? 'asc' : ['desc', 'asc']
      )
      setSortColumn(column)
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
      setActivities(sortedActivities)
    }
  }

  const handleRowClick = (
    event: React.MouseEvent<unknown>,
    activity: Activity
  ) => {
    const selectedIndex = selected.indexOf(activity)
    let newSelected: Activity[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, activity)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    onSelect(newSelected)
  }

  const [filtered, setFiltered] = useState(false)

  const handleFilterClick = () => {
    setFiltered(!filtered)
  }

  const EnhancedTableToolbar = ({ numSelected }: { numSelected: number }) => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t(`${numSelected} selected`, { count: numSelected })}
          </Typography>
        ) : (
          <>
            <Tooltip title={String(t('Filters'))}>
              <FilterAlt
                sx={{
                  width: '1.3rem',
                  color: filtered ? 'primary.main' : '#bdbdbd',
                  cursor: 'pointer',
                  marginRight: !filtered ? '0.3rem' : 0,
                }}
                onClick={handleFilterClick}
              />
            </Tooltip>
            {filtered ? (
              <FilterTableToolbar />
            ) : (
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {t('Activities')}
              </Typography>
            )}
          </>
        )}
        {numSelected > 0 && (
          <Stack direction={'row'} spacing={2}>
            <Tooltip title={String(t('Delete'))}>
              <IconButton
                onClick={() => {
                  onDelete()
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <ActivityStatusButtonGroup
              onChange={(event, newStatus) => {
                onActivitiesStatusChange(newStatus)
              }}
              iconified
            />
          </Stack>
        )}
      </Toolbar>
    )
  }

  const FilterTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Stack direction={'row'} spacing={2}>
          <ActivitiesFilters
            isLoading={isLoading}
            onSubmit={handleFiltersChange}
            filters={filters}
          />
        </Stack>
      </Toolbar>
    )
  }

  const allItemsSelected = activities.map((a) => a.id).every(isItemSelected)

  return (
    <>
      <EnhancedTableToolbar numSelected={selected.length} />
      <TableContainer>
        <Table data-role="activity-list">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={
                    !allItemsSelected &&
                    activities.length > 0 &&
                    selected.length > 0
                  }
                  checked={allItemsSelected}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell
                onClick={sortTable('createdAt')}
                sx={{ cursor: 'pointer' }}
              >
                <Stack
                  direction="row"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {t('Created at')}
                  {sortColumn === 'createdAt' &&
                    (sortDirection === 'asc' ? (
                      <KeyboardArrowUp fontSize="small" />
                    ) : (
                      <KeyboardArrowDown fontSize="small" />
                    ))}
                </Stack>
              </TableCell>
              <TableCell onClick={sortTable('name')} sx={{ cursor: 'pointer' }}>
                <Stack
                  direction="row"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {t('Name')}
                  {sortColumn === 'name' &&
                    (sortDirection === 'asc' ? (
                      <KeyboardArrowUp fontSize="small" />
                    ) : (
                      <KeyboardArrowDown fontSize="small" />
                    ))}
                </Stack>
              </TableCell>
              {!hideOrganization && (
                <TableCell
                  onClick={sortTable('organization.id')}
                  sx={{ cursor: 'pointer' }}
                >
                  <Stack
                    direction="row"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {t('Organization')}
                    {sortColumn === 'organization.id' &&
                      (sortDirection === 'asc' ? (
                        <KeyboardArrowUp fontSize="small" />
                      ) : (
                        <KeyboardArrowDown fontSize="small" />
                      ))}
                  </Stack>
                </TableCell>
              )}
              <TableCell onClick={sortTable('type')} sx={{ cursor: 'pointer' }}>
                <Stack
                  direction="row"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {t('Type')}
                  {sortColumn === 'type' &&
                    (sortDirection === 'asc' ? (
                      <KeyboardArrowUp fontSize="small" />
                    ) : (
                      <KeyboardArrowDown fontSize="small" />
                    ))}
                </Stack>
              </TableCell>
              <TableCell
                onClick={sortTable('fromDate')}
                sx={{ cursor: 'pointer' }}
              >
                <Stack
                  direction="row"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {t('Dates')}
                  {sortColumn === 'fromDate' &&
                    (sortDirection === 'asc' ? (
                      <KeyboardArrowUp fontSize="small" />
                    ) : (
                      <KeyboardArrowDown fontSize="small" />
                    ))}
                </Stack>
              </TableCell>
              <TableCell
                onClick={sortTable('address.city')}
                sx={{ cursor: 'pointer' }}
              >
                <Stack
                  direction="row"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {t('Location')}
                  {sortColumn === 'address.city' &&
                    (sortDirection === 'asc' ? (
                      <KeyboardArrowUp fontSize="small" />
                    ) : (
                      <KeyboardArrowDown fontSize="small" />
                    ))}
                </Stack>
              </TableCell>
              <TableCell
                onClick={sortTable('status')}
                sx={{ cursor: 'pointer' }}
              >
                <Stack
                  direction="row"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {t('Status')}
                  {sortColumn === 'status' &&
                    (sortDirection === 'asc' ? (
                      <KeyboardArrowUp fontSize="small" />
                    ) : (
                      <KeyboardArrowDown fontSize="small" />
                    ))}
                </Stack>
              </TableCell>
              <TableCell>{t('Actions')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities.map((activity) => {
              const isSelected = isItemSelected(activity.id)
              return (
                <TableRow
                  key={activity.id}
                  selected={isSelected}
                  hover
                  data-role="activity-item"
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isSelected}
                      onClick={(event) => handleRowClick(event, activity)}
                    />
                  </TableCell>
                  <TableCell>
                    {new Date(activity.createdAt).toLocaleDateString('en-GB', {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                    })}
                  </TableCell>
                  <TableCell>{activity.name}</TableCell>
                  {!hideOrganization && (
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={getOrganizationPath(activity.organization.id)}
                      >
                        {activity.organization.id}
                      </Link>
                    </TableCell>
                  )}
                  <TableCell>{t(activity.type)}</TableCell>
                  <TableCell>
                    {activity.toDate
                      ? `${new Date(activity.fromDate).toLocaleDateString(
                          'en-GB',
                          {
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit',
                          }
                        )}
                      - ${new Date(activity.toDate).toLocaleDateString(
                        'en-GB',
                        {
                          year: '2-digit',
                          month: '2-digit',
                          day: '2-digit',
                        }
                      )}`
                      : new Date(activity.fromDate).toLocaleDateString(
                          'en-GB',
                          { year: '2-digit', month: '2-digit', day: '2-digit' }
                        )}
                  </TableCell>
                  <TableCell>{activity.address?.city ?? t('Empty')}</TableCell>
                  <TableCell data-role="activity-status">
                    {t(activity.status ?? '')}
                  </TableCell>
                  <TableCell>
                    <ActivityStatusButtonGroup
                      value={activity.status}
                      onChange={(event, newStatus) =>
                        onActivityStatusChange(activity.id, newStatus)
                      }
                      iconified
                    />
                  </TableCell>
                  <TableCell>
                    <Stack direction="row">
                      <IconButton
                        data-role="activity-item-link"
                        onClick={() => navigate(getActivityPath(activity.id))}
                      >
                        <NavigateNext />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount || activities.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[{ label: '', value: -1 }]}
        page={page || internalPage}
        onPageChange={(event, newPage) => {
          if (onPageChange) {
            onPageChange(newPage)
          } else {
            setInternalPage(newPage)
          }
        }}
      />
    </>
  )
}

export default ActivitiesTable
