import { Box, Grid, Paper, Stack, Typography } from '@mui/material'
import { Communication } from 'data/models/Communication'
import { useTranslation } from 'react-i18next'

const Communications = ({
  communications,
}: {
  communications: Communication[]
}) => {
  const { t } = useTranslation()
  return (
    <Box>
      <Grid container direction="column" spacing={2}>
        {communications.map((communication, index) => (
          <Grid item key={index}>
            <Paper variant="outlined" elevation={0} sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Typography alignSelf={'end'}>
                  {new Date(communication.createdAt).toLocaleString()}
                </Typography>
                <Stack>
                  <Typography variant="subtitle1" color="text.secondary">
                    {t('Object')}
                  </Typography>
                  <Typography>{communication.object}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="subtitle1" color="text.secondary">
                    {t('Body')}
                  </Typography>
                  <Typography>{communication.body}</Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Communications
