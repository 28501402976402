import {
  AppBar,
  Button,
  Container,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from '@mui/material'
import { SIGNIN_PATH } from 'AppRoutes'
import { postUser } from 'data/api'
import { sendVerificationEmail, signUpWithCredentials } from 'data/firebase'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { showError } from 'utils/notifier'
import EmailConfirmation from './EmailConfirmation'
import SignUp, { SignUpForm } from './SignUp'

const steps = {
  account: 'account',
  email: 'email',
}

const SignUpPage = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const activeStep = searchParams.get('step') || steps.account

  const { mutate: signUp, isLoading: signUpIsLoading } = useMutation(
    async (data: Omit<SignUpForm, 'isLegalChecked'>) => {
      const user = await signUpWithCredentials(data)
      await postUser({
        ...data,
        id: user.uid,
      })
      await sendVerificationEmail(user)
    },
    {
      onSuccess: () => setSearchParams({ step: steps.email }),
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const isLoading = signUpIsLoading

  const stepsComponents = {
    [steps.account]: {
      title: t('Create an account'),
      component: (
        <SignUp onSignUp={(data) => signUp(data)} isLoading={signUpIsLoading} />
      ),
    },
    [steps.email]: {
      title: t('Verify email address'),
      component: (
        <EmailConfirmation
          onEmailConfirmed={() => {
            //wait for session to attach
            setTimeout(() => {
              navigate('/')
            }, 1500)
          }}
        />
      ),
    },
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {stepsComponents[activeStep].title}
          </Typography>
          <Button href={SIGNIN_PATH} color="inherit">
            {t('Login')}
          </Button>
          {isLoading && <LinearProgress />}
        </Toolbar>
      </AppBar>
      <Container maxWidth={'xs'}>
        <>
          {stepsComponents[activeStep].component}
          <Stepper
            sx={{ mt: 8 }}
            activeStep={Object.values(steps).indexOf(activeStep)}
            alternativeLabel
          >
            {Object.values(stepsComponents).map((step) => (
              <Step key={step.title}>
                <StepLabel>{step.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </>
      </Container>
    </>
  )
}

export default SignUpPage
