import config from 'config/config'
import ky, { HTTPError } from 'ky'
import { omit } from 'lodash'
import { getSession } from './firebase'
import {
  Activity,
  ActivityDetails,
  Category,
  CreateActivity,
  Period,
  UpdateActivity,
} from './models/Activity'
import { Child } from './models/Child'
import { CreateCommunication } from './models/Communication'
import { ItemsEnvelope } from './models/ItemsEnvelope'
import {
  CreateOrganization,
  Organization,
  OrganizationDetails,
} from './models/Organization'
import { CreateOrganizer } from './models/OrganizationUser'
import {
  ActivityRegistration,
  Registration,
  UpdateRegistration,
} from './models/Registration'
import {
  CreateUser,
  SessionUser,
  UpdateUser,
  User,
  UserDetails,
} from './models/User'

export type Status = { status: string; version: string }

const api = ky.extend({
  prefixUrl: `${config.endpoint}/api/`,
  hooks: {
    beforeError: [
      async (error: HTTPError) => {
        const { response } = error
        const body = (await response.json()) as {
          error: string
          message: string | string[]
        }
        if (response?.body) {
          if (body.error) {
            error.name = body.error
          }
          error.message = body.message.toString()
        }

        return error
      },
    ],
    beforeRequest: [
      async (request) => {
        const session = await getSession()
        if (session) {
          request.headers.set('Authorization', `Bearer ${session.token}`)
        }
      },
    ],
  },
})

export const getStatus = async (): Promise<Status> => {
  return api.get(`status`).json()
}

export const getActivities = async (
  searchParams: Record<string, string>
): Promise<ItemsEnvelope<Activity>> => {
  return api
    .get(`activities`, {
      searchParams,
    })
    .json()
}

export const getAllActivities = async (): Promise<ItemsEnvelope<Activity>> => {
  return api.get(`activities`).json()
}

export const getCategories = async (): Promise<Category[]> => {
  return api.get(`activities/categories`).json()
}

export const getPeriods = async (): Promise<Period[]> => {
  return api.get(`activities/periods`).json()
}

export const getImages = async (): Promise<string[]> => {
  return api.get(`activities/images`).json()
}

export const getChildren = async (
  searchParams: Record<string, string>
): Promise<ItemsEnvelope<Child>> => {
  return api
    .get(`children`, {
      searchParams,
    })
    .json()
}

export const getAllChildren = async (): Promise<Child[]> => {
  return api.get(`children`).json()
}

export const getOrganizations = async (
  searchParams: Record<string, string>
): Promise<ItemsEnvelope<Organization>> => {
  return api
    .get(`organizations`, {
      searchParams,
    })
    .json()
}

export const getAllOrganizations = async (): Promise<Organization[]> => {
  return api.get(`organizations`).json()
}

export const getUsers = async (
  searchParams: Record<string, string>
): Promise<ItemsEnvelope<User>> => {
  return api
    .get(`users`, {
      searchParams,
    })
    .json()
}

export const getActivity = async (id: string): Promise<ActivityDetails> => {
  return api.get(`activities/${id}`).json()
}

export const deleteActivity = async (id: string): Promise<void> => {
  const activity: ActivityDetails = await getActivity(id)
  if (activity.registrations.length > 0) {
    throw new Error('Cannot delete an activity with registrations')
  } else {
    await api.delete(`activities/${id}`)
  }
}

export const getUser = async (id: string): Promise<UserDetails> => {
  return api.get(`users/${id}`).json()
}

export const deleteUser = async (id: string): Promise<void> => {
  await api.delete(`users/${id}`)
}

export const getMe = async (): Promise<SessionUser> => {
  const session = await getSession()
  if (!session) {
    throw new Error('No session')
  }
  const user = await getUser(session.uid)
  return { ...omit(session, 'phoneNumber'), ...user }
}

export const postUser = async (user: CreateUser): Promise<User> => {
  return api.post(`users`, { json: user }).json()
}

export const putUser = async (user: UpdateUser): Promise<User> => {
  return api
    .put(`users/${user.id}`, {
      json: user,
    })
    .json()
}

export const getOrganization = async (
  id: string
): Promise<OrganizationDetails> => {
  return api.get(`organizations/${id}`).json()
}

export const deleteOrganization = async (id: string): Promise<void> => {
  await api.delete(`organizations/${id}`)
}

export const postOrganization = async (
  organization: CreateOrganization
): Promise<Organization> => {
  return api.post(`organizations`, { json: organization }).json()
}

export const putOrganization = async ({
  id,
  organization,
}: {
  id: string
  organization: Partial<Organization>
}): Promise<Organization> => {
  return api
    .put(`organizations/${id}`, {
      json: organization,
    })
    .json()
}

export const postActivity = async (
  activity: CreateActivity
): Promise<Activity> => {
  return api
    .post(`activities`, {
      json: activity,
    })
    .json()
}

export const postActivities = async (
  activities: CreateActivity[]
): Promise<Activity[]> => {
  const addedActivities = []
  for (const activity of activities) {
    addedActivities.push(await postActivity(activity))
  }
  return addedActivities
}

export const postOrganizations = async (
  organizations: CreateOrganization[]
): Promise<void> => {
  for (const organization of organizations) {
    await postOrganization(organization)
  }
}

export const getAllRegistrations = async (
  offset = 0,
  limit = 2000
): Promise<ItemsEnvelope<Registration>> => {
  return api
    .get(`registrations`, {
      searchParams: {
        offset,
        limit,
      },
    })
    .json()
}

export const getRegistrations = async (
  searchParams: Record<string, string>
): Promise<ItemsEnvelope<Registration>> => {
  const removeDiacritics = (input: string): string => {
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  if (searchParams.child) {
    const children = await getAllChildren()
    const split = removeDiacritics(searchParams.child).toLowerCase().split(' ')
    let matchingChildren = ''

    for (const child of children) {
      const firstNameMatch =
        split[0] !== '' &&
        (removeDiacritics(child.firstName).toLowerCase() === split[0] ||
          removeDiacritics(child.firstName).toLowerCase().includes(split[0]))
      const lastNameMatch =
        split[1] !== '' &&
        (removeDiacritics(child.lastName).toLowerCase() === split[1] ||
          removeDiacritics(child.lastName).toLowerCase().includes(split[1]))

      if (
        firstNameMatch ||
        lastNameMatch ||
        (split[0] !== '' &&
          split[1] !== '' &&
          removeDiacritics(child.firstName).toLowerCase() +
            ' ' +
            removeDiacritics(child.lastName).toLowerCase() ===
            removeDiacritics(searchParams.child).toLowerCase())
      ) {
        if (matchingChildren) {
          matchingChildren += ','
        }
        matchingChildren += child.id
      }
    }

    if (matchingChildren) {
      searchParams.child = matchingChildren
    } else {
      searchParams.child = 'nomatch'
    }
  }

  if (searchParams.child === 'nomatch') {
    // If 'nomatch', return an empty result or handle it as needed
    return { items: [], totalCount: 0 } as ItemsEnvelope<Registration>
  }

  if (searchParams.activity) {
    const activities = await getAllActivities()
    let found = false
    for (const activity of activities.items) {
      if (activity.name.includes(searchParams.activity)) {
        searchParams.activity = activity.id
        found = true
        break
      }
    }
    if (!found) {
      searchParams.activity = ''
    }
  }
  return api
    .get('registrations', {
      searchParams: new URLSearchParams(searchParams),
    })
    .json()
}

export const getRegistration = async (id: string): Promise<Registration> => {
  return api.get(`registrations/${id}`).json()
}

export const putRegistration = async (
  updateRegistration: UpdateRegistration
): Promise<ActivityRegistration> => {
  return api
    .put(`registrations/${updateRegistration.id}`, {
      json: updateRegistration,
    })
    .json()
}

export const putActivity = async (
  activity: UpdateActivity
): Promise<Activity> => {
  return api
    .put(`activities/${activity.id}`, {
      json: activity,
    })
    .json()
}

export const postCommunication = async ({
  communication,
}: {
  communication: CreateCommunication
}): Promise<CreateCommunication> => {
  return api.post(`communications`, { json: communication }).json()
}

export const putOrganizer = async (
  organizer: CreateOrganizer
): Promise<void> => {
  return api
    .put(`organizations/${organizer.organization}/users/${organizer.user}`, {
      json: organizer,
    })
    .json()
}

export const postOrganizer = async (
  organizer: CreateOrganizer
): Promise<void> => {
  return api
    .post(`organizations/${organizer.organization}/users`, {
      json: organizer,
    })
    .json()
}

export const deleteOrganizer = async (
  organizationId: string,
  organizationUserId: string
): Promise<void> => {
  return api
    .delete(`organizations/${organizationId}/users/${organizationUserId}`)
    .json()
}
