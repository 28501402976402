import LoadingButton from '@mui/lab/LoadingButton'
import { DialogActions, DialogContent, Grid } from '@mui/material'
import FormSelect from 'components/FormSelect'
import FormTextField from 'components/FormTextField'
import ResponsiveDialog from 'components/ResponsiveDialog'
import { postOrganizer, putOrganizer } from 'data/api'
import { OrganizationDetails } from 'data/models/Organization'
import {
  CreateOrganizer,
  OrganizationUser,
  organizerRoles,
} from 'data/models/OrganizationUser'
import { HTTPError } from 'ky'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { showError, showInfoMessage } from 'utils/notifier'

type EditOrganizerForm = Omit<CreateOrganizer, 'organization'>

const EditOrganizerDialog = ({
  organizer,
  organization,
  open,
  onClose,
}: {
  organizer?: OrganizationUser
  organization: OrganizationDetails
  open: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
  } = useForm<EditOrganizerForm>({
    mode: 'all',
    defaultValues: {
      ...organizer,
      user: organizer?.user?.id ?? '',
    },
  })
  const queryClient = useQueryClient()
  const snackbar = useSnackbar()

  const { mutate: updateOrganizer, isLoading: updateUserLoading } = useMutation(
    putOrganizer,
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries([
          'organizations',
          { id: variables.organization },
        ])
        showInfoMessage(snackbar, t('Organizer updated'))
        onClose()
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: addOrganizer, isLoading: addOrganizerLoading } = useMutation(
    postOrganizer,
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries([
          'organizations',
          { id: variables.organization },
        ])
        showInfoMessage(snackbar, t('Organizer added'))
        onClose()
      },
      onError: (e) => {
        if (e instanceof HTTPError && e.response.status === 404) {
          showError(snackbar, t('User Not found'), t)
        } else {
          showError(snackbar, e, t)
        }
      },
    }
  )

  const onSubmit = (data: EditOrganizerForm): void => {
    if (organizer) {
      updateOrganizer({
        ...data,
        user: organizer?.user.id,
        organization: organization.id,
      })
    } else {
      addOrganizer({
        ...data,
        organization: organization.id,
      })
    }
  }

  useEffect(() => {
    reset({
      ...organizer,
      user: organizer?.user?.id ?? '',
    })
  }, [organizer, reset])

  const isFormValid = isDirty
  const isLoading = updateUserLoading || addOrganizerLoading

  return (
    <ResponsiveDialog
      title={t('Edit user')}
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogContent>
        <Grid container mt={1} spacing={2}>
          {!organizer && (
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name="user"
                label={t('User email')}
                fullWidth
                required
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormSelect
              control={control}
              name="role"
              label={t('Role')}
              options={organizerRoles}
              fullWidth
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          disabled={!isFormValid}
          loading={isLoading}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}

export default EditOrganizerDialog
