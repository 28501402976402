import {
  AppBar,
  Button,
  Container,
  Grid,
  Toolbar,
  Typography,
} from '@mui/material'
import { SIGNIN_PATH } from 'AppRoutes'
import { useTranslation } from 'react-i18next'

const ParentPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <Button href={SIGNIN_PATH} color="inherit">
            {t('Login')}
          </Button>
        </Toolbar>
      </AppBar>
      <Container>
        <Grid
          container
          style={{ minHeight: '90vh' }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={4}
        >
          <Grid item>
            <Typography variant="h1" sx={{ color: 'secondary' }}>
              (&gt;_&lt;)
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" sx={{ color: 'secondary' }}>
              {t(
                'Dashboard access is reserved for organizers and administrators'
              )}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default ParentPage
