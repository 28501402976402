import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { TextField, TextFieldProps } from '@mui/material'
import { Locale } from 'date-fns'
import enLocale from 'date-fns/locale/en-GB'
import frLocale from 'date-fns/locale/fr'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const localeMap: Record<string, Locale> = {
  en: enLocale,
  fr: frLocale,
}

const getLocale = (language: string): Locale => {
  if (Object.keys(localeMap).includes(language)) {
    return localeMap[language]
  } else {
    return enLocale
  }
}

const FormDatePicker = ({
  control,
  name,
  label,
  defaultValue = null,
  rules,
  ...textFieldProps
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, never>
  name: string
  label: string
  defaultValue?: Date | null
  rules?: Record<string, unknown>
} & TextFieldProps) => {
  const { t, i18n } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: {
          value: !!textFieldProps.required,
          message: t('Required'),
        },
        ...rules,
      }}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={{ dateFormat: 'dd-MM-yyyy', ...getLocale(i18n.language) }}
        >
          <DatePicker
            {...field}
            label={label}
            renderInput={(props) => {
              const updatedProps = {
                ...props,
                inputProps: {
                  ...props.inputProps,
                  placeholder: 'dd/mm/yyyy',
                },
              }

              return (
                <TextField
                  {...updatedProps}
                  {...textFieldProps}
                  error={!!error}
                />
              )
            }}
          />
        </LocalizationProvider>
      )}
    />
  )
}

export default FormDatePicker
