import { Email } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Avatar, Stack, Typography } from '@mui/material'
import config from 'config/config'
import { getSession, sendVerificationEmail } from 'data/firebase'
import useSession from 'features/useSession'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { showError, showInfoMessage } from 'utils/notifier'

const EmailConfirmation = ({
  onEmailConfirmed,
}: {
  onEmailConfirmed: () => void
}) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()

  const {
    data: session,
    isLoading: sessionIsLoading,
    fetchStatus,
  } = useSession()

  const { mutate: reloadUser, isLoading: reloadUserIsLoading } = useMutation(
    () => getSession(true),
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(['me'])
        if (data?.emailVerified || config.firebase.emulator) {
          onEmailConfirmed()
        } else {
          showInfoMessage(snackbar, t('Please verify your email address'))
        }
      },
    }
  )

  const {
    mutate: resendEmailVerification,
    isLoading: resendEmailVerificationIsLoading,
  } = useMutation(sendVerificationEmail, {
    onSuccess: () => {
      showInfoMessage(snackbar, t('Email sent'))
    },
    onError: (e) => showError(snackbar, e, t),
  })

  const isLoading =
    sessionIsLoading ||
    fetchStatus === 'fetching' ||
    resendEmailVerificationIsLoading ||
    reloadUserIsLoading

  return (
    (session && session.email && (
      <Stack
        direction="column"
        spacing={4}
        mt={4}
        justifyContent="center"
        alignItems="center"
      >
        <Avatar sx={{ width: 80, height: 80 }}>
          <Email sx={{ width: 50, height: 50 }} />
        </Avatar>
        <Typography variant="h6" align="center">
          {t(`A confirmation email has been sent to ${session.email}`)}
        </Typography>

        <Typography variant="subtitle1">
          {t(`Please confirm your account by clicking the link in the email.`)}
        </Typography>
        <Stack spacing={2} mt={4}>
          <LoadingButton
            disabled={isLoading}
            onClick={() => resendEmailVerification(undefined)}
          >
            {t('Resend an Email')}
          </LoadingButton>
        </Stack>
      </Stack>
    )) || <></>
  )
}
export default EmailConfirmation
