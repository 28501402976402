import { TextField, TextFieldProps } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface CustomTextFieldProps extends Omit<TextFieldProps, 'onChange'> {
  min?: number
}

const FormTextField = ({
  control,
  name,
  defaultValue = '',
  handleInputChange,
  rules,
  ...textFieldProps
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, never>
  name: string
  defaultValue?: string
  rules?: Record<string, unknown>
  handleInputChange?: (value: any) => any
} & CustomTextFieldProps) => {
  const { t } = useTranslation()

  const priceValidator = (value: string) => {
    return value === '' || Number(value) >= 0
  }

  const postalCodeValidator = (value: string | undefined | null) => {
    if (!value || value === '') return true

    return (
      /^([1-9][0-9]{3})$/.test(value.trim()) ||
      t('Postal code must be four digits between from 1000 - 9999')
    )
  }

  const emailValidator = (value: string | undefined | null) => {
    if (!value || value === '') return true

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return (
      emailRegex.test(value.trim()) || t('This is not a valid email address')
    )
  }

  const telephoneValidator = (value: string | number | undefined | null) => {
    if (!value || value === '') return true

    value = value.toString()

    const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
    return (
      phoneRegex.test(value.trim()) || t('This is not a valid phone number')
    )
  }

  const facebookValidator = (value: string | undefined | null) => {
    if (!value || value === '') return true
    const fbRegex = /^https:\/\/(?:www\.)?facebook\.com\/.+$/
    return fbRegex.test(value.trim()) || t('This is not a valid Facebook link')
  }

  const websiteValidator = (value: string | undefined | null) => {
    if (!value || value === '') return true
    const wwwRegex =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/
    return wwwRegex.test(value.trim()) || t('This is not a valid URL')
  }

  const nissValidator = (value: string | number | undefined | null) => {
    if (!value || value === '') return true

    value = value.toString()

    return Number(value) && value.length === 11
      ? true
      : t('This is not a valid national registration number')
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: { value: !!textFieldProps.required, message: t('Required') },
        ...(name === 'address.postalCode'
          ? {
              validate: {
                postalCode: (value: string | null | undefined) =>
                  postalCodeValidator(value),
                ...rules,
              },
            }
          : name.startsWith('phone')
          ? {
              validate: {
                telephone: (value: string | number | null | undefined) =>
                  telephoneValidator(value),
                ...rules,
              },
            }
          : name.startsWith('website')
          ? {
              validate: {
                website: (value: string | null | undefined) =>
                  websiteValidator(value),
                ...rules,
              },
            }
          : name.endsWith('.price')
          ? {
              validate: {
                price: (value: string) =>
                  priceValidator(value) ||
                  t(`Price must be greater than or equal to 0`),
                ...rules,
              },
            }
          : name === 'email'
          ? {
              validate: {
                email: (value: string | null | undefined) =>
                  emailValidator(value),
                ...rules,
              },
            }
          : name === 'facebook'
          ? {
              validate: {
                facebook: (value: string | null | undefined) =>
                  facebookValidator(value),
                ...rules,
              },
            }
          : name === 'nationalRegistrationNumber'
          ? {
              validate: {
                nationalRegistrationNumber: (
                  value: string | null | undefined
                ) => nissValidator(value),
                ...rules,
              },
            }
          : {}),
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          {...textFieldProps}
          type={textFieldProps.type === 'number' ? 'number' : undefined}
          helperText={error ? error.message : textFieldProps.helperText}
          error={!!error}
          placeholder={textFieldProps.placeholder}
        />
      )}
    />
  )
}

export default FormTextField
