import { Link } from '@mui/material'

const GoogleMapLink = ({ query, label }: { query: string; label?: string }) => {
  return (
    <Link
      href={`https://www.google.com/maps/search/?api=1&query=${query}`}
      target="_blank"
    >
      {label || query}
    </Link>
  )
}

export default GoogleMapLink
