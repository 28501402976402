import { Add, FileDownload, Upload } from '@mui/icons-material'
import { Button, Container, Grid, Paper, Stack } from '@mui/material'
import {
  deleteActivity,
  getActivities,
  postActivities,
  putActivity,
} from 'data/api'
import { activitiesFromCsv, activitiesToCsv } from 'data/csv'
import { Activity, ActivityStatus, UpdateActivity } from 'data/models/Activity'
import DeleteConfirmationDialog from 'features/components/DeleteConfirmationDialog'
import RejectedReasonDialog from 'features/components/RejectedReasonDialog'
import { useMe } from 'features/ProtectedRoute'
import { pickBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { ChangeEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import downloadString from 'utils/downloadString'
import { showError, showInfoMessage } from 'utils/notifier'
import transformSearchParams from 'utils/searchParams'
import SearchField from '../../components/SearchField'
import NavigationMenu from '../menu/NavigationMenu'
import ActivitiesTable from './ActivitiesTable'
import EditActivityDialog from './EditActivityDialog'

const ROWS_PER_PAGE = 20

const ActivitiesPage = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isEditActivityDialogOpen, setIsEditActivityDialogOpen] =
    useState(false)
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false)
  const [isRejectedReasonDialogOpen, setIsRejectedReasonDialogOpen] =
    useState(false)
  const [selectedIdsForRejection, setSelectedIdsForRejection] = useState<
    string[]
  >([])
  const [selectedActivities, setSelectedActivities] = useState<Activity[]>([])

  const [searchParams, setSearchParams] = useSearchParams()

  const { user } = useMe()

  const filters = useMemo(
    () =>
      pickBy(
        {
          ...transformSearchParams(searchParams),
          limit: String(ROWS_PER_PAGE),
          organizer:
            user.role === 'organizer' || user.role === 'organizer-premium'
              ? user.id
              : undefined,
        },
        (v) => v !== undefined
      ) as Record<string, string>,
    [searchParams, user]
  )

  const {
    data,
    isLoading: activitiesIsLoading,
    isPreviousData,
  } = useQuery(['activities', filters], () => getActivities({ ...filters }), {
    keepPreviousData: true,
    onError: (e) => showError(snackbar, e, t),
  })

  const { mutate: updateActivity, isLoading: updateActivityIsLoading } =
    useMutation(putActivity, {
      onSuccess: async () => {
        //TODO: set data directly ?
        await queryClient.invalidateQueries(['activities', filters])
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const { mutate: updateActivities, isLoading: updateActivitiesLoading } =
    useMutation(
      async (activities: UpdateActivity[]) => {
        for (const activity of activities) {
          await putActivity(activity)
        }
        setSelectedActivities([])
      },
      {
        onSuccess: async () => {
          //TODO: set data directly ?
          await queryClient.invalidateQueries(['activities', filters])
        },
        onError: (e) => showError(snackbar, e, t),
      }
    )

  const { mutate: removeActivities, isLoading: removeActivitiesLoading } =
    useMutation(
      async (ids: string[]) => {
        for (const id of ids) {
          await deleteActivity(id)
        }
      },
      {
        onSuccess: async () => {
          showInfoMessage(snackbar, t('Activities deleted'))
          setSelectedActivities([])
          await queryClient.invalidateQueries(['activities', filters])
        },
        onError: (e) => showError(snackbar, e, t),
      }
    )

  const { mutate: addActivities, isLoading: addActivitiesLoading } =
    useMutation(postActivities, {
      onSuccess: async (addedActivities) => {
        await queryClient.invalidateQueries(['activities'])
        showInfoMessage(
          snackbar,
          t(`${addedActivities.length} activities added`)
        )
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const handleActivitiesStatusChange = (
    newStatus: ActivityStatus,
    rejectedReason?: string | null,
    activityId?: string
  ) => {
    if (newStatus === 'rejected') {
      setIsRejectedReasonDialogOpen(true)
      setSelectedIdsForRejection(
        activityId ? [activityId] : selectedActivities.map((a) => a.id)
      )
    } else {
      updateActivities(
        activityId
          ? [
              {
                id: activityId,
                status: newStatus,
                rejectedReason: rejectedReason ? rejectedReason : undefined,
              },
            ]
          : selectedActivities.map((a) => ({
              id: a.id,
              status: newStatus,
              rejectedReason: rejectedReason ? rejectedReason : undefined,
            }))
      )
    }
  }

  const handleActivitiesReject = (rejectedReason?: string) => {
    updateActivities(
      selectedIdsForRejection.map((id) => ({
        id,
        status: 'rejected',
        rejectedReason: rejectedReason,
      }))
    )
  }

  const handleFiltersChange = (newFilters: Record<string, string>) => {
    setSearchParams({
      ...newFilters,
      offset: String(0),
    })
  }

  const handleExportActivities = () => {
    downloadString(
      activitiesToCsv(selectedActivities),
      'text/csv',
      'activities.csv'
    )
  }

  const handleSelectedError = () => {
    return !selectedActivities.length
      ? showError(snackbar, t('No activities selected'), t)
      : handleExportActivities()
  }

  const handleFileSet = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0]
    event.target.value = ''
    if (!file) return

    try {
      const activities = await activitiesFromCsv(file)
      addActivities(activities)
    } catch (e) {
      showError(snackbar, e, t)
    }
  }

  const activities = data?.items
  const totalCount = data?.totalCount

  const isLoading =
    activitiesIsLoading ||
    updateActivityIsLoading ||
    isPreviousData ||
    addActivitiesLoading ||
    removeActivitiesLoading ||
    updateActivitiesLoading

  return (
    <NavigationMenu progress={isLoading} title={t('Activities')}>
      <Container data-role="activities-page">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
          mt={0}
          mb={2}
        >
          <Grid item>
            <Stack direction="row" justifyContent="space-between" spacing={6}>
              <SearchField
                isLoading={isLoading}
                onSubmit={handleFiltersChange}
                filters={filters}
                placeholder={t('Search an activity')}
              />
              <Button
                variant="contained"
                color="secondary"
                style={{
                  color: 'background',
                  marginLeft: '2rem',
                  marginRight: '1rem',
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  width: '30%',
                }}
                sx={{ textTransform: 'none' }}
                onClick={handleSelectedError}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Upload /> <div>{t('Export activities')}</div>
                </Stack>
              </Button>
              {user.role === 'admin' && (
                <Button
                  variant="contained"
                  color="error"
                  style={{
                    color: 'background',
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    width: '30%',
                  }}
                  sx={{ textTransform: 'none' }}
                  onClick={(e) => {
                    document.getElementById('button-import-file')?.click()
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <FileDownload />
                    <label htmlFor="button-import-file">
                      <input
                        accept="text/csv"
                        type="file"
                        id="button-import-file"
                        style={{ display: 'none' }}
                        onChange={handleFileSet}
                      />
                    </label>
                    <div>{t('Import activities')}</div>
                  </Stack>
                </Button>
              )}
              <Button
                variant="contained"
                color="success"
                data-role="create-activity-button"
                style={{
                  color: 'background',
                  marginLeft: '1rem',
                  marginRight: '1rem',
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  width: '30%',
                }}
                sx={{ textTransform: 'none' }}
                onClick={() =>
                  setIsEditActivityDialogOpen(!isEditActivityDialogOpen)
                }
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Add /> <div>{t('Add activity')}</div>
                </Stack>
              </Button>
            </Stack>
            {/* <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <FiltersTitle filters={filters} omitFields={['organizer']} />
              </AccordionSummary>
              <AccordionDetails>
                <ActivitiesFilters
                  isLoading={isLoading}
                  onSubmit={handleFiltersChange}
                  filters={filters}
                />
              </AccordionDetails>
            </Accordion> */}
          </Grid>
          <Grid item>
            <Paper>
              <ActivitiesTable
                activities={activities || []}
                page={parseInt(filters.offset || '0') / ROWS_PER_PAGE}
                rowsPerPage={ROWS_PER_PAGE}
                onPageChange={(page) => {
                  setSearchParams({
                    ...filters,
                    offset: String(page * ROWS_PER_PAGE),
                  })
                }}
                totalCount={totalCount}
                onActivityStatusChange={(id, status) =>
                  handleActivitiesStatusChange(status, undefined, id)
                }
                onDelete={() => setIsDeleteConfirmationDialogOpen(true)}
                selectedActivities={selectedActivities}
                onSelect={setSelectedActivities}
                onActivitiesStatusChange={handleActivitiesStatusChange}
                onExport={handleExportActivities}
              />
            </Paper>
          </Grid>
        </Grid>
        {isEditActivityDialogOpen && (
          <EditActivityDialog
            open={isEditActivityDialogOpen}
            onClose={() => setIsEditActivityDialogOpen(false)}
          />
        )}
        <DeleteConfirmationDialog
          open={isDeleteConfirmationDialogOpen}
          onClose={() => setIsDeleteConfirmationDialogOpen(false)}
          onConfirm={() =>
            removeActivities(selectedActivities.map((a) => a.id))
          }
          entityName={t(`${selectedActivities.length} activities`)}
        />
        <RejectedReasonDialog
          open={isRejectedReasonDialogOpen}
          onClose={() => setIsRejectedReasonDialogOpen(false)}
          onReject={(reason) => handleActivitiesReject(reason)}
        />
      </Container>
    </NavigationMenu>
  )
}

export default ActivitiesPage
