import { Add, Download } from '@mui/icons-material'
import { Button, Container, Grid, Paper, Stack } from '@mui/material'
import { getOrganizations, postOrganizations, putOrganization } from 'data/api'
import { organizationsFromCsv } from 'data/csv'
import { useMe } from 'features/ProtectedRoute'
import { pickBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { ChangeEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { showError, showInfoMessage } from 'utils/notifier'
import transformSearchParams from 'utils/searchParams'
import NavigationMenu from '../menu/NavigationMenu'
import EditOrganizationDialog from './EditOrganizationDialog'
import OrganizationsTable from './OrganizationsTable'

const ROWS_PER_PAGE = 20

const OrganizationsPage = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [searchParams, setSearchParams] = useSearchParams()

  const { user } = useMe()

  const filters = useMemo(
    () =>
      pickBy(
        {
          ...transformSearchParams(searchParams),
          limit: String(ROWS_PER_PAGE),
          organizer:
            user.role === 'organizer' || user.role === 'organizer-premium'
              ? user.id
              : undefined,
        },
        (v) => v !== undefined
      ) as Record<string, string>,
    [searchParams, user]
  )

  const [isEditOrganizationDialogOpen, setIsEditOrganizationDialogOpen] =
    useState(false)

  const { mutate: addOrganizations, isLoading: addOrganizationsIsLoading } =
    useMutation(postOrganizations, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['organizations'])
        showInfoMessage(snackbar, t('Organizations added'))
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const handleFileSet = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0]
    event.target.value = ''
    if (!file) return

    try {
      const organizations = await organizationsFromCsv(file)
      addOrganizations(organizations)
    } catch (e) {
      showError(snackbar, e, t)
    }
  }

  const {
    data,
    isLoading: organizationsIsLoading,
    isPreviousData,
  } = useQuery(['organizations', filters], () => getOrganizations(filters), {
    keepPreviousData: true,
    onError: (e) => showError(snackbar, e, t),
    onSuccess: (data) => {
      if (
        !isPreviousData &&
        data?.items.length === 1 &&
        (user.role === 'organizer' || user.role === 'organizer-premium')
      ) {
        navigate(`/organizations/${data.items[0].id}`)
      }
    },
  })

  const { mutate: updateOrganization, isLoading: updateOrganizationIsLoading } =
    useMutation(putOrganization, {
      onSuccess: async () => {
        //TODO: set data directly ?
        await queryClient.invalidateQueries(['organizations', filters])
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const handleInactiveChange = (ids: string[] | string, newValue: boolean) => {
    if (Array.isArray(ids)) {
      //TODO: update multiple organizations
    } else {
      updateOrganization({ id: ids, organization: { inactive: newValue } })
    }
  }

  const organizations = data?.items
  const totalCount = data?.totalCount

  const isLoading =
    organizationsIsLoading ||
    updateOrganizationIsLoading ||
    addOrganizationsIsLoading

  return (
    <NavigationMenu
      progress={isLoading || isPreviousData}
      title={t('Organizations')}
    >
      <Container data-role="organizations-page">
        {user.role === 'admin' && (
          <Stack direction="row" justifyContent="flex-end" spacing={6}>
            <Button
              variant="contained"
              color="error"
              style={{
                marginRight: 0,
                marginTop: '2rem',
                marginBottom: 0,
                paddingTop: '1rem',
                paddingBottom: '1rem',
                width: '30%',
              }}
              sx={{ textTransform: 'none' }}
              onClick={(e) => {
                document.getElementById('button-import-file')?.click()
              }}
            >
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Download />
                <label htmlFor="button-import-file">
                  <input
                    accept="text/csv"
                    type="file"
                    id="button-import-file"
                    style={{ display: 'none' }}
                    onChange={handleFileSet}
                  />
                </label>
                <div>{t('Import organizations')}</div>
              </Stack>
            </Button>
            <Button
              variant="contained"
              color="success"
              data-role="create-organization-button"
              style={{
                color: 'background',
                marginRight: 0,
                marginTop: '2rem',
                marginBottom: 0,
                paddingTop: '1rem',
                paddingBottom: '1rem',
                width: '30%',
              }}
              sx={{ textTransform: 'none' }}
              onClick={() =>
                setIsEditOrganizationDialogOpen(!isEditOrganizationDialogOpen)
              }
            >
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Add /> <div>{t('Add organization')}</div>
              </Stack>
            </Button>
          </Stack>
        )}
        {(organizations?.length !== 1 || user.role !== 'organizer') && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
            mt={0}
            mb={2}
          >
            <Grid item>
              <Paper>
                <OrganizationsTable
                  organizations={organizations || []}
                  page={parseInt(filters.offset || '0') / ROWS_PER_PAGE}
                  rowsPerPage={ROWS_PER_PAGE}
                  onPageChange={(page) =>
                    setSearchParams({
                      ...filters,
                      offset: String(page * ROWS_PER_PAGE),
                    })
                  }
                  totalCount={totalCount}
                  onInactiveChange={handleInactiveChange}
                />
              </Paper>
            </Grid>
          </Grid>
        )}

        {isEditOrganizationDialogOpen && (
          <EditOrganizationDialog
            open={isEditOrganizationDialogOpen}
            onClose={() => setIsEditOrganizationDialogOpen(false)}
          />
        )}
      </Container>

      {/* <Fab
        color="secondary"
        sx={{ position: 'fixed', bottom: 32, right: 32 }}
        onClick={() =>
          setIsEditOrganizationDialogOpen(!isEditOrganizationDialogOpen)
        }
      >
        <Add />
      </Fab> */}
    </NavigationMenu>
  )
}

export default OrganizationsPage
