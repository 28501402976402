import { Box, Grid, Link } from '@mui/material'
import GoogleMapLink from 'components/GoogleMapLink'
import LabelValue from 'components/LabelValue'
import { User as UserModel } from 'data/models/User'
import { useTranslation } from 'react-i18next'

const User = ({
  user,
  hideRole = false,
}: {
  user: UserModel
  hideRole?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <LabelValue label={t('First name')} value={user.firstName} />
        </Grid>
        <Grid item xs={6}>
          <LabelValue label={t('Last name')} value={user.lastName} />
        </Grid>
        <Grid item xs={6}>
          <LabelValue label={t('Email')}>
            <Link href={`mailto:${user.email}`}>{user.email}</Link>
          </LabelValue>
        </Grid>
        <Grid item xs={6}>
          <LabelValue label={t('Phone number')}>
            {user.phoneNumber && (
              <Link href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</Link>
            )}
          </LabelValue>
        </Grid>
        <Grid item xs={12}>
          <LabelValue label={t('Address')}>
            {user.address && (
              <GoogleMapLink
                query={`${user.address.street} ${user.address.postalCode} ${user.address.city}`}
              />
            )}
          </LabelValue>
        </Grid>
        {!hideRole && (
          <Grid item xs={12}>
            <LabelValue
              label={t('Role')}
              value={String(t(user.role || 'Parent'))}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default User
