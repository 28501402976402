import { Button, DialogActions, DialogContent, Grid } from '@mui/material'
import FormTextField from 'components/FormTextField'
import ResponsiveDialog from 'components/ResponsiveDialog'
import { CreateCommunication } from 'data/models/Communication'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const CreateCommunicationDialog = ({
  open,
  onClose,
  onSendCommunication,
}: {
  open: boolean
  onClose: () => void
  onSendCommunication: (communication: CreateCommunication) => void
}) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm<CreateCommunication>({ mode: 'all' })

  const isFormValid = isDirty && isValid

  return (
    <ResponsiveDialog
      title={t('Create communication')}
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogContent>
        <Grid container direction="column" mt={1} spacing={2}>
          <Grid item xs={10}>
            <FormTextField
              control={control}
              name="object"
              label={t('Object')}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name="body"
              label={t('Body')}
              multiline
              rows={6}
              fullWidth
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit(onSendCommunication)}
          disabled={!isFormValid}
        >
          {t('Send')}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}

export default CreateCommunicationDialog
