import { Clear, Search } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack } from '@mui/material'
import FormDatePicker from 'components/FormDatePicker'
import FormSelect from 'components/FormSelect'
import FormTextField from 'components/FormTextField'
import { administrativeAreas } from 'data/models/Address'
import { UserRole, userRoles } from 'data/models/User'
import { pickBy } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

type UsersFiltersForm = {
  createdAt?: Date
  email?: string
  name?: string
  administrativeArea?: string
  status?: 'inactive' | 'active' | ''
  role?: UserRole | ''
}

const UsersFilters = ({
  filters,
  isLoading = false,
  onSubmit,
}: {
  filters?: Record<string, string>
  isLoading: boolean
  onSubmit: (data: Record<string, string>) => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [roleValue, setRoleValue] = useState('')
  const [administrativeAreaValue, setAdministrativeAreaValue] = useState('')
  const [statusValue, setStatusValue] = useState('')
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    mode: 'all',
  })

  useEffect(() => {
    reset({
      ...filters,
      status:
        filters?.inactive === 'true'
          ? 'inactive'
          : filters?.inactive === 'false'
          ? 'active'
          : undefined,
    })
  }, [filters, reset])

  const isFormValid = isDirty

  const submitHandler = (data: UsersFiltersForm): void => {
    onSubmit(
      pickBy(
        {
          createdAt: data.createdAt
            ? data.createdAt.toISOString().slice(0, 10)
            : undefined,
          email: data.email && data.email?.length ? data.email : undefined,
          name: data.name && data.name?.length ? data.name : undefined,
          administrativeArea:
            data.administrativeArea && data.administrativeArea?.length
              ? data.administrativeArea
              : undefined,
          role: data.role && data.role.length ? data.role : undefined,
          inactive:
            data.status === 'inactive'
              ? true
              : data.status === ''
              ? undefined
              : false,
        },
        (v) => v !== undefined
      ) as Record<string, string>
    )
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') void handleSubmit(submitHandler)(event)
    if (event.key === 'Delete') void reset()
  }

  const handleReset = () => {
    reset()
    setAdministrativeAreaValue('')
    setStatusValue('')
    setRoleValue('')
    navigate('/users')
  }

  return (
    <Stack spacing={4}>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        justifyContent="center"
      >
        <Box flexBasis="20%">
          <FormDatePicker
            control={control}
            name="createdAt"
            label={t('Created at')}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box flexBasis="40%" marginLeft="1rem">
          <FormTextField
            control={control}
            name="name"
            label={t('User name')}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box flexBasis="40%" marginLeft="1rem">
          <FormTextField
            control={control}
            name="email"
            label={t('Email')}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box flexBasis="35%" marginLeft="1rem">
          <FormSelect
            control={control}
            name="administrativeArea"
            label={t('Administrative area')}
            options={administrativeAreas}
            defaultValue={administrativeAreaValue}
            showNoneOption
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box flexBasis="20%" marginLeft="1rem">
          <FormSelect
            control={control}
            name="role"
            label={t('Role')}
            options={userRoles}
            defaultValue={roleValue}
            showNoneOption
            fullWidth
          />
        </Box>
        <Box flexBasis="20%" marginLeft="1rem">
          <FormSelect
            control={control}
            name="status"
            label={t('Status')}
            options={['active', 'inactive']}
            defaultValue={statusValue}
            showNoneOption
            fullWidth
          />
        </Box>
        <Box flexBasis="1%">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Button
              onClick={() => {
                handleReset()
                // submitHandler({ status: '' })
              }}
              startIcon={<Clear />}
              style={{
                marginLeft: '1rem',
                paddingLeft: '1.3rem',
                maxWidth: '2.5rem',
                maxHeight: '2.5rem',
                minWidth: '2.5rem',
                minHeight: '2.5rem',
              }}
            />
            <LoadingButton
              onClick={handleSubmit(submitHandler)}
              disabled={!isFormValid}
              loading={isLoading}
              style={{
                paddingLeft: '1.3rem',
                maxWidth: '2.5rem',
                maxHeight: '2.5rem',
                minWidth: '2.5rem',
                minHeight: '2.5rem',
              }}
              startIcon={<Search />}
              fullWidth={true}
            />
          </Stack>
        </Box>
      </Box>
    </Stack>
  )
}

export default UsersFilters
