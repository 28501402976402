import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlProps,
} from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const FormCheckbox = ({
  control,
  name,
  defaultValue = false,
  rules,
  label,
  ...formControlProps
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, never>
  name: string
  defaultValue?: boolean
  label: string
  rules?: Record<string, unknown>
} & Omit<FormControlProps, 'defaultValue'>) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: {
          value: !!formControlProps.required,
          message: t('Required'),
        },
        ...rules,
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl {...formControlProps} error={!!error}>
          <FormControlLabel
            label={label}
            control={<Checkbox {...field} checked={!!field.value} />}
          />
        </FormControl>
      )}
    />
  )
}

export default FormCheckbox
