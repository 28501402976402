import { Button } from '@mui/material'
import AppRoutes from 'AppRoutes'
import { SnackbarKey, SnackbarProvider } from 'notistack'
import { createRef } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import './i18n/config'

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
})

const App = () => {
  const { t } = useTranslation()

  // add action to all snackbars
  const notistackRef = createRef<SnackbarProvider>()

  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      ref={notistackRef}
      action={(key) => (
        <Button onClick={onClickDismiss(key)} color="inherit">
          {t('Dismiss')}
        </Button>
      )}
      preventDuplicate
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </SnackbarProvider>
  )
}

export default App
