import { ActivityAddress } from './Address'
import { Organization } from './Organization'
import { ActivityRegistration } from './Registration'
import { Tag } from './Tag'

export const activityTypes = [
  'course',
  'birthday',
  'animation',
  'schoolAnimation',
  'extracurricular',
] as const

export type ActivityType = typeof activityTypes[number]

export const activityStatus = [
  'published',
  'rejected',
  'closed',
  'draft',
  'to be validated',
] as const
export type ActivityStatus = typeof activityStatus[number]

export type Activity = {
  name: string
  description: string
  type: ActivityType
  id: string
  createdAt: string
  fromDate: string
  toDate?: string
  highlight?: boolean
  status?: ActivityStatus
  organization: Organization
  address?: ActivityAddress
  image?: string
  minAge?: number
  maxAge?: number
  reducedMobilityAccess?: boolean
  externalApproval?: boolean
  prices?: Price[]
  rejectedReason?: string
  tags: Tag[]
  categories: Category[]
}

export type Price = { price: number; currency: string; description?: string }

export type ActivityDetails = Activity & {
  registrations: ActivityRegistration[]
}

export type CreateActivity = Omit<
  ActivityDetails,
  | 'id'
  | 'createdAt'
  | 'registrations'
  | 'organization'
  | 'categories'
  | 'tags'
  | 'address'
> & {
  organization: string
  categories: string[]
  tags: string[]
  address?: ActivityAddress
}

export type UpdateActivity = { id: string } & Partial<CreateActivity>

export type ActivitiesQuery = {
  offset: number
  limit: number
  name?: string
  administrativeArea?: string
  status?: ActivityStatus
  type?: ActivityType
  fromDate?: string
  toDate?: string
  categories?: string[]
}

export type Period = {
  name: string
  startDate: string
  endDate: string
  vacations: Vacation[]
}

export type Vacation = {
  name: string
  startDate: string
  endDate: string
}

export type Category = {
  name: string
  childCategories?: { name: string }[]
}

export const flattenCategories = (
  categories: Category[],
  prefix = ''
): string[] => {
  return categories.reduce((acc, category) => {
    if (category.childCategories) {
      return [
        ...acc,
        `${prefix}${category.name}`,
        ...flattenCategories(category.childCategories, ``),
      ]
    }
    return [...acc, `${prefix}${category.name}`]
  }, [] as string[])
}
