import { LoadingButton } from '@mui/lab'
import { Link, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { FORGOTPASSWORD_PATH, SIGNUP_PATH } from 'AppRoutes'
import FormPassword from 'components/FormPassword'
import FormTextField from 'components/FormTextField'
import LanguageSwitchButton from 'components/LanguageSwitchButton'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

type SignInForm = {
  email: string
  password: string
}

const SignIn = ({
  onSignInClick,
  loading,
}: {
  onSignInClick: (data: SignInForm) => void
  loading: boolean
}) => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    formState: { isValid: isFormValid },
  } = useForm<SignInForm>({ mode: 'all' })

  const submitHandler = (data: SignInForm) => {
    onSignInClick(data)
  }

  return (
    <Stack
      justifyContent="center"
      alignItems="stretch"
      spacing={2}
      component="form"
    >
      <Box alignSelf="center" mb={4} p={4}>
        <img src={'/logo.svg'} alt={t('Happykids')} style={{ width: 125 }} />
      </Box>
      <Stack spacing={2}>
        <FormTextField
          control={control}
          name="email"
          data-role="email-input"
          required
          fullWidth
          label={t('Email address')}
          autoComplete="email"
        />
        <FormPassword control={control} name="password" required fullWidth />
      </Stack>
      <LoadingButton
        type="submit"
        fullWidth
        disabled={!isFormValid}
        data-role="submit-button"
        variant="contained"
        onClick={handleSubmit(submitHandler)}
        loading={loading}
      >
        {t('Login')}
      </LoadingButton>
      <Stack direction="row" justifyContent="space-between">
        <Link component={RouterLink} to={FORGOTPASSWORD_PATH} variant="body2">
          {t('Forgot password?')}
        </Link>
        <Link component={RouterLink} to={SIGNUP_PATH} variant="body2">
          {t('No account? Register')}
        </Link>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <LanguageSwitchButton
          style={{ flexDirection: 'row', width: '2 rem' }}
          langs={['gb', 'fr']}
        />
      </Stack>
    </Stack>
  )
}

export default SignIn
