import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const RejectedReasonDialog = ({
  open,
  onClose,
  onReject,
  ...props
}: {
  onClose: () => void
  onReject: (reason?: string) => void
} & DialogProps) => {
  const { t } = useTranslation()

  const [reason, setReason] = useState<string>('')

  return (
    <Dialog
      open={open}
      onClose={onClose}
      {...props}
      fullWidth
      maxWidth="sm"
      data-role="rejection-form"
    >
      <DialogTitle>{t('Reject')}</DialogTitle>
      <DialogContent>
        <TextField
          data-role="reason-input"
          value={reason}
          fullWidth
          label={t('Rejection reason')}
          onChange={(e) => setReason(e.target.value)}
          multiline
          rows={4}
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        <Button
          data-role="submit-button"
          onClick={() => {
            onClose()
            onReject(reason === '' ? undefined : reason)
          }}
        >
          {t('Reject')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RejectedReasonDialog
