import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import LanguageSwitchButton from 'components/LanguageSwitchButton'
import config from 'config/config'
import { getMe } from 'data/api'
import { signOut } from 'data/firebase'
import { SessionUser } from 'data/models/User'
import User from 'features/components/User'
import NavigationMenu from 'features/menu/NavigationMenu'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { showError } from 'utils/notifier'
import packageJson from '../../../package.json'

const ProfilePage = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  const [user, setUser] = useState<SessionUser>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getMe()
      .then((data) => {
        setUser(data)
        setIsLoading(false)
      })
      .catch((e) => {
        showError(snackbar, e, t)
      })
  }, [snackbar, t])

  return (
    <NavigationMenu title={t('Profile')} progress={isLoading}>
      <Container>
        <Stack
          mt={4}
          mb={4}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          {user && (
            <Stack spacing={2}>
              <Typography variant="h6" color="text.secondary">
                {t('Profile')}
              </Typography>
              <Card>
                <CardContent>
                  <User user={user} />
                </CardContent>
              </Card>
            </Stack>
          )}

          <Button
            onClick={async () => {
              await signOut()
              window.location.replace('/')
            }}
          >
            {t('Logout')}
          </Button>

          <Divider sx={{ width: 400 }}>{t('Language')}</Divider>

          <Stack direction={'column'}>
            <LanguageSwitchButton
              style={{ flexDirection: 'row', width: '2 rem' }}
              langs={['gb', 'fr']}
            />
          </Stack>

          <Divider sx={{ width: 400 }}>{t('Help')}</Divider>

          <Stack direction={'column'}>
            <Button href={'/activities.sample.csv'} target="_blank">
              {t('Activities csv sample')}
            </Button>
            <Button href={'/organizations.sample.csv'} target="_blank">
              {t('Organizations csv sample')}
            </Button>
          </Stack>

          <Divider sx={{ width: 400 }}>{t('Legal')}</Divider>

          <Stack direction={'column'}>
            <Button href={config.termsUrl} target="_blank">
              {t('Terms and conditions')}
            </Button>
            <Button href={config.privacyUrl} target="_blank">
              {t('Privacy policy')}
            </Button>
          </Stack>

          <Typography variant="subtitle1" color="text.secondary">
            {`HappyKids © ${new Date().getFullYear()} - Version ${
              packageJson.version
            }`}
          </Typography>
        </Stack>
      </Container>
    </NavigationMenu>
  )
}

export default ProfilePage
