import NavigationDrawer, {
  NavigationDrawerProps,
} from 'components/NavigationDrawer'
import { useMe } from 'features/ProtectedRoute'
import { useTranslation } from 'react-i18next'
import { menuAdmin, menuOrganizer } from './menus'

const NavigationMenu = (props: Omit<NavigationDrawerProps, 'menu'>) => {
  const { t } = useTranslation()
  const { user } = useMe()

  return (
    <NavigationDrawer
      menu={
        user.role === 'admin'
          ? menuAdmin(t)
          : menuOrganizer(t, user.role ?? 'organizer')
      }
      {...props}
    />
  )
}

export default NavigationMenu
