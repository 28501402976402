import { CancelOutlined, CheckCircle, HelpOutline } from '@mui/icons-material'

const IconOptionalBoolean = ({ value }: { value?: boolean }) => {
  return value === true ? (
    <CheckCircle sx={{ color: 'green' }} />
  ) : value === false ? (
    <CancelOutlined sx={{ color: 'red' }} />
  ) : (
    <HelpOutline />
  )
}

export default IconOptionalBoolean
