import { Clear, Search } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack } from '@mui/material'
import FormDatePicker from 'components/FormDatePicker'
import FormSelect from 'components/FormSelect'
import FormTextField from 'components/FormTextField'
import { pickBy } from 'lodash'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toCapitalize } from 'utils/capitalize'

type RegistrationsFiltersForm = {
  activity?: string
  child?: string
  firstName?: string
  lastName?: string
  status?: string | ''
  childBirthdate?: Date
  createdAt?: Date
  fromDate?: Date
  toDate?: Date
}

const RegistrationsFilters = ({
  filters,
  isLoading = false,
  onSubmit,
}: {
  filters?: Record<string, string>
  isLoading: boolean
  onSubmit: (data: Record<string, string>) => void
}) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<RegistrationsFiltersForm>({
    mode: 'all',
    defaultValues: filters
      ? filters
      : {
          lastName: '',
        },
  })

  useEffect(() => {
    reset({
      ...filters,
      createdAt: filters?.createdAt ? new Date(filters.createdAt) : undefined,
      fromDate: filters?.fromDate ? new Date(filters.fromDate) : undefined,
      toDate: filters?.toDate ? new Date(filters.toDate) : undefined,
    })
  }, [filters, reset])

  const isFormValid = isDirty

  const submitHandler = (data: RegistrationsFiltersForm): void => {
    onSubmit(
      pickBy(
        {
          activity:
            data.activity && data.activity.length ? data.activity : undefined,
          child:
            data.firstName || data.lastName
              ? `${toCapitalize(data.firstName) ?? ''} ${
                  toCapitalize(data.lastName) ?? ''
                }`
              : undefined,
          status: data.status && data.status.length ? data.status : undefined,
          createdAt: data.createdAt
            ? data.createdAt.toISOString().slice(0, 10)
            : undefined,
          fromDate: data.fromDate
            ? data.fromDate.toISOString().slice(0, 10)
            : undefined,
          toDate: data.toDate
            ? data.toDate.toISOString().slice(0, 10)
            : undefined,
        },
        (v) => v !== undefined
      ) as Record<string, string>
    )
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') void handleSubmit(submitHandler)(event)
    if (event.key === 'Delete') void reset()
  }

  return (
    <Stack spacing={4}>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        justifyContent="center"
      >
        <Box flexBasis="15%">
          <FormDatePicker
            control={control}
            name="createdAt"
            label={t('Created at')}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box flexBasis="40%" marginLeft="1rem">
          <FormTextField
            control={control}
            name="firstName"
            label={t('First Name')}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box flexBasis="40%" marginLeft="1rem">
          <FormTextField
            control={control}
            name="lastName"
            label={t('Last Name')}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box flexBasis="25%" marginLeft="1rem">
          <FormTextField
            control={control}
            name="activity"
            label={t('Activity name')}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box flexBasis="8%" marginLeft="1rem">
          <FormDatePicker
            control={control}
            name="fromDate"
            label={t('From')}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box flexBasis="8%" marginLeft="1rem">
          <FormDatePicker
            control={control}
            name="toDate"
            label={t('To')}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box flexBasis="20%" marginLeft="1rem">
          <FormSelect
            control={control}
            name="status"
            label={t('Status')}
            options={['no status', 'confirmed', 'rejected']}
            showNoneOption
            fullWidth
          />
        </Box>
        <Box flexBasis="1%">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Button
              onClick={() => {
                reset()
                submitHandler({ status: '' })
              }}
              startIcon={<Clear />}
              style={{
                marginLeft: '1rem',
                paddingLeft: '1.3rem',
                maxWidth: '2.5rem',
                maxHeight: '2.5rem',
                minWidth: '2.5rem',
                minHeight: '2.5rem',
              }}
            />
            <LoadingButton
              onClick={handleSubmit(submitHandler)}
              disabled={!isFormValid}
              loading={isLoading}
              style={{
                paddingLeft: '1.3rem',
                maxWidth: '2.5rem',
                maxHeight: '2.5rem',
                minWidth: '2.5rem',
                minHeight: '2.5rem',
              }}
              startIcon={<Search />}
              fullWidth={true}
            />
          </Stack>
        </Box>
      </Box>
    </Stack>
  )
}

export default RegistrationsFilters
