import { getMe } from 'data/api'
import { onAuthStateChanged } from 'data/firebase'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { showError } from 'utils/notifier'

export default () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const snackbar = useSnackbar()

  const [isInitialized, setInitialized] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(() => {
      // beware: onAuthStateChanged is called multiple time without knowing his state
      if (!isInitialized) {
        getMe()
          .then((me) => {
            setInitialized(true)
            queryClient.setQueryData(['me'], me)
          })
          .catch((e) => {
            setInitialized(true)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            if (e.message.includes('User not found')) showError(snackbar, e, t)
          })
      }

      // setInitialized(true)
    })

    return unsubscribe
  }, [isInitialized, queryClient])

  return useQuery(['me'], () => getMe(), {
    enabled: isInitialized,
    retry: 0,
  })
}
