import { Container, Grid, Paper } from '@mui/material'
import { getUsers, putUser } from 'data/api'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { showError } from 'utils/notifier'
import transformSearchParams from 'utils/searchParams'
import NavigationMenu from '../menu/NavigationMenu'
import UsersTable from './UsersTable'

const ROWS_PER_PAGE = 20

const UsersPage = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()

  const [searchParams, setSearchParams] = useSearchParams()

  const filters = useMemo(
    () =>
      ({
        ...transformSearchParams(searchParams),
        limit: String(ROWS_PER_PAGE),
      } as Record<string, string>),
    [searchParams]
  )

  const {
    data,
    isLoading: usersIsLoading,
    isPreviousData,
  } = useQuery(['users', filters], () => getUsers(filters), {
    keepPreviousData: true,
    onError: (e) => showError(snackbar, e, t),
  })

  const { mutate: updateUser, isLoading: updateUserIsLoading } = useMutation(
    putUser,
    {
      onSuccess: async () => {
        //TODO: set data directly ?
        await queryClient.invalidateQueries(['users', filters])
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const handleInactiveChange = (ids: string[] | string, newValue: boolean) => {
    if (Array.isArray(ids)) {
      //TODO: update multiple organizations
    } else {
      updateUser({ id: ids, inactive: newValue })
    }
  }

  const users = data?.items
  const totalCount = data?.totalCount
  const isLoading = usersIsLoading || updateUserIsLoading

  return (
    <NavigationMenu progress={isLoading || isPreviousData} title={t('Users')}>
      <Container>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
          mt={0}
          mb={2}
        >
          <Grid item>
            <Paper>
              <UsersTable
                users={users || []}
                page={parseInt(filters.offset || '0') / ROWS_PER_PAGE}
                rowsPerPage={ROWS_PER_PAGE}
                onPageChange={(page) =>
                  setSearchParams({
                    ...filters,
                    offset: String(page * ROWS_PER_PAGE),
                  })
                }
                totalCount={totalCount}
                onInactiveChange={handleInactiveChange}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </NavigationMenu>
  )
}

export default UsersPage
