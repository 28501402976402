import { Delete, Edit } from '@mui/icons-material'
import {
  Breadcrumbs,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { ORGANIZATIONS_PATH } from 'AppRoutes'
import GoogleMapLink from 'components/GoogleMapLink'
import LabelValue from 'components/LabelValue'
import {
  deleteActivity,
  deleteOrganization,
  deleteOrganizer,
  getOrganization,
  putActivity,
  putOrganization,
} from 'data/api'
import { activitiesToCsv } from 'data/csv'
import { Activity, ActivityStatus, UpdateActivity } from 'data/models/Activity'
import { OrganizationUser } from 'data/models/OrganizationUser'
import ActivitiesTable from 'features/activities/ActivitiesTable'
import DeleteConfirmationDialog from 'features/components/DeleteConfirmationDialog'
import InactiveButtonGroup from 'features/components/InactiveButtonGroup'
import NavigationMenu from 'features/menu/NavigationMenu'
import EditOrganizationDialog from 'features/organizations/EditOrganizationDialog'
import { useMe } from 'features/ProtectedRoute'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import downloadString from 'utils/downloadString'
import { showError, showInfoMessage } from 'utils/notifier'
import EditOrganizerDialog from './EditOrganizerDialog'
import OrganizationUsersTable from './OrganizationUsersTable'

const OrganizationPage = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams<keyof { id: string }>() as { id: string }
  const { user } = useMe()
  const queryClient = useQueryClient()
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  const [isEditOrganizationDialogOpen, setIsEditOrganizationDialogOpen] =
    useState(false)
  const [isDeleteOrganizationDialogOpen, setDeleteOrganizationDialogOpen] =
    useState(false)

  const [
    isDeleteActivitiesConfirmationDialogOpen,
    setIsDeleteActivitiesConfirmationDialogOpen,
  ] = useState(false)
  const [selectedOrganizer, setSelectedOrganizer] = useState<
    OrganizationUser | undefined
  >(undefined)
  const [isNewOrganizerDialogOpen, setIsNewOrganizerDialogOpen] =
    useState(false)

  const [selectedActivities, setSelectedActivities] = useState<Activity[]>([])

  const { data: organization, isLoading: organizationIsLoading } = useQuery(
    ['organizations', { id }],
    () => getOrganization(id),
    {
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: removeOrganization, isLoading: removeOrganizationIsLoading } =
    useMutation(async () => deleteOrganization(id), {
      onSuccess: () => {
        showInfoMessage(snackbar, t('Organization deleted'))
        navigate(ORGANIZATIONS_PATH)
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const { mutate: updateActivity, isLoading: updateActivityIsLoading } =
    useMutation(putActivity, {
      onSuccess: async () => {
        //TODO: set data directly ?
        await queryClient.invalidateQueries(['organizations', { id }])
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const { mutate: updateActivities, isLoading: updateActivitiesLoading } =
    useMutation(
      async (activities: UpdateActivity[]) => {
        for (const activity of activities) {
          await putActivity(activity)
        }
        setSelectedActivities([])
      },
      {
        onSuccess: async () => {
          //TODO: set data directly ?
          await queryClient.invalidateQueries(['organizations', { id }])
        },
        onError: (e) => showError(snackbar, e, t),
      }
    )

  const { mutate: removeActivities, isLoading: removeActivitiesLoading } =
    useMutation(
      async (ids: string[]) => {
        for (const id of ids) {
          await deleteActivity(id)
        }
      },
      {
        onSuccess: async () => {
          showInfoMessage(snackbar, t('Activities deleted'))
          setSelectedActivities([])
          await queryClient.invalidateQueries(['organizations', { id }])
        },
        onError: (e) => showError(snackbar, e, t),
      }
    )

  const { mutate: removeUser, isLoading: removeUserLoading } = useMutation(
    async (userId: string) => {
      await deleteOrganizer(id, userId)
    },
    {
      onSuccess: async () => {
        showInfoMessage(snackbar, t('User removed'))
        await queryClient.invalidateQueries(['organizations', { id: id }])
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: updateOrganization, isLoading: updateOrganizationIsLoading } =
    useMutation(putOrganization, {
      onSuccess: async (variables) => {
        //TODO: set data directly
        await queryClient.invalidateQueries(['organizations', { id }])
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const handleInactiveChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: boolean
  ) => {
    updateOrganization({ id, organization: { inactive: newValue } })
  }

  const handleActivitiesStatusChange = (newStatus: ActivityStatus) => {
    updateActivities(
      selectedActivities.map((a) => ({ id: a.id, status: newStatus }))
    )
  }

  const handleExportActivities = () => {
    downloadString(
      activitiesToCsv(selectedActivities),
      'text/csv',
      'activities.csv'
    )
  }

  const isLoading =
    organizationIsLoading ||
    updateActivityIsLoading ||
    updateOrganizationIsLoading ||
    updateActivitiesLoading ||
    removeActivitiesLoading ||
    removeOrganizationIsLoading ||
    removeUserLoading

  return (
    <NavigationMenu
      progress={isLoading}
      title={
        <Breadcrumbs sx={{ color: 'inherit' }}>
          <Link
            underline="hover"
            variant="h6"
            color="inherit"
            component={RouterLink}
            to={ORGANIZATIONS_PATH}
          >
            {t('Organizations')}
          </Link>
          {organization && (
            <Typography variant="h6">{organization.id}</Typography>
          )}
        </Breadcrumbs>
      }
      toolbarChildren={
        organization && (
          <Stack direction="row" spacing={1}>
            <Tooltip title={String(t('Edit'))}>
              <IconButton
                data-role="edit-button"
                color="inherit"
                onClick={() => setIsEditOrganizationDialogOpen(true)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title={String(t('Delete'))}>
              <IconButton
                color="inherit"
                onClick={() => setDeleteOrganizationDialogOpen(true)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Stack>
        )
      }
    >
      <Container>
        {organization && (
          <Stack spacing={4} mt={4} mb={4}>
            <Card>
              <CardContent>
                <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
                  {/* {organization.logo && (
                    <Box
                      component="img"
                      src={organization.logo}
                      alt={organization.logo}
                      sx={{
                        width: 300,
                        height: 200,
                        backgroundColor: 'LightGray',
                        borderRadius: '0.5rem',
                      }}
                    />
                  )} */}
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: '1.7rem',
                      fontWeight: '600',
                      width: '100%',
                    }}
                  >
                    {organization.id}
                  </Typography>
                  {user.role === 'admin' && (
                    <CardActions
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                        width: '50%',
                      }}
                    >
                      <InactiveButtonGroup
                        onChange={handleInactiveChange}
                        value={organization.inactive}
                        iconified={true}
                      />
                    </CardActions>
                  )}
                </Stack>
              </CardContent>
            </Card>
            <Stack direction="row" spacing={3}>
              <Card sx={{ width: '100%' }}>
                <CardContent>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        paddingBottom: '0.5rem',
                        fontSize: '1.7rem',
                        fontWeight: '600',
                        color: 'text.primary',
                      }}
                    >
                      {t('Details')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <LabelValue
                      label={t('Email')}
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: '0.5rem',
                        paddingTop: '0.5rem',
                      }}
                      fontStyle={{ fontWeight: '600' }}
                    >
                      {organization.email && (
                        <Link
                          key={organization.email}
                          href={`mailto:${organization.email}`}
                          target="_blank"
                        >
                          {organization.email}
                        </Link>
                      )}
                    </LabelValue>
                  </Grid>
                  <Divider />
                  <Grid item xs={6} justifySelf="flex-end">
                    <LabelValue
                      label={t('Phone numbers')}
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: '0.5rem',
                        paddingTop: '0.5rem',
                      }}
                      fontStyle={{ fontWeight: '600' }}
                    >
                      {organization.phoneNumbers &&
                        organization.phoneNumbers.length > 0 && (
                          <Stack spacing={1}>
                            {organization.phoneNumbers.map((phoneNumber) => (
                              <Link
                                key={phoneNumber}
                                href={`tel:${phoneNumber}`}
                                target="_blank"
                              >
                                {phoneNumber}
                              </Link>
                            ))}
                          </Stack>
                        )}
                    </LabelValue>
                  </Grid>
                  <Divider />
                  <Grid item xs={6}>
                    <LabelValue
                      label={t('Websites')}
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: '0.5rem',
                        paddingTop: '0.5rem',
                      }}
                      fontStyle={{ fontWeight: '600' }}
                    >
                      {organization.websites &&
                        organization.websites.length > 0 && (
                          <Stack spacing={1}>
                            {organization.websites.map((website) => (
                              <Link
                                key={website}
                                href={website}
                                target="_blank"
                              >
                                {website}
                              </Link>
                            ))}
                          </Stack>
                        )}
                    </LabelValue>
                  </Grid>
                  <Divider />
                  <Grid item xs={6}>
                    <LabelValue
                      label={t('Facebook')}
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: '0.5rem',
                        paddingTop: '0.5rem',
                      }}
                      fontStyle={{ fontWeight: '600' }}
                    >
                      {organization.facebook && (
                        <Link
                          key={organization.facebook}
                          href={organization.facebook}
                          target="_blank"
                        >
                          {organization.facebook}
                        </Link>
                      )}
                    </LabelValue>
                  </Grid>
                  <Divider />
                  <Grid item xs={6}>
                    <LabelValue
                      label={t('Address')}
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: '0.5rem',
                        paddingTop: '0.5rem',
                      }}
                      fontStyle={{ fontWeight: '600' }}
                    >
                      {organization.address && (
                        <GoogleMapLink
                          query={`${organization.address?.street}, ${organization.address?.postalCode} ${organization.address?.city}`}
                        />
                      )}
                    </LabelValue>
                  </Grid>
                </CardContent>
              </Card>
            </Stack>
            <Card>
              <CardContent>
                <Grid item xs={12}>
                  <LabelValue
                    label={t('Description')}
                    value={organization.description}
                  />
                </Grid>
              </CardContent>
            </Card>
            <Paper>
              <OrganizationUsersTable
                organizationUsers={organization.users}
                onDelete={(userId) => removeUser(userId)}
                onEdit={setSelectedOrganizer}
                onAdd={() => setIsNewOrganizerDialogOpen(true)}
              />
            </Paper>
            <Paper>
              <ActivitiesTable
                activities={organization.activities}
                onActivityStatusChange={(id, status) =>
                  updateActivity({ id, status })
                }
                selectedActivities={selectedActivities}
                onSelect={setSelectedActivities}
                onDelete={() =>
                  setIsDeleteActivitiesConfirmationDialogOpen(true)
                }
                onActivitiesStatusChange={handleActivitiesStatusChange}
                hideOrganization
                onExport={handleExportActivities}
              />
            </Paper>
          </Stack>
        )}
        {organization && (
          <EditOrganizerDialog
            open={selectedOrganizer !== undefined || isNewOrganizerDialogOpen}
            onClose={() => {
              setIsNewOrganizerDialogOpen(false)
              setSelectedOrganizer(undefined)
            }}
            organizer={selectedOrganizer}
            organization={organization}
          />
        )}

        {organization && (
          <>
            {isEditOrganizationDialogOpen && (
              <EditOrganizationDialog
                open={isEditOrganizationDialogOpen}
                onClose={() => setIsEditOrganizationDialogOpen(false)}
                organization={organization}
                edit={true}
              />
            )}
            <DeleteConfirmationDialog
              open={isDeleteOrganizationDialogOpen}
              onClose={() => setDeleteOrganizationDialogOpen(false)}
              onConfirm={removeOrganization}
              entityName={`${organization.id}`}
            />
          </>
        )}

        <DeleteConfirmationDialog
          open={isDeleteActivitiesConfirmationDialogOpen}
          onClose={() => setIsDeleteActivitiesConfirmationDialogOpen(false)}
          onConfirm={() =>
            removeActivities(selectedActivities.map((a) => a.id))
          }
          entityName={t(`${selectedActivities.length} activities`)}
        />
      </Container>
    </NavigationMenu>
  )
}

export default OrganizationPage
