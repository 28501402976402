import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const FormSelect = ({
  control,
  name,
  label,
  options,
  defaultValue,
  rules,
  showNoneOption = false,
  noneOptionLabel,
  displayEmpty = false,
  ...formControlProps
}: {
  control: Control<any, never>
  name: string
  label: string
  options: readonly string[]
  defaultValue?: string | string[]
  rules?: Record<string, unknown>
  showNoneOption?: boolean
  noneOptionLabel?: string
  displayEmpty?: boolean
} & Omit<FormControlProps, 'defaultValue'>) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: {
          value: !!formControlProps.required,
          message: t('Required'),
        },
        ...rules,
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl {...formControlProps} error={!!error}>
          {!displayEmpty && <InputLabel>{label}</InputLabel>}
          <Select
            {...field}
            label={!displayEmpty ? label : null}
            multiple={Array.isArray(field.value)}
            displayEmpty={displayEmpty}
          >
            {showNoneOption && (
              <MenuItem value="">
                {displayEmpty ? (
                  noneOptionLabel || t('None')
                ) : (
                  <em>{noneOptionLabel || t('None')}</em>
                )}
              </MenuItem>
            )}
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {t(option)}
              </MenuItem>
            ))}
          </Select>
          {error && (
            <FormHelperText error={!!error}>{error.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}

export default FormSelect
