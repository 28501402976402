import { Block, Close, Done, PendingOutlined } from '@mui/icons-material'
import { ToggleButton, Tooltip } from '@mui/material'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { ActivityStatus } from 'data/models/Activity'
import { useMe } from 'features/ProtectedRoute'
import { useTranslation } from 'react-i18next'

const ActivityStatusButtonGroup = ({
  value,
  onChange,
  iconified = false,
}: {
  value?: ActivityStatus
  onChange: (
    event: React.MouseEvent<HTMLElement>,
    newStatus: ActivityStatus
  ) => void
  iconified?: boolean
}) => {
  const { t } = useTranslation()
  const { user } = useMe()

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      exclusive
      value={value}
      onChange={onChange}
    >
      <ToggleButton value="to be validated" color="secondary">
        {iconified ? (
          <Tooltip title={String(t('To be validated'))}>
            <PendingOutlined />
          </Tooltip>
        ) : (
          t('To be validated')
        )}
      </ToggleButton>
      {user?.role === 'admin' && (
        <ToggleButton
          value="published"
          color="success"
          data-role="publish-button"
        >
          {iconified ? (
            <Tooltip title={String(t('Published'))}>
              <Done />
            </Tooltip>
          ) : (
            t('Published')
          )}
        </ToggleButton>
      )}
      {user?.role === 'admin' && (
        <ToggleButton value="rejected" color="error" data-role="reject-button">
          {iconified ? (
            <Tooltip title={String(t('Rejected'))}>
              <Block />
            </Tooltip>
          ) : (
            t('Rejected')
          )}
        </ToggleButton>
      )}
      <ToggleButton value="closed" color="warning">
        {iconified ? (
          <Tooltip title={String(t('Closed'))}>
            <Close />
          </Tooltip>
        ) : (
          t('Closed')
        )}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default ActivityStatusButtonGroup
