import { Clear, Search } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack } from '@mui/material'
import FormAsynchronousAutocomplete, {
  AutocompleteOption,
} from 'components/FormAsynchronousAutocomplete'
import FormDatePicker from 'components/FormDatePicker'
import FormSelect from 'components/FormSelect'
import FormTextField from 'components/FormTextField'
import { getOrganizations } from 'data/api'
import {
  ActivityStatus,
  activityStatus,
  ActivityType,
  activityTypes,
  Category,
} from 'data/models/Activity'
import { useMe } from 'features/ProtectedRoute'
import { pickBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { showError } from 'utils/notifier'

type ActivityFiltersForm = {
  createdAt?: Date
  name?: string
  organizationName?: string | AutocompleteOption
  status?: ActivityStatus | ''
  type?: ActivityType | ''
  administrativeArea?: string
  fromDate?: Date
  toDate?: Date
  categories: Category[]
}

const ActivitiesFilters = ({
  filters,
  isLoading = false,
  onSubmit,
}: {
  filters?: Record<string, string>
  isLoading: boolean
  onSubmit: (data: Record<string, string>) => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [organizationValue, setOrganizationValue] = useState('')
  const [statusValue, setStatusValue] = useState('')
  const [typeValue, setTypeValue] = useState('')
  const snackbar = useSnackbar()
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<ActivityFiltersForm>({
    mode: 'all',
  })

  useEffect(() => {
    reset({
      ...filters,
      createdAt: filters?.createdAt ? new Date(filters.createdAt) : undefined,
      fromDate: filters?.fromDate ? new Date(filters.fromDate) : undefined,
      toDate: filters?.toDate ? new Date(filters.toDate) : undefined,
    })
  }, [filters, reset])

  const { user } = useMe()
  const isFormValid = isDirty

  const submitHandler = (data: ActivityFiltersForm): void => {
    onSubmit(
      pickBy(
        {
          createdAt: data.createdAt
            ? data.createdAt.toISOString().slice(0, 10)
            : undefined,
          name: data.name && data?.name.length ? data.name : undefined,
          organization:
            typeof data.organizationName === 'string'
              ? data.organizationName && data?.organizationName.length
                ? data.organizationName
                : undefined
              : data.organizationName?.label &&
                data.organizationName?.label.length
              ? data.organizationName?.label
              : 'undefined',
          status: data.status && data.status.length ? data.status : undefined,
          type: data.type && data.type.length ? data.type : undefined,
          fromDate: data.fromDate
            ? data.fromDate.toISOString().slice(0, 10)
            : undefined,
          toDate: data.toDate
            ? data.toDate.toISOString().slice(0, 10)
            : undefined,
        },
        (v) => v !== undefined
      ) as Record<string, string>
    )
  }

  const { data: organizationItems, isLoading: organizationsIsLoading } =
    useQuery(
      [
        'organizations',
        {
          name: organizationValue,
          organizer:
            user.role === 'organizer' || user.role === 'organizer-premium'
              ? user.id
              : undefined,
        },
      ],
      () =>
        getOrganizations(
          pickBy({
            name: organizationValue ? organizationValue : undefined,
            organizer:
              user.role === 'organizer' || user.role === 'organizer-premium'
                ? user.id
                : undefined,
          }) as Record<string, string>
        ),
      {
        onError: (e) => showError(snackbar, e, t),
      }
    )

  const organizations = organizationItems?.items

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') void handleSubmit(submitHandler)(event)
    if (event.key === 'Delete') void reset()
  }

  const handleReset = () => {
    reset()
    setOrganizationValue('')
    setStatusValue('')
    setTypeValue('')
    navigate('/activities')
  }

  return (
    <Stack spacing={4} sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
        <Box sx={{ flexBasis: '10%', paddingRight: 1 }}>
          <FormDatePicker
            control={control}
            name="createdAt"
            label={t('Created at')}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box sx={{ flexBasis: '25%', paddingRight: 1 }}>
          <FormTextField
            control={control}
            name="name"
            label={t('Activity name')}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box sx={{ flexBasis: '18%', paddingRight: 1 }}>
          <FormAsynchronousAutocomplete
            control={control}
            name="organizationName"
            label={t('Organization')}
            options={
              organizations?.map((o) => ({ id: o.id, label: o.id })) || []
            }
            defaultValue={organizationValue}
            loading={organizationsIsLoading}
            onInputChange={setOrganizationValue}
            fullWidth
          />
        </Box>
        <Box sx={{ flexBasis: '12%', paddingRight: 1 }}>
          <FormSelect
            control={control}
            name="type"
            label={t('Type')}
            defaultValue={typeValue}
            options={activityTypes}
            showNoneOption
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box sx={{ flexBasis: '8%', paddingRight: 1 }}>
          <FormDatePicker
            control={control}
            name="fromDate"
            label={t('From')}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box sx={{ flexBasis: '8%', paddingRight: 1 }}>
          <FormDatePicker
            control={control}
            name="toDate"
            label={t('To')}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box sx={{ flexBasis: '10%', paddingRight: 1 }}>
          <FormSelect
            control={control}
            name="status"
            label={t('Status')}
            options={activityStatus}
            defaultValue={statusValue}
            showNoneOption
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box sx={{ flexBasis: '2%' }}>
          <Stack
            direction="row"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              height: '100%',
            }}
          >
            <Button
              onClick={() => {
                handleReset()
              }}
              startIcon={<Clear />}
              style={{
                marginLeft: '0.5rem',
                paddingLeft: '1.3rem',
                maxWidth: '2.5rem',
                maxHeight: '2.5rem',
                minWidth: '2.5rem',
                minHeight: '2.5rem',
              }}
            />
            <LoadingButton
              onClick={handleSubmit(submitHandler)}
              disabled={!isFormValid}
              loading={isLoading}
              style={{
                marginLeft: '0.5rem',
                paddingLeft: '1.3rem',
                maxWidth: '2.5rem',
                maxHeight: '2.5rem',
                minWidth: '2.5rem',
                minHeight: '2.5rem',
              }}
              startIcon={<Search />}
              fullWidth={true}
            />
          </Stack>
        </Box>
      </Box>
    </Stack>
  )
}

export default ActivitiesFilters
