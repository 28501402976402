import { Button, DialogContent, ImageList, ImageListItem } from '@mui/material'
import ResponsiveDialog from 'components/ResponsiveDialog'
import { getImages } from 'data/api'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { showError } from 'utils/notifier'

const ImageDialog = ({
  open,
  onClose,
  onImageSelected,
}: {
  open: boolean
  onClose: () => void
  onImageSelected: (image: string) => void
}) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()

  const { data: images, isLoading: imagesIsLoading } = useQuery(
    ['images'],
    getImages,
    {
      onError: (e) => showError(snackbar, e, t),
    }
  )

  return (
    <ResponsiveDialog title={t('Gallery')} open={open} onClose={onClose}>
      <DialogContent>
        {images && (
          <ImageList cols={4}>
            {images.map((image) => (
              <Button key={image} onClick={(event) => onImageSelected(image)}>
                <ImageListItem>
                  <img src={image} alt={image} loading="lazy" />
                </ImageListItem>
              </Button>
            ))}
          </ImageList>
        )}
      </DialogContent>
    </ResponsiveDialog>
  )
}

export default ImageDialog
