import moment from 'moment'
import Papa from 'papaparse'
import geocode from 'utils/geocoding'
import { Activity, CreateActivity, Price } from './models/Activity'
import { Child } from './models/Child'
import { CreateOrganization } from './models/Organization'
import { ActivityRegistration } from './models/Registration'
import { User } from './models/User'

export const organizationsFromCsv = (
  file: File
): Promise<CreateOrganization[]> => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      delimiter: ';',
      complete: (
        results: Papa.ParseResult<
          Record<string, string | boolean | number | null>
        >
      ) => {
        if (results.errors.length > 0) {
          reject(results.errors)
        }
        try {
          const organizations = results.data.map((row) => {
            const trimmedRow = Object.fromEntries(
              Object.entries(row).map(([key, value]) => [
                key,
                typeof value === 'string' ? value.trim() : value,
              ])
            )

            return {
              id: trimmedRow.id,
              description: trimmedRow.description,
              inactive: trimmedRow.inactive,
              address: {
                street: trimmedRow.address_street,
                postalCode: String(trimmedRow.address_postalCode),
                city: trimmedRow.address_city,
                administrativeArea: trimmedRow.address_administrativeArea,
                country: trimmedRow.address_country,
              },
              phoneNumbers:
                typeof trimmedRow.phoneNumbers === 'string'
                  ? (JSON.parse(trimmedRow.phoneNumbers) as string[])
                  : [],
              websites:
                typeof trimmedRow.websites === 'string'
                  ? (JSON.parse(trimmedRow.websites) as string[])
                  : [],
              facebook: trimmedRow.facebook,
              email: trimmedRow.email,
            }
          })
          resolve(organizations as CreateOrganization[])
        } catch (e) {
          reject(e)
        }
      },
    })
  })
}

export const activitiesToCsv = (activities: Activity[]) => {
  const csv = Papa.unparse(
    activities.map((activity) => {
      return {
        name: activity.name,
        description: activity.description,
        type: activity.type,
        fromDate: activity.fromDate,
        toDate: activity.toDate,
        highlight: activity.highlight,
        status: activity.status,
        address_street: activity.address?.street,
        address_postalCode: String(activity.address?.postalCode),
        address_city: activity.address?.city,
        address_administrativeArea: activity.address?.administrativeArea,
        address_country: activity.address?.country,
        minAge: activity.minAge,
        maxAge: activity.maxAge,
        reducedMobilityAccess: activity.reducedMobilityAccess,
        externalApproval: activity.externalApproval,
        prices: JSON.stringify(activity.prices),
        organization: activity.organization.id,
        categories: JSON.stringify(activity.categories),
        tags: JSON.stringify(activity.tags),
        image: activity.image,
      }
    }),
    { delimiter: ';' }
  )
  return csv
}

export const registrationsToCSV = (
  registrations: (Omit<ActivityRegistration, 'activity' | 'child'> & {
    activity: Activity
    child: Child & {
      user: User
    }
  })[]
) => {
  const csv = Papa.unparse(
    registrations.map((registration) => {
      return {
        activité: registration.activity.name,
        statut: registration.status,
        prénomEnfant: registration.child.firstName,
        nomEnfant: registration.child.lastName,
        dateNaissanceEnfant: registration.child.birthDate
          ? moment(registration.child.birthDate).format('DD/MM/YYYY')
          : '',
        téléphoneMédecin: registration.child.familyDoctor?.phoneNumber
          ? registration.child.familyDoctor.phoneNumber
          : 'Inconnu',
        crééLe: registration.createdAt
          ? moment(registration.createdAt).format('DD/MM/YYYY')
          : '',
        dateDébut: registration.fromDate
          ? moment(registration.fromDate).format('DD/MM/YYYY')
          : '',
        dateFin: registration.toDate
          ? moment(registration.toDate).format('DD/MM/YYYY')
          : '',
        droitsImage: registration.imageRights,
        prix: `${registration.price?.price}`,
        payé: registration.paid,
        communications: registration.communications
          ? registration.communications.map(
              (communication: { body: string }) => communication.body
            )
          : [],
      }
    }),
    { delimiter: ';' }
  )
  return csv
}

export const activitiesFromCsv = (file: File): Promise<CreateActivity[]> => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      delimiter: ';',
      complete: async (
        results: Papa.ParseResult<
          Record<string, string | boolean | number | null>
        >
      ) => {
        if (results.errors.length > 0) {
          reject(results.errors.map((e) => e.message).join(', '))
        }
        try {
          const activities = []

          const status = [
            'published',
            'rejected',
            'closed',
            'draft',
            'to be validated',
          ]

          for (const row of results.data) {
            // Trim string values here
            const trimmedRow = Object.fromEntries(
              Object.entries(row).map(([key, value]) => [
                key,
                typeof value === 'string' ? value.trim() : value,
              ])
            )

            const geocodeResults = await geocode(
              `${String(trimmedRow.address_street)}, ${String(
                trimmedRow.address_postalCode
              )}, ${String(trimmedRow.address_city)}, ${String(
                trimmedRow.address_administrativeArea
              )}, ${String(trimmedRow.address_country)}`
            )

            activities.push({
              name: trimmedRow.name,
              description: trimmedRow.description,
              type: trimmedRow.type,
              fromDate: moment(String(trimmedRow.fromDate), 'DD/MM/YYYY')
                .add(1, 'day')
                .toString(),
              toDate:
                typeof trimmedRow.toDate === 'string'
                  ? moment(String(trimmedRow.toDate), 'DD/MM/YYYY')
                      .add(1, 'day')
                      .toString()
                  : undefined,
              highlight: trimmedRow.highlight,
              status:
                typeof trimmedRow.status === 'string' &&
                status.includes(trimmedRow.status)
                  ? trimmedRow.status
                  : 'draft',
              address: trimmedRow.address_street
                ? {
                    street: trimmedRow.address_street,
                    postalCode: String(trimmedRow.address_postalCode),
                    city: trimmedRow.address_city,
                    administrativeArea: trimmedRow.address_administrativeArea,
                    country: trimmedRow.address_country,
                    location: geocodeResults,
                  }
                : undefined,
              minAge: trimmedRow.minAge,
              maxAge: trimmedRow.maxAge,
              reducedMobilityAccess: trimmedRow.reducedMobilityAccess,
              externalApproval: trimmedRow.externalApproval,
              prices:
                typeof trimmedRow.prices === 'string'
                  ? (JSON.parse(trimmedRow.prices) as Price[])
                  : [],
              categories:
                typeof trimmedRow.categories === 'string'
                  ? (JSON.parse(trimmedRow.categories) as string[])
                  : [],
              tags:
                typeof trimmedRow.tags === 'string'
                  ? (JSON.parse(trimmedRow.tags) as string[])
                  : [],
              organization: trimmedRow.organization,
              image: trimmedRow.image,
            })
          }

          resolve(activities as CreateActivity[])
        } catch (e) {
          reject(e)
        }
      },
    })
  })
}
