import { Autocomplete } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type AutocompleteOption = { id: string; label: string }

const FormAsynchronousAutocomplete = ({
  control,
  name,
  options,
  loading,
  defaultValue,
  rules,
  onInputChange,
  ...textFieldProps
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, never>
  name: string
  options: AutocompleteOption[]
  loading: boolean
  defaultValue?: AutocompleteOption | string
  rules?: Record<string, unknown>
  onInputChange: (value: string) => void
} & TextFieldProps) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: {
          value: !!textFieldProps.required,
          message: t('Required'),
        },
        ...rules,
      }}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(_, value) => onChange(value)}
          filterOptions={() => options}
          getOptionLabel={(option: AutocompleteOption | undefined) =>
            option?.label || ''
          }
          isOptionEqualToValue={(a, b) => a?.id === b?.id}
          options={[field.value, ...options]}
          loading={loading}
          onInputChange={(_, value) => {
            onInputChange(value)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={error ? error.message : textFieldProps.helperText}
              error={!!error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              {...textFieldProps}
              autoComplete="off"
            />
          )}
        />
      )}
    />
  )
}

export default FormAsynchronousAutocomplete
