import { Upload } from '@mui/icons-material'
import { Button, Container, Grid, Paper, Stack } from '@mui/material'
import { getRegistrations, postCommunication, putRegistration } from 'data/api'
import { registrationsToCSV } from 'data/csv'
import { CreateCommunication } from 'data/models/Communication'
import {
  RegistrationStatus,
  UpdateRegistration,
} from 'data/models/Registration'
import CreateCommunicationDialog from 'features/components/CreateCommunicationDialog'
import NavigationMenu from 'features/menu/NavigationMenu'
import { useMe } from 'features/ProtectedRoute'
import { pickBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import downloadString from 'utils/downloadString'
import { showError, showInfoMessage } from 'utils/notifier'
import transformSearchParams from 'utils/searchParams'
import RegistrationsTable from './RegistrationsTable'

const ROWS_PER_PAGE = 20

const RegistrationsPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const [page, setPage] = useState(0)
  const queryClient = useQueryClient()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [isCreateCommunicationDialogOpen, setCreateCommunicationDialogOpen] =
    useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const { user } = useMe()

  const filters = useMemo(
    () =>
      pickBy(
        {
          ...transformSearchParams(searchParams),
          limit: String(ROWS_PER_PAGE),
          offset: String(page * ROWS_PER_PAGE),
          organizer:
            user.role === 'organizer' || user.role === 'organizer-premium'
              ? user.id
              : undefined,
        },
        (v) => v !== undefined
      ) as Record<string, string>,

    [searchParams, user, page]
  )

  const {
    data,
    isLoading: registrationsIsLoading,
    isPreviousData,
  } = useQuery(['registrations', filters], () => getRegistrations(filters), {
    keepPreviousData: true,
    onSuccess: (data) => {
      if (
        !isPreviousData &&
        data?.items.length === 1 &&
        (user.role === 'organizer' || user.role === 'organizer-premium')
      ) {
        navigate(`/registrations/${data.items[0].id}`)
      }
    },
    onError: (e) => showError(snackbar, e, t),
  })

  const { mutate: updateRegistration, isLoading: updateRegistrationIsLoading } =
    useMutation(putRegistration, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['registrations', { page }])
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const {
    mutate: updateRegistrations,
    isLoading: updateRegistrationsIsLoading,
  } = useMutation(
    async (registrations: UpdateRegistration[]) => {
      for (const registration of registrations) {
        await putRegistration(registration)
      }
      setSelectedIds([])
    },
    {
      onSuccess: async () => {
        //TODO: set data directly ?
        await queryClient.invalidateQueries(['registrations', { page }])
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: addCommunication, isLoading: addCommunicationIsLoading } =
    useMutation(postCommunication, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['registrations', { page }])
        showInfoMessage(snackbar, t('Communication sent'))
        setSelectedIds([])
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const handleRegistrationsStatusChange = (newStatus: RegistrationStatus) => {
    updateRegistrations(selectedIds.map((id) => ({ id, status: newStatus })))
  }

  const cleanFilters = useMemo(
    () =>
      pickBy(
        {
          ...transformSearchParams(searchParams),
        },
        (value, key) =>
          value !== undefined && !['limit', 'offset', 'organizer'].includes(key)
      ),
    [searchParams]
  )

  const handleExportRegistrations = async () => {
    try {
      const registrations = await getRegistrations(cleanFilters)

      if (registrations && registrations.items.length > 0) {
        const csvData = registrationsToCSV(registrations.items)
        downloadString(csvData, 'text/csv', 'Liste des participants.csv')
      } else {
        showError(
          snackbar,
          t('No registrations found for the selected criteria'),
          t
        )
      }
    } catch (e) {
      showError(snackbar, e, t)
    }
  }

  const handleAddCommunication = (communication: CreateCommunication) => {
    setCreateCommunicationDialogOpen(false)
    addCommunication({
      communication: { ...communication, activityChildren: selectedIds },
    })
  }

  const isLoading =
    registrationsIsLoading ||
    isPreviousData ||
    updateRegistrationIsLoading ||
    updateRegistrationsIsLoading ||
    addCommunicationIsLoading

  const registrations = data?.items
  const totalCount = data?.totalCount

  return (
    <NavigationMenu progress={isLoading} title={t('Registrations')}>
      <Container>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
          mt={0}
          mb={2}
        >
          <Grid item>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                color="secondary"
                style={{
                  color: 'background',
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  width: '30%',
                }}
                sx={{ textTransform: 'none' }}
                onClick={handleExportRegistrations}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Upload /> <div>{t('Export registrations')}</div>
                </Stack>
              </Button>
            </Stack>
            <Paper sx={{ mt: 4, mb: 4 }}>
              <RegistrationsTable
                registrations={registrations || []}
                page={page}
                rowsPerPage={ROWS_PER_PAGE}
                onPageChange={(newPage) => {
                  setSearchParams({
                    ...filters,
                    offset: String(newPage * ROWS_PER_PAGE),
                  })
                  setPage(newPage)
                }}
                totalCount={totalCount}
                onStatusChange={(id, status) =>
                  updateRegistration({ id, status })
                }
                selected={selectedIds}
                onSelect={setSelectedIds}
                onSelectedStatusChange={handleRegistrationsStatusChange}
                onSendCommunication={() =>
                  setCreateCommunicationDialogOpen(true)
                }
                onExport={handleExportRegistrations}
              />
            </Paper>

            <CreateCommunicationDialog
              open={isCreateCommunicationDialogOpen}
              onClose={() => setCreateCommunicationDialogOpen(false)}
              onSendCommunication={handleAddCommunication}
            />
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={6}
            ></Stack>
          </Grid>
        </Grid>
      </Container>
    </NavigationMenu>
  )
}

export default RegistrationsPage
