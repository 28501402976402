import { Add, Delete, Edit, NavigateNext } from '@mui/icons-material'
import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { getUserPath } from 'AppRoutes'
import { OrganizationUser } from 'data/models/OrganizationUser'
import { useMe } from 'features/ProtectedRoute'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const OrganizationUsersTable = ({
  organizationUsers,
  onDelete,
  onEdit,
  onAdd,
}: {
  organizationUsers: OrganizationUser[]
  onDelete: (userId: string) => void
  onEdit: (organizationUser: OrganizationUser) => void
  onAdd: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useMe()

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t('Members')}
        </Typography>
        {user.role === 'admin' && (
          <Tooltip title={String(t('Add'))}>
            <IconButton onClick={onAdd}>
              <Add />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('First name')}</TableCell>
              <TableCell>{t('Last name')}</TableCell>
              <TableCell>{t('Email')}</TableCell>
              <TableCell align="right">{t('Role')}</TableCell>
              <TableCell align="right">{t('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizationUsers.map((organizationUser) => (
              <TableRow key={organizationUser.user.id}>
                <TableCell width={160}>
                  {organizationUser.user.firstName}
                </TableCell>
                <TableCell width={160}>
                  {organizationUser.user.lastName}
                </TableCell>
                <TableCell width={160}>{organizationUser.user.email}</TableCell>
                <TableCell align="right">{t(organizationUser.role)}</TableCell>
                <TableCell align="right" width={120}>
                  <Stack direction="row">
                    {user?.role === 'admin' && (
                      <Stack direction="row">
                        <IconButton onClick={() => onEdit(organizationUser)}>
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => onDelete(organizationUser.user.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Stack>
                    )}
                    <IconButton
                      onClick={() =>
                        navigate(getUserPath(organizationUser.user.id))
                      }
                    >
                      <NavigateNext />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default OrganizationUsersTable
