import { CircularProgress, Grid } from '@mui/material'

const Progress = () => {
  return (
    <Grid
      style={{ minHeight: '80vh' }}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Grid>
  )
}

export default Progress
