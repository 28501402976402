import {
  AlternateEmail,
  Badge,
  Cake,
  ChatBubbleOutline,
  CreditCardOff,
  CreditScore,
  FormatColorReset,
  MedicalServices,
  Person,
  Phone,
  Place,
  Pool,
  Vaccines,
} from '@mui/icons-material'
import {
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { REGISTRATIONS_PATH } from 'AppRoutes'
import { getRegistration, postCommunication, putRegistration } from 'data/api'
import { CreateCommunication } from 'data/models/Communication'
import { Registration, RegistrationStatus } from 'data/models/Registration'
import CreateCommunicationDialog from 'features/components/CreateCommunicationDialog'
import NavigationMenu from 'features/menu/NavigationMenu'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { ISO3ToSymbol } from 'utils/currency'
import { showError, showInfoMessage } from 'utils/notifier'
import AddPaymentDialog from './AddPaymentDialog'
import Communications from './Communications'

const RegistrationPage = () => {
  const { id } = useParams() as {
    id: string
  }
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )
  const [isCreateCommunicationDialogOpen, setCreateCommunicationDialogOpen] =
    useState(false)
  const [isAddPaymentDialogOpen, setIsAddPaymentDialogOpen] = useState(false)
  const [paid, setPaid] = useState(0)

  const { data: registration, isLoading: activityChildIsLoading } = useQuery(
    ['registrations', { id }],
    () => getRegistration(id),
    {
      onSuccess: (data) => setPaid(data.paid),
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: addCommunication, isLoading: addCommunicationIsLoading } =
    useMutation(postCommunication, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['registrations', { id }])
        showInfoMessage(snackbar, t('Communication sent'))
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const { mutate: updateRegistration, isLoading: updateActivityChildLoading } =
    useMutation(putRegistration, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['registrations', { id }])
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const handleStatusChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: RegistrationStatus
  ) => {
    if (registration) {
      updateRegistration({
        id: registration.id,
        status: newStatus,
      })
    }
  }

  const handlePaidChange = () => {
    if (registration) {
      updateRegistration({
        id: registration.id,
        paid: paid,
      })
    }
  }

  const handleAddCommunication = (communication: CreateCommunication) => {
    if (registration) {
      setCreateCommunicationDialogOpen(false)
      addCommunication({
        communication: {
          ...communication,
          activityChildren: [registration.id],
        },
      })
    }
  }

  const isLoading =
    activityChildIsLoading ||
    updateActivityChildLoading ||
    addCommunicationIsLoading

  return (
    <NavigationMenu
      progress={isLoading}
      title={
        registration && (
          <Breadcrumbs sx={{ color: 'inherit' }}>
            <Link
              underline="hover"
              variant="h6"
              color="inherit"
              component={RouterLink}
              to={REGISTRATIONS_PATH}
            >
              {t('Registrations')}
            </Link>
            <Typography variant="h6">{`${registration.child.firstName} ${registration.child.lastName}`}</Typography>
          </Breadcrumbs>
        )
      }
    >
      <Container>
        {registration && (
          <Stack spacing={4} mt={4} mb={4}>
            <Stack direction="row" spacing={3}>
              <Card sx={{ padding: '1rem', width: '70%' }}>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Stack direction="column">
                    <Grid>
                      <Typography
                        sx={{
                          paddingBottom: '0.5rem',
                          fontWeight: 600,
                          fontSize: '1.8rem',
                        }}
                        variant="h6"
                      >{`${registration.child.firstName} ${registration.child.lastName}`}</Typography>
                    </Grid>
                    <Stack direction="row">
                      <Grid>
                        <Typography
                          sx={{
                            paddingBottom: '0.5rem',
                            fontWeight: 600,
                          }}
                        >
                          {new Date(registration.fromDate).toLocaleDateString(
                            'en-GB'
                          )}{' '}
                          -{' '}
                          {new Date(registration.toDate).toLocaleDateString(
                            'en-GB'
                          )}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          sx={{ paddingBottom: '1rem', fontWeight: 600 }}
                        >
                          &nbsp;- {registration.activity.name}
                        </Typography>
                      </Grid>
                    </Stack>
                  </Stack>
                  <Grid>
                    {registration.paid >= registration.price?.price ? (
                      <Tooltip
                        title={String(t('Paid'))}
                        sx={{
                          borderRadius: '6px',
                          padding: '0.5rem',
                          backgroundColor: 'success.main',
                          color: '#fff',
                        }}
                      >
                        <CreditScore
                          sx={{
                            width: '25px',
                            height: '25px',
                            color: 'success.main',
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={String(t('Not paid'))}
                        sx={{
                          padding: '0.5rem',
                        }}
                      >
                        <CreditCardOff
                          sx={{
                            width: '25px',
                            height: '25px',
                            color: 'error.main',
                          }}
                        />
                      </Tooltip>
                    )}
                  </Grid>
                </Stack>
                <Divider />
                {registration.child.birthDate && (
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ paddingTop: '1rem' }}
                  >
                    <Cake />
                    <Typography>
                      {registration.child.birthDate
                        ? new Date(
                            registration.child.birthDate
                          ).toLocaleDateString('en-GB')
                        : t('Unknown')}
                    </Typography>
                  </Stack>
                )}
                {registration.child.address && (
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ paddingTop: '1rem' }}
                  >
                    <Place />
                    <Typography>
                      {registration.child.address?.street}
                      {', '}
                      {registration.child.address?.postalCode}{' '}
                      {registration.child.address?.city}{' '}
                      {registration.child.address?.administrativeArea}{' '}
                      {registration.child.address?.country}
                    </Typography>
                  </Stack>
                )}
                <Stack direction="row" spacing={1} sx={{ paddingTop: '1rem' }}>
                  <Person />
                  <Typography>
                    {t('Contact')}
                    {': '}
                    {registration.child.user?.lastName}{' '}
                    {registration.child.user?.firstName}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ paddingTop: '1rem' }}>
                  <AlternateEmail />
                  <Typography>
                    {registration.child.user?.email}
                    {registration.child.user?.phoneNumber
                      ? ` - ${registration.child.user?.phoneNumber}`
                      : ''}
                  </Typography>
                </Stack>
                {registration.child.idNumber && (
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ paddingTop: '1rem' }}
                  >
                    <Badge />
                    <Typography>
                      {t('National registration number')}
                      {': '}
                      {registration.child.idNumber}
                    </Typography>
                  </Stack>
                )}
                {registration.comment && (
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ paddingTop: '1rem' }}
                  >
                    <ChatBubbleOutline />
                    <Typography>{registration.comment}</Typography>
                  </Stack>
                )}
              </Card>
              <Card sx={{ width: '30%' }}>
                <CardContent>
                  <Stack spacing={2} direction="column">
                    <Stack
                      spacing={3}
                      direction="row"
                      sx={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '1.5rem',
                      }}
                    >
                      <Typography sx={{ fontsize: '0.7rem', fontWeight: 600 }}>
                        {t('Payments')}
                      </Typography>
                      <Button
                        style={{ textTransform: 'none' }}
                        variant="contained"
                        sx={{
                          borderRadius: '12px',
                          backgroundColor: 'success.main',
                          padding: '0.5rem',
                          color: '#fff',
                          fontSize: '1rem',
                        }}
                        onClick={() =>
                          setIsAddPaymentDialogOpen(!isAddPaymentDialogOpen)
                        }
                      >
                        {t('Add')}
                      </Button>
                    </Stack>
                    <Divider sx={{ marginTop: '1.5rem !important' }} />
                    <Stack sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <ul>
                        {registration.payments
                          ?.sort((a, b) =>
                            a.createdAt.localeCompare(b.createdAt)
                          )
                          .map((payment, index) => (
                            <li key={index}>
                              {payment.createdAt} {' - '} {payment.amount}{' '}
                              {ISO3ToSymbol(registration.price?.currency) ??
                                '€'}
                            </li>
                          ))}
                      </ul>
                      <Typography>
                        {t('Total paid')}: {registration.paid?.toFixed(2) ?? 0}{' '}
                        {ISO3ToSymbol(registration.price?.currency) ?? '€'}{' '}
                        {t('of')} {String(registration.price?.price) ?? 0}{' '}
                        {ISO3ToSymbol(registration.price?.currency) ?? '€'}
                      </Typography>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
            <Card sx={{ padding: '1rem' }}>
              <Stack
                direction="row"
                spacing={1}
                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Stack direction="column">
                  <Grid>
                    <Typography
                      sx={{
                        paddingBottom: '0.5rem',
                        fontWeight: 600,
                      }}
                    >
                      {t('Medical information')}
                    </Typography>
                  </Grid>
                </Stack>
              </Stack>
              <Divider />
              <Stack direction="row" spacing={1} sx={{ paddingTop: '1rem' }}>
                <MedicalServices />
                {registration.child?.familyDoctor?.name ? (
                  <Typography>
                    Dr. {registration.child.familyDoctor?.name}
                  </Typography>
                ) : (
                  <Typography>{t('Unknown')}</Typography>
                )}
              </Stack>
              <Stack direction="row" spacing={1} sx={{ paddingTop: '1rem' }}>
                <Phone />
                {registration.child?.familyDoctor?.phoneNumber ? (
                  <Typography>
                    {registration.child.familyDoctor?.phoneNumber}
                  </Typography>
                ) : (
                  <Typography>{t('Unknown')}</Typography>
                )}
              </Stack>
              <Stack direction="row" spacing={1} sx={{ paddingTop: '1rem' }}>
                <Vaccines />
                <Typography>
                  {registration.child?.tetanusVaccinationDate
                    ? t('Tetanus vaccination date') +
                      ': ' +
                      new Date(
                        registration.child?.tetanusVaccinationDate
                      ).toLocaleDateString('en-GB')
                    : t('Tetanus vaccination date') + ': ' + t('Unknown')}
                </Typography>
              </Stack>
              {registration.child?.comment && (
                <Stack direction="row" spacing={1} sx={{ paddingTop: '1rem' }}>
                  <ChatBubbleOutline />
                  <Typography>{registration.child?.comment}</Typography>
                </Stack>
              )}
            </Card>
            <Card sx={{ padding: '1rem' }}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack direction="column">
                  <Grid>
                    <Typography
                      sx={{
                        paddingBottom: '0.5rem',
                        fontWeight: 600,
                      }}
                    >
                      {t('Swimming level')}
                    </Typography>
                  </Grid>
                </Stack>
              </Stack>
              <Divider />
              <Stack direction="row" spacing={1} sx={{ paddingTop: '1rem' }}>
                <Pool />
                <Typography>
                  {t('Swim level')}
                  {': '}
                  {(() => {
                    switch (registration.child.swimLevel) {
                      case 1:
                        return t('Bad')
                      case 2:
                        return t('Average')
                      case 3:
                        return t('Good')
                      case 4:
                        return t('Excellent')
                      default:
                        return t('Unknown')
                    }
                  })()}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} sx={{ paddingTop: '1rem' }}>
                <FormatColorReset />
                <Typography>
                  {t('Afraid of water')}
                  {': '}
                  {!registration.child.afraidOfWater ? t('No') : t('Yes')}
                </Typography>
              </Stack>
            </Card>

            <Card>
              <CardContent>
                <Stack spacing={2} direction="column">
                  <Typography sx={{ fontWeight: 600 }}>
                    {t('Communications')}
                  </Typography>
                  <Communications
                    communications={registration.communications}
                  />
                </Stack>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction={isSmallScreen ? 'column' : 'row'}
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                  mb={1}
                >
                  <Grid item>
                    <Button
                      onClick={() => setCreateCommunicationDialogOpen(true)}
                      style={{ textTransform: 'none' }}
                      variant="contained"
                      sx={{
                        borderRadius: '12px',
                        backgroundColor: 'primary.main',
                        padding: '0.7rem',
                        color: '#fff',
                        fontSize: '1rem',
                      }}
                    >
                      {t('New communication')}
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
            {/* <Card>
              <CardContent>
                <Stack spacing={2} direction="column">
                  <Typography variant="h6">{t('Child')}</Typography>
                  <Child child={registration.child} />
                </Stack>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Stack spacing={2} direction="column">
                  <Typography variant="h6">{t('Parent')}</Typography>
                  <User user={registration.child.user?} hideRole={true} />
                </Stack>
              </CardContent>
            </Card> */}
          </Stack>
        )}
        <AddPaymentDialog
          open={isAddPaymentDialogOpen}
          onClose={() => setIsAddPaymentDialogOpen(false)}
          registration={registration || ({} as Registration)}
        />
        <CreateCommunicationDialog
          open={isCreateCommunicationDialogOpen}
          onClose={() => setCreateCommunicationDialogOpen(false)}
          onSendCommunication={handleAddCommunication}
        />
      </Container>
    </NavigationMenu>
  )
}

export default RegistrationPage
