import { getGeocode, getLatLng } from 'use-places-autocomplete'

const geocode = async (address: string): Promise<[number, number]> => {
  const geocodeResults = await getGeocode({
    address: address,
  })
  if (geocodeResults && geocodeResults.length > 0) {
    const latLng = getLatLng(geocodeResults[0])
    return [latLng.lat, latLng.lng]
  } else {
    throw new Error("Can't find address")
  }
}

export default geocode
