import { createTheme } from '@mui/material/styles'

export default createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3831AC',
    },
    secondary: {
      main: '#FEE054',
    },
    background: {
      default: '#F5F5F5',
    },
    success: {
      main: '#21B590',
    },
    error: {
      main: '#F60168',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'primary',
      },
    },
  },
})
