import ActivitiesPage from 'features/activities/ActivitiesPage'
import ActivityPage from 'features/activity/ActivityPage'
import OrganizationPage from 'features/organization/OrganizationPage'
import OrganizationsPage from 'features/organizations/OrganizationsPage'
import ProfilePage from 'features/profile/ProfilePage'
import ProtectedRoutes from 'features/ProtectedRoute'
import RegistrationPage from 'features/registration/RegistrationPage'
import RegistrationsPage from 'features/registrations/RegistrationsPage'
import ForgotPassword from 'features/signin/ForgotPassword'
import SignInPage from 'features/signin/SignInPage'
import SignUpPage from 'features/signup/SignUpPage'
import UserPage from 'features/user/UserPage'
import UsersPage from 'features/users/UsersPage'
import { Navigate, Route, Routes } from 'react-router'

export const SIGNUP_PATH = '/sign-up'
export const SIGNIN_PATH = '/sign-in'
export const FORGOTPASSWORD_PATH = '/forgot-password'
export const ACTIVITIES_PATH = '/activities'
export const ORGANIZATIONS_PATH = '/organizations'
export const ORGANIZATION_PATH = '/organizations/:id'
export const USERS_PATH = '/users'
export const USER_PATH = '/users/:id'
export const ACTIVITY_PATH = '/activities/:id'
export const PROFILE_PATH = '/profile'
export const REGISTRATION_PATH = '/registrations/:id'
export const REGISTRATIONS_PATH = '/registrations'

export const getRegistrationPath = (id: string) => {
  return `${REGISTRATION_PATH.replace(':id', id)}`
}

export const getActivityPath = (id: string) => {
  return `${ACTIVITY_PATH.replace(':id', id)}`
}

export const getUserPath = (id: string) => {
  return `${USER_PATH.replace(':id', id)}`
}

export const getOrganizationPath = (id: string) => {
  return `${ORGANIZATION_PATH.replace(':id', id)}`
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/" element={<Navigate to={ACTIVITIES_PATH} />} />
        <Route path={ACTIVITIES_PATH} element={<ActivitiesPage />} />
        <Route path={PROFILE_PATH} element={<ProfilePage />} />
        <Route path={ACTIVITY_PATH} element={<ActivityPage />} />
        <Route path={REGISTRATION_PATH} element={<RegistrationPage />} />
        <Route path={ORGANIZATIONS_PATH} element={<OrganizationsPage />} />
        <Route path={ORGANIZATION_PATH} element={<OrganizationPage />} />
        <Route path={USERS_PATH} element={<UsersPage />} />
        <Route path={USER_PATH} element={<UserPage />} />
        <Route path={REGISTRATIONS_PATH} element={<RegistrationsPage />} />
      </Route>
      <Route path={SIGNIN_PATH} element={<SignInPage />} />
      <Route path={SIGNUP_PATH} element={<SignUpPage />} />
      <Route path={FORGOTPASSWORD_PATH} element={<ForgotPassword />} />
    </Routes>
  )
}

export default AppRoutes
