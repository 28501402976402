import { Close } from '@mui/icons-material'
import {
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/system'

const ResponsiveDialog = ({
  title,
  open,
  onClose,
  children,
  ...props
}: { title: string; onClose: () => void } & DialogProps) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      data-role="responsive-dialog"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="md"
      {...props}
    >
      <DialogTitle>
        {title}
        <IconButton
          data-role="close-button"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  )
}

export default ResponsiveDialog
