import { Block, Done, RemoveCircleOutline } from '@mui/icons-material'
import { ToggleButton, Tooltip } from '@mui/material'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { RegistrationStatus } from 'data/models/Registration'
import { useTranslation } from 'react-i18next'

const ActivityChildStatusButtonGroup = ({
  value,
  onChange,
  iconified = false,
}: {
  value?: RegistrationStatus
  onChange: (
    event: React.MouseEvent<HTMLElement>,
    newStatus: RegistrationStatus
  ) => void
  iconified?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      exclusive
      value={value}
      onChange={onChange}
    >
      <ToggleButton value="confirmed" color="success">
        {iconified ? (
          <Tooltip title={String(t('Confirmed'))}>
            <Done />
          </Tooltip>
        ) : (
          t('Confirmed')
        )}
      </ToggleButton>
      <ToggleButton value="rejected" color="error">
        {iconified ? (
          <Tooltip title={String(t('Rejected'))}>
            <Block />
          </Tooltip>
        ) : (
          t('Rejected')
        )}
      </ToggleButton>
      <ToggleButton value="none" color="error">
        {iconified ? (
          <Tooltip title={String(t('No status'))}>
            <RemoveCircleOutline />
          </Tooltip>
        ) : (
          t('No status')
        )}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default ActivityChildStatusButtonGroup
