import config from 'config/config'
import { FirebaseError, initializeApp } from 'firebase/app'
import {
  browserLocalPersistence,
  browserSessionPersistence,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  NextOrObserver,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  User,
} from 'firebase/auth'
import { EmailAlreadyInUse } from './EmailAlreadyInUse'
import { UserNotFound } from './models/UserNotFound'

const firebaseApp = initializeApp(config.firebase.config)
const auth = getAuth(firebaseApp)

export type Session = User

if (config.firebase.emulator) {
  const emulatorUrl: string =
    (import.meta.env.VITE_FIREBASE_EMULATOR_URL as string) ||
    'http://localhost:9099'
  connectAuthEmulator(auth, emulatorUrl)
}

export const signInWithCrendentials = async (
  email: string,
  password: string,
  rememberMe = true
) => {
  if (rememberMe) {
    await setPersistence(auth, browserLocalPersistence)
  } else {
    await setPersistence(auth, browserSessionPersistence)
  }

  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (e) {
    if (
      e instanceof FirebaseError &&
      (e.code === 'auth/user-not-found' ||
        e.code === 'auth/wrong-password' ||
        e.code === 'auth/invalid-email')
    ) {
      throw new UserNotFound(e.message)
    }
    throw e
  }

  return auth.currentUser
}

export const sendVerificationEmail = async (user = auth.currentUser) => {
  if (!user) {
    throw new Error('No user logged in')
  }
  return sendEmailVerification(user)
}

export const signUpWithCredentials = async ({
  email,
  password,
}: {
  email: string
  password: string
}) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password)
    const user = auth.currentUser
    if (!user) {
      throw new Error('No user logged in')
    }
    return user
  } catch (e) {
    if (e instanceof FirebaseError && e.code === 'auth/email-already-in-use') {
      throw new EmailAlreadyInUse('Email already in use')
    }
    throw e
  }
}

export const getSession = async (refresh = false) => {
  let user = auth.currentUser
  if (!user) {
    return null
  }
  const { token } = await user.getIdTokenResult(refresh)
  // console.log('token', token)
  if (refresh) {
    await user.reload()
    user = auth.currentUser
    if (!user) {
      throw new Error('reloading user error')
    }
  }
  return {
    ...user,
    token,
  }
}

export const passwordReset = (email: string) => {
  return sendPasswordResetEmail(auth, email)
}

export const onAuthStateChanged = (next: NextOrObserver<User>) => {
  return auth.onAuthStateChanged(next)
}

export const signOut = () => {
  return auth.signOut()
}
