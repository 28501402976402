import { Clear, Done, HelpOutline } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const LabelValue = ({
  label,
  value,
  direction = 'column',
  children,
  style,
  fontStyle,
}: {
  label: string
  value?: string | boolean | number
  direction?: 'row' | 'column'
  children?: React.ReactNode
  style?: React.CSSProperties
  fontStyle?: React.CSSProperties
}) => {
  const { t } = useTranslation()

  const renderString = (value?: string) => {
    return value && value.length > 0 ? (
      <Typography whiteSpace="pre-line">{value}</Typography>
    ) : (
      <Typography color="text.secondary">{t('Empty')}</Typography>
    )
  }

  const renderBoolean = (value?: boolean) => {
    return value === undefined ? <HelpOutline /> : value ? <Done /> : <Clear />
  }

  const renderValue = (value?: string | boolean | number) => {
    if (typeof value === 'string' || value == null) {
      return renderString(value)
    } else if (typeof value === 'number') {
      return renderString(String(value))
    } else if (typeof value === 'boolean') {
      return renderBoolean(value)
    } else {
      throw new Error(`Unsupported type: ${typeof value}`)
    }
  }

  return (
    <Stack
      direction={direction}
      spacing={1}
      alignItems={direction == 'row' ? 'center' : undefined}
      sx={{ ...style }}
    >
      <Typography
        variant="subtitle1"
        color="text.secondary"
        sx={{ ...fontStyle }}
      >
        {label}
      </Typography>
      {children ? children : renderValue(value)}
    </Stack>
  )
}

export default LabelValue
