const config = {
  endpoint: String(
    import.meta.env.VITE_ENDPOINT_URL || 'http://localhost:3000'
  ),
  firebase: {
    config: {
      apiKey: String(import.meta.env.VITE_FIREBASE_API_KEY),
      authDomain: String(import.meta.env.VITE_FIREBASE_AUTH_DOMAIN),
      projectId: String(import.meta.env.VITE_FIREBASE_PROJECT_ID),
      storageBucket: String(import.meta.env.VITE_FIREBASE_STORAGE_BUCKET),
      messagingSenderId: String(import.meta.env.VITE_FIREBASE_SENDER_ID),
      appId: String(import.meta.env.VITE_FIREBASE_APP_ID),
      measurementId: String(import.meta.env.VITE_FIREBASE_APP_MEASUREMENT_ID),
    },
    emulator: Boolean(import.meta.env.VITE_FIREBASE_EMULATOR === 'true'),
  },
  termsUrl: String(import.meta.env.VITE_TERMS_URL),
  privacyUrl: String(import.meta.env.VITE_PRIVACY_URL),
}

export default config
