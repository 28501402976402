import {
  Event,
  People,
  ReceiptLong,
  SettingsOutlined,
  Store,
} from '@mui/icons-material'
import {
  ACTIVITIES_PATH,
  ORGANIZATIONS_PATH,
  PROFILE_PATH,
  REGISTRATIONS_PATH,
  USERS_PATH,
} from 'AppRoutes'
import { TFunction } from 'i18next'

export const menuOrganizer = (
  t: TFunction,
  role: 'organizer' | 'organizer-premium' | 'parent'
) => {
  return role === 'organizer-premium'
    ? [
        {
          items: [
            {
              title: t('Activities'),
              icon: Event,
              path: ACTIVITIES_PATH,
            },
            {
              title: t('Registrations'),
              icon: ReceiptLong,
              path: REGISTRATIONS_PATH,
            },
          ],
        },
        {
          items: [
            {
              title: t('Organizations'),
              icon: Store,
              path: ORGANIZATIONS_PATH,
            },
          ],
        },
        {
          items: [
            {
              title: t('Profile'),
              icon: SettingsOutlined,
              path: PROFILE_PATH,
            },
          ],
        },
      ]
    : [
        {
          items: [
            {
              title: t('Activities'),
              icon: Event,
              path: ACTIVITIES_PATH,
            },
          ],
        },
        {
          items: [
            {
              title: t('Organizations'),
              icon: Store,
              path: ORGANIZATIONS_PATH,
            },
          ],
        },
        {
          items: [
            {
              title: t('Profile'),
              icon: SettingsOutlined,
              path: PROFILE_PATH,
            },
          ],
        },
      ]
}

export const menuAdmin = (t: TFunction) => [
  {
    items: [
      {
        title: t('Activities'),
        icon: Event,
        path: ACTIVITIES_PATH,
      },
      {
        title: t('Registrations'),
        icon: ReceiptLong,
        path: REGISTRATIONS_PATH,
      },
      {
        title: t('Organizations'),
        icon: Store,
        path: ORGANIZATIONS_PATH,
      },
      {
        title: t('Users'),
        icon: People,
        path: USERS_PATH,
      },
    ],
  },
  {
    items: [
      {
        title: t('Profile'),
        icon: SettingsOutlined,
        path: PROFILE_PATH,
      },
    ],
  },
]
