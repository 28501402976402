import { Grid, Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import GoogleMapLink from 'components/GoogleMapLink'
import LabelValue from 'components/LabelValue'
import { Child as ChildModel } from 'data/models/Child'
import { useTranslation } from 'react-i18next'

const Child = ({ child }: { child: ChildModel }) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <LabelValue label={t('First name')} value={child.firstName} />
        </Grid>
        <Grid item xs={6}>
          <LabelValue label={t('Last name')} value={child.lastName} />
        </Grid>
        <Grid item xs={6}>
          <LabelValue
            label={t('Birth date')}
            value={new Date(child.birthDate).toLocaleDateString('en-GB')}
          />
        </Grid>
        <Grid item xs={6}>
          <LabelValue label={t('ID number')} value={child.idNumber} />
        </Grid>
        <Grid item xs={6}>
          <LabelValue
            label={t('Relationship')}
            value={
              child.relationShip ? String(t(child.relationShip)) : undefined
            }
          />
        </Grid>
        <Grid item xs={12}>
          <LabelValue label={t('Address')}>
            {child.address && (
              <GoogleMapLink
                query={`${child.address.street}, ${child.address.postalCode} ${child.address.city}`}
              />
            )}
          </LabelValue>
        </Grid>
        <Grid item xs={6}>
          <LabelValue label={t('Swim level')} value={child.swimLevel} />
        </Grid>
        <Grid item xs={6}>
          <LabelValue
            label={t('Afraid of water')}
            value={child.afraidOfWater}
          />
        </Grid>
        <Grid item xs={6}>
          <LabelValue label={t('Family doctor')}>
            {child.familyDoctor && (
              <Stack>
                <Typography>{child.familyDoctor.name}</Typography>
                <Link
                  href={`tel:${child.familyDoctor.phoneNumber}`}
                  target="_blank"
                >
                  {child.familyDoctor.phoneNumber}
                </Link>
              </Stack>
            )}
          </LabelValue>
        </Grid>
        <Grid item xs={6}>
          <LabelValue
            label={t('Tetanus vaccination date')}
            value={
              child.tetanusVaccinationDate
                ? new Date(child.tetanusVaccinationDate).toLocaleDateString(
                    'en-GB'
                  )
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12}>
          <LabelValue label={t('Medical comment')} value={child.comment} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Child
