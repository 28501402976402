import { NavigateNext } from '@mui/icons-material'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'
import { getOrganizationPath } from 'AppRoutes'
import { UserOrganization } from 'data/models/OrganizationUser'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const UserOrganizationsTable = ({
  userOrganizations,
}: {
  userOrganizations: UserOrganization[]
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t('Organizations')}
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Name')}</TableCell>
              <TableCell align="right">{t('Role')}</TableCell>
              <TableCell align="right">{t('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userOrganizations.map((organizationUser) => (
              <TableRow key={organizationUser.organization.id}>
                <TableCell>{organizationUser.organization.id}</TableCell>
                <TableCell align="right">{t(organizationUser.role)}</TableCell>
                <TableCell align="right" width={120}>
                  <IconButton
                    onClick={() =>
                      navigate(
                        getOrganizationPath(organizationUser.organization.id)
                      )
                    }
                  >
                    <NavigateNext />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default UserOrganizationsTable
