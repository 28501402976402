import { AppBar, Container, LinearProgress, Stack } from '@mui/material'
import { signInWithCrendentials } from 'data/firebase'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { showError } from 'utils/notifier'
import SignIn from './SignIn'

const SignInPage = () => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  const { mutate: signIn, isLoading } = useMutation(
    ({ email, password }: { email: string; password: string }) =>
      signInWithCrendentials(email, password),
    {
      onSuccess: () => {
        window.location.replace('/')
      },
      onError: (e: Error) => {
        switch (true) {
          case e.message.includes('auth/user-not-found'):
            return showError(snackbar, t('Error: User not found'), t, false)
          case e.message.includes('auth/wrong-password'):
            return showError(snackbar, t('Error: Wrong password'), t, false)
          case e.message.includes('auth/too-many-requests'):
            return showError(
              snackbar,
              t(
                'Error: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
              ),
              t,
              false
            )
          default:
            return showError(snackbar, e, t)
        }
      },
    }
  )

  return (
    <>
      <AppBar position="static">{isLoading && <LinearProgress />}</AppBar>
      <Container maxWidth="xs">
        <Stack
          justifyContent="center"
          alignItems="stretch"
          minHeight="90vh"
          spacing={5}
        >
          <SignIn onSignInClick={signIn} loading={isLoading} />
        </Stack>
      </Container>
    </>
  )
}

export default SignInPage
