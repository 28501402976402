import { ExitToApp } from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Divider,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { signOut } from 'data/firebase'
import { FC, ReactNode, useState } from 'react'

import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

type MenuItem = { title: string; icon: FC; path: string }
export type Menu = {
  items: MenuItem[]
}[]

const drawerWidthMobile = 200
const drawerWidthDesktop = 100

export type NavigationDrawerProps = {
  menu: Menu
  logo?: string
  title: string | ReactNode
  toolbarChildren?: ReactNode
  children: ReactNode
  progress?: boolean
}

const NavigationDrawer = ({
  menu,
  logo = '/Logo.png',
  title,
  toolbarChildren,
  children,
  progress = false,
}: NavigationDrawerProps) => {
  const location = useLocation()
  const navigate = useNavigate()

  const isRouteActive = (routeName: string) => {
    return location.pathname.indexOf(routeName) > -1
  }

  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const renderMobileList = () => {
    return menu.map((list, index) => (
      <Box key={index}>
        <List>
          {list.items.map((item: MenuItem) =>
            item.path.startsWith('http') ? (
              <ListItem
                button
                key={item.title}
                component="a"
                href={item.path}
                target="blank"
              >
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
              </ListItem>
            ) : (
              <ListItemButton
                key={item.title}
                selected={isRouteActive(item.path)}
                onClick={() => navigate(item.path)}
              >
                <ListItemIcon
                  sx={{
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  primary={item.title}
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '0.9rem',
                      color: '#fff',
                    },
                  }}
                />
              </ListItemButton>
            )
          )}
        </List>
        {menu.length - 1 !== index && <Divider />}
      </Box>
    ))
  }

  const renderDesktopList = () => {
    const flattenedList = menu.flatMap((list) => list.items)
    return (
      <List>
        {flattenedList.map((item) =>
          item.path.startsWith('http') ? (
            <ListItem
              sx={{
                pt: 2,
                pb: 2,
              }}
              button
              key={item.title}
              component="a"
              href={item.path}
              target="blank"
            >
              <ListItemIcon>
                <item.icon />
              </ListItemIcon>
            </ListItem>
          ) : (
            <ListItemButton
              sx={{
                pt: 2,
                pb: 2,
                display: 'flex',
                flexDirection: 'column',
                width: drawerWidthDesktop,
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
              }}
              key={item.title}
              selected={isRouteActive(item.path)}
              onClick={() => navigate(item.path)}
            >
              <ListItemIcon
                sx={{
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <item.icon />
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '0.9rem',
                    color: '#fff',
                  },
                }}
              />
            </ListItemButton>
          )
        )}
        <ListItemButton
          sx={{
            pt: 2,
            pb: 2,
            display: 'flex',
            flexDirection: 'column',
            width: drawerWidthDesktop,
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
          }}
          onClick={async () => {
            await signOut()
            window.location.replace('/')
          }}
        >
          <ExitToApp sx={{ color: '#fff' }} />
          <Typography
            variant="body1"
            sx={{
              fontSize: '0.9rem',
              textTransform: 'none',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Logout
          </Typography>
        </ListItemButton>
      </List>
    )
  }

  const drawerMobile = (
    <Grid container direction="column">
      <Grid item alignSelf="center" m={4} p={2}>
        <img
          src={logo}
          alt=""
          style={{
            width: '100px',
            backgroundColor: '#fff',
            borderRadius: '50%',
            padding: '0.3rem',
          }}
        />
      </Grid>
      <Grid item>{renderMobileList()}</Grid>
      <ListItemButton
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: drawerWidthMobile,
        }}
        onClick={async () => {
          await signOut()
          window.location.replace('/')
        }}
      >
        <ExitToApp sx={{ color: '#fff', marginLeft: '1rem' }} />
        <Typography
          variant="body1"
          sx={{
            fontSize: '0.9rem',
            textTransform: 'none',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '1rem',
          }}
        >
          Logout
        </Typography>
      </ListItemButton>
    </Grid>
  )

  const drawerDesktop = (
    <Grid
      container
      direction="column"
      style={{
        overflowX: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid item p={0.3} mb={-1.3}>
        <img
          src="/Logo.png"
          alt=""
          style={{
            width: '70px',
            height: '70px',
            padding: '0.3rem',
            margin: '0.3rem',
          }}
        />
      </Grid>
      <Grid item>{renderDesktopList()}</Grid>
      {/* <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0.5rem',
          width: drawerWidthDesktop,
        }}
      >
        <LanguageSwitchButton
          style={{ flexDirection: 'column', width: '1.5rem', height: '1.5rem' }}
          langs={['gb', 'fr']}
        />
      </Grid> */}
    </Grid>
  )

  const container =
    window !== undefined ? () => window.document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidthDesktop}px)` },
          ml: { sm: `${drawerWidthDesktop}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            {typeof title === 'string' ? (
              <Typography variant="h6" noWrap>
                {title}
              </Typography>
            ) : (
              title
            )}
          </Box>
          {toolbarChildren}
        </Toolbar>
        {progress && <LinearProgress color="secondary" />}
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidthDesktop },
          flexShrink: { sm: 0 },
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          elevation={0}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidthMobile,
              backgroundColor: 'primary.main',
            },
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerMobile}
        </Drawer>
        <Drawer
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidthDesktop,
              backgroundColor: 'primary.main',
            },
          }}
          variant="permanent"
          open
        >
          {drawerDesktop}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}

export default NavigationDrawer
