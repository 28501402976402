import {
  Email,
  FileDownload,
  FilterAlt,
  KeyboardArrowDown,
  KeyboardArrowUp,
  NavigateNext,
  NoiseControlOff,
  Warning,
} from '@mui/icons-material'
import {
  alpha,
  Checkbox,
  Collapse,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { getActivityPath, getRegistrationPath } from 'AppRoutes'
import { getRegistrations } from 'data/api'
import {
  ActivityRegistration,
  isRegistration,
  isRegistrations,
  Registration,
  RegistrationStatus,
} from 'data/models/Registration'
import ActivityChildStatusButtonGroup from 'features/components/ActivityChildStatusButtonGroup'
import { useMe } from 'features/ProtectedRoute'
import { orderBy, pickBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'
import { ISO3ToSymbol } from 'utils/currency'
import { showError } from 'utils/notifier'
import transformSearchParams from 'utils/searchParams'
import RegistrationsFilters from './RegistrationsFilters'

const ROWS_PER_PAGE = 20

const RegistrationsTable = ({
  registrations: initialRegistrations,
  page,
  rowsPerPage = 50,
  totalCount,
  onPageChange,
  onStatusChange,
  onSelectedStatusChange,
  selected = [],
  onSelect,
  onSendCommunication,
  onExport,
}: {
  registrations: Registration[] | ActivityRegistration[]
  page?: number
  rowsPerPage?: number
  totalCount?: number
  onPageChange?: (page: number) => void
  onStatusChange: (id: string, status: RegistrationStatus) => void
  onSelectedStatusChange: (status: RegistrationStatus) => void
  selected: string[]
  onSelect: (ids: string[]) => void
  onSendCommunication: () => void
  onExport: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const [internalPage, setInternalPage] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const { user } = useMe()

  const filters = useMemo(
    () =>
      pickBy(
        {
          ...transformSearchParams(searchParams),
          limit: String(ROWS_PER_PAGE),
          organizer:
            user.role === 'organizer' || user.role === 'organizer-premium'
              ? user.id
              : undefined,
        },
        (v) => v !== undefined
      ) as Record<string, string>,
    [searchParams, user]
  )

  const { isLoading: registrationsIsLoading, isPreviousData } = useQuery(
    ['registrations', filters],
    () => getRegistrations({ ...filters }),
    {
      keepPreviousData: true,
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const isItemSelected = (id: string) => selected.indexOf(id) !== -1

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (registrations && event.target.checked) {
      onSelect(registrations.map((r) => r.id))
    } else {
      onSelect([])
    }
  }

  const isLoading = registrationsIsLoading || isPreviousData

  const [registrations, setRegistrations] = useState(initialRegistrations)
  const [sortColumn, setSortColumn] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')

  useEffect(() => {
    setRegistrations(initialRegistrations)
  }, [initialRegistrations])

  const sortTable = (column: string) => {
    return (event: React.MouseEvent<HTMLTableCellElement>) => {
      event.preventDefault()
      const sortedRegistrations = orderBy(
        registrations,
        column,
        sortDirection === 'asc' ? 'asc' : ['desc', 'asc']
      ) as Registration[] | ActivityRegistration[]
      setSortColumn(column)
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
      setRegistrations(sortedRegistrations)
    }
  }

  const [filtered, setFiltered] = useState(false)

  const handleFilterClick = () => {
    setFiltered(!filtered)
  }

  const handleFiltersChange = (newFilters: Record<string, string>) => {
    setSearchParams({
      ...newFilters,
      offset: String(0),
    })
  }

  const handleRowClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    onSelect(newSelected)
  }

  const handleStatusChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: RegistrationStatus,
    id: string
  ) => {
    onStatusChange(id, newStatus)
    initialRegistrations.map((a) => a.id === id && (a.status = newStatus))
  }

  const EnhancedTableToolbar = ({ numSelected }: { numSelected: number }) => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t(`${numSelected} selected`, { count: numSelected })}
          </Typography>
        ) : (
          <>
            <Tooltip title={String(t('Filters'))}>
              <FilterAlt
                sx={{
                  width: '1.3rem',
                  color: filtered ? 'primary.main' : '#bdbdbd',
                  cursor: 'pointer',
                  marginRight: !filtered ? '0.3rem' : 0,
                }}
                onClick={handleFilterClick}
              />
            </Tooltip>
            {filtered ? (
              <FilterTableToolbar />
            ) : (
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {t('Registrations')}
              </Typography>
            )}
          </>
        )}
        {numSelected > 0 && (
          <Stack direction={'row'} spacing={2}>
            <Tooltip title={String(t('Send communication'))}>
              <IconButton onClick={onSendCommunication}>
                <Email />
              </IconButton>
            </Tooltip>
            <Tooltip title={String(t('Export'))}>
              <IconButton
                onClick={() => {
                  onExport()
                }}
              >
                <FileDownload />
              </IconButton>
            </Tooltip>
            <ActivityChildStatusButtonGroup
              onChange={(event, newStatus) => onSelectedStatusChange(newStatus)}
            />
          </Stack>
        )}
      </Toolbar>
    )
  }

  const FilterTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Stack direction={'row'} spacing={2}>
          <RegistrationsFilters
            isLoading={isLoading}
            onSubmit={handleFiltersChange}
            filters={filters}
          />
        </Stack>
      </Toolbar>
    )
  }

  const Row = ({
    registration,
  }: {
    registration: Registration | ActivityRegistration
  }) => {
    const isSelected = isItemSelected(registration.id)
    const [open, setOpen] = useState(false)

    return (
      <>
        <TableRow key={registration.id} selected={isSelected} hover>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isSelected}
              onClick={(event) => handleRowClick(event, registration.id)}
            />
          </TableCell>
          <TableCell>
            {new Date(registration.createdAt).toLocaleDateString('en-GB', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            })}
          </TableCell>
          <TableCell>{`${registration.child.firstName} ${registration.child.lastName}`}</TableCell>
          {isRegistration(registration) && (
            <TableCell>
              <Link
                component={RouterLink}
                to={getActivityPath(registration.activity.id)}
              >
                {registration.activity.name}
              </Link>
            </TableCell>
          )}
          <TableCell>{`${new Date(registration.fromDate).toLocaleDateString(
            'en-GB',
            { year: '2-digit', month: '2-digit', day: '2-digit' }
          )} - ${new Date(registration.toDate).toLocaleDateString('en-GB', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
          })}`}</TableCell>
          <TableCell>
            {registration.price ? (
              <>
                <Typography sx={{ fontWeight: 'medium' }}>
                  {registration.price.price}
                  {ISO3ToSymbol(registration.price.currency)}
                </Typography>
                <Typography color="text.secondary">
                  {registration.price.description}
                </Typography>
              </>
            ) : (
              <NoiseControlOff fontSize="inherit" />
            )}
          </TableCell>
          <TableCell>
            {registration.paid ? (
              <>
                <Typography>
                  {registration.paid}
                  {ISO3ToSymbol(registration.price.currency)}
                </Typography>
              </>
            ) : (
              <NoiseControlOff fontSize="inherit" />
            )}
          </TableCell>
          <TableCell>
            <ActivityChildStatusButtonGroup
              value={registration.status}
              onChange={(event, newStatus) =>
                handleStatusChange(event, newStatus, registration.id)
              }
              iconified
            />
          </TableCell>
          <TableCell>
            <Stack direction="row" justifyContent="end">
              {registration.comment && (
                <IconButton size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowDown /> : <Warning />}
                </IconButton>
              )}
              <IconButton
                onClick={() => navigate(getRegistrationPath(registration.id))}
              >
                <NavigateNext />
              </IconButton>
            </Stack>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto">
              <Box sx={{ margin: 2 }}>
                <Typography>{registration.comment}</Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <EnhancedTableToolbar numSelected={selected.length} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={
                    selected.length > 0 &&
                    selected.length < registrations.length
                  }
                  checked={
                    registrations.length > 0 &&
                    selected.length === registrations.length
                  }
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell
                onClick={sortTable('createdAt')}
                sx={{ cursor: 'pointer' }}
              >
                <Stack
                  direction="row"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {t('Created at')}
                  {sortColumn === 'createdAt' &&
                    (sortDirection === 'asc' ? (
                      <KeyboardArrowUp fontSize="small" />
                    ) : (
                      <KeyboardArrowDown fontSize="small" />
                    ))}
                </Stack>
              </TableCell>
              <TableCell onClick={sortTable('name')} sx={{ cursor: 'pointer' }}>
                <Stack
                  direction="row"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {t('Name')}
                  {sortColumn === 'name' &&
                    (sortDirection === 'asc' ? (
                      <KeyboardArrowUp fontSize="small" />
                    ) : (
                      <KeyboardArrowDown fontSize="small" />
                    ))}
                </Stack>
              </TableCell>
              {isRegistrations(registrations) && (
                <TableCell
                  onClick={sortTable('registration.activity.name')}
                  sx={{ cursor: 'pointer' }}
                >
                  {t('Activity')}
                </TableCell>
              )}
              <TableCell>{t('Dates')}</TableCell>
              <TableCell>{t('Price')}</TableCell>
              <TableCell>{t('Paid')}</TableCell>
              <TableCell>{t('Status')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {registrations.map((registration) => {
              return <Row key={registration.id} registration={registration} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount || initialRegistrations.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[{ label: '', value: -1 }]}
        page={page || internalPage}
        onPageChange={(event, newPage) => {
          if (onPageChange) {
            onPageChange(newPage)
          } else {
            setInternalPage(newPage)
          }
        }}
      />
    </>
  )
}

export default RegistrationsTable
