import {
  Autocomplete,
  Checkbox,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { Category } from 'data/models/Activity'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const FormCategoriesAutocomplete = ({
  control,
  name,
  categories,
  defaultValue = [],
  rules,
  ...textFieldProps
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, never>
  name: string
  categories: Category[]
  defaultValue?: Category[]
  rules?: Record<string, unknown>
} & TextFieldProps) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: {
          value: !!textFieldProps.required,
          message: t('Required'),
        },
        ...rules,
      }}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => {
        return (
          <Autocomplete
            {...field}
            onChange={(event, value) => onChange(value)}
            multiple
            groupBy={(option) =>
              categories.find((c) =>
                c.childCategories?.map((cc) => cc.name)?.includes(option.name)
              )?.name || ''
            }
            disableCloseOnSelect
            options={categories
              .map(
                (category) =>
                  category.childCategories?.map((child) => child) || category
              )
              .flat()}
            isOptionEqualToValue={(option: Category, value: Category) =>
              option.name === value.name
            }
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Categories')}
                helperText={error ? error.message : textFieldProps.helperText}
                error={!!error}
                autoComplete="off"
              />
            )}
          />
        )
      }}
    />
  )
}

export default FormCategoriesAutocomplete
