import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

const DuplicateConfirmationDialog = ({
  entityName,
  open,
  onClose,
  onConfirm,
  ...props
}: {
  entityName: string
  onClose: () => void
  onConfirm: () => void
} & DialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} {...props}>
      <DialogTitle>{t('Confirm duplication')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Are you sure you want duplicate this activity ')}
          <Box sx={{ fontStyle: 'oblique' }} component={'span'}>
            {entityName} ?
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        <Button
          onClick={() => {
            onClose()
            onConfirm()
          }}
        >
          {t('Duplicate')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DuplicateConfirmationDialog
