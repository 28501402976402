export type Address = {
  street: string
  postalCode: string
  city: string
  administrativeArea: AdministrativeArea
  country: string
}

export type ActivityAddress = Address & { location?: [number, number] }

export const administrativeAreas = [
  'Antwerpen',
  'Brabant-Wallon',
  'Bruxelles',
  'Hainaut',
  'Liège',
  'Limburg',
  'Luxembourg',
  'Namur',
  'Oost-Vlaanderen',
  'Vlaams-Brabant',
  'West-Vlaanderen',
]

export type AdministrativeArea = typeof administrativeAreas[number]

export const Mutualitie = [
  'Christelijke Mutualiteit',
  'Mutualité chrétienne',
  'Vlaams Neutraal Ziekenfonds',
  'Mutualité Neutre',
  'Mutualia',
  'Neutraal Ziekenfonds Vlaanderen',
  'Solidaris',
  'Mutualité Socialiste du Luxembourg',
  'LM Mutplus',
  'LM Oost-Vlaanderen',
  'Mutualité Liberale',
  'LM Plus',
  'Partena Mut',
  'Freie Krankenkasse',
  'Helan',
  'Hulpkas voor Ziekte- en Invaliditeitsverzekering',
  'Kas der Geneeskundige Verzorging van het Huisgezin',
]
