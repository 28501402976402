import { FormControlProps, Slider, SliderProps } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const FormSlider = ({
  control,
  name,
  defaultValue = 0,
  rules,
  ...props
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, never>
  name: string
  defaultValue?: number | number[]
  rules?: Record<string, unknown>
} & Omit<FormControlProps & SliderProps, 'defaultValue'>) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: {
          value: !!props.required,
          message: t('Required'),
        },
        ...rules,
      }}
      render={({ field }) => <Slider {...field} {...props}></Slider>}
    />
  )
}

export default FormSlider
