import LoadingButton from '@mui/lab/LoadingButton'
import { DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import FormSelect from 'components/FormSelect'
import FormTextField from 'components/FormTextField'
import ResponsiveDialog from 'components/ResponsiveDialog'
import { putUser } from 'data/api'
import { administrativeAreas, Mutualitie } from 'data/models/Address'
import {
  CreateUser,
  UpdateUser,
  UserDetails,
  UserRole,
  userRoles,
} from 'data/models/User'
import { pickBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { showError, showInfoMessage } from 'utils/notifier'
import pickNonEmpty from 'utils/pickObject'

type EditUserForm = Omit<CreateUser, 'role'> & {
  role: UserRole | ''
}

const EditUserDialog = ({
  user,
  open,
  onClose,
}: {
  user?: UserDetails
  open: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<EditUserForm>({
    mode: 'all',
    defaultValues: {
      ...user,
      role: user?.role || '',
      address: user?.address
        ? {
            ...user?.address,
          }
        : undefined,
    },
  })
  const queryClient = useQueryClient()
  const snackbar = useSnackbar()

  const { mutate: updateUser, isLoading: updateUserLoading } = useMutation(
    putUser,
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(['users', { id: variables.id }])
        showInfoMessage(snackbar, t('User updated'))
        onClose()
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const onSubmit = (data: EditUserForm): void => {
    const address = pickNonEmpty(data.address)

    updateUser(
      pickBy(
        {
          ...data,
          phoneNumber:
            data.phoneNumber && data.phoneNumber ? data.phoneNumber : null,
          role: data.role && data.role.length ? data.role : null,
          address,
        },
        (v) => v !== undefined
      ) as UpdateUser
    )
  }

  const isFormValid = isDirty
  const isLoading = updateUserLoading

  return (
    <ResponsiveDialog title={t('Edit user')} open={open} onClose={onClose}>
      <DialogContent>
        <Grid container mt={1} spacing={2}>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name="firstName"
              label={t('First name')}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name="lastName"
              label={t('Last name')}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              control={control}
              name="role"
              label={t('Role')}
              options={userRoles}
              showNoneOption
              noneOptionLabel={t('Parent')}
              displayEmpty
              fullWidth
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <Typography variant="subtitle1" color="text.secondary">
              {t('Address')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name="address.street"
              label={t('Street')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              control={control}
              name="address.postalCode"
              label={t('Postal code')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              control={control}
              name="address.city"
              label={t('City')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              control={control}
              name="address.administrativeArea"
              label={t('Administrative area')}
              options={Object.values(administrativeAreas)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              control={control}
              name="address.country"
              label={t('Country')}
              options={['Belgium']}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <Typography variant="subtitle1" color="text.secondary">
              {t('Info')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name="phoneNumber"
              label={t('Phone number')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              control={control}
              name="nationalRegistrationNumber"
              label={t('National registration number')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              control={control}
              name="gender"
              label={t('Gender')}
              options={['male', 'female', 'other']}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              control={control}
              name="mutuality"
              label={t('Mutuality')}
              options={Mutualitie}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          disabled={!isFormValid}
          loading={isLoading}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}

export default EditUserDialog
