import {
  Block,
  Close,
  CopyAll,
  Delete,
  Done,
  Edit,
  ImageNotSupported,
  PendingOutlined,
} from '@mui/icons-material'
import {
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Box } from '@mui/system'
import { ACTIVITIES_PATH } from 'AppRoutes'
import IconOptionalBoolean from 'components/IconOptionalBoolean'
import LabelValue from 'components/LabelValue'
import {
  deleteActivity,
  getActivity,
  getRegistration,
  postActivity,
  postCommunication,
  putActivity,
  putRegistration,
} from 'data/api'
import { registrationsToCSV } from 'data/csv'
import { ActivityStatus } from 'data/models/Activity'
import { CreateCommunication } from 'data/models/Communication'
import {
  RegistrationStatus,
  UpdateRegistration,
} from 'data/models/Registration'
import EditActivityDialog from 'features/activities/EditActivityDialog'
import CreateCommunicationDialog from 'features/components/CreateCommunicationDialog'
import DeleteConfirmationDialog from 'features/components/DeleteConfirmationDialog'
import DuplicateConfirmationDialog from 'features/components/DuplicateConfirmationDialog'
import RejectedReasonDialog from 'features/components/RejectedReasonDialog'
import NavigationMenu from 'features/menu/NavigationMenu'
import RegistrationsTable from 'features/registrations/RegistrationsTable'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { ISO3ToSymbol } from 'utils/currency'
import { formatDateToSlash } from 'utils/date'
import downloadString from 'utils/downloadString'
import { showError, showInfoMessage } from 'utils/notifier'

const ActivityPage = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams() as { id: string }

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  const queryClient = useQueryClient()
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const [isEditActivityDialogOpen, setIsEditActivityDialogOpen] =
    useState(false)
  const [isDeleteActivityDialogOpen, setDeleteActivityDialogOpen] =
    useState(false)
  const [isDuplicateActivityDialogOpen, setDuplicateActivityDialogOpen] =
    useState(false)
  const [isCreateCommunicationDialogOpen, setCreateCommunicationDialogOpen] =
    useState(false)
  const [isRejectedReasonDialogOpen, setIsRejectedReasonDialogOpen] =
    useState(false)

  const { data: activity, isLoading: activityIsLoading } = useQuery(
    ['activities', { id }],
    () => getActivity(id),
    {
      onError: (e) => showError(snackbar, e, t),
    }
  )
  const { mutate: updateActivity, isLoading: updateActivityLoading } =
    useMutation(putActivity, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['activities', { id }])
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const { mutate: addCommunication, isLoading: addCommunicationIsLoading } =
    useMutation(postCommunication, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['activities', { id }])
        showInfoMessage(snackbar, t('Communication sent'))
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const { mutate: updateRegistration, isLoading: updateActivityChildLoading } =
    useMutation(putRegistration, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['activities', { id }])
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const {
    mutate: updateRegistrations,
    isLoading: updateRegistrationsIsLoading,
  } = useMutation(
    async (registrations: UpdateRegistration[]) => {
      for (const registration of registrations) {
        await putRegistration(registration)
      }
      setSelectedIds([])
    },
    {
      onSuccess: async () => {
        //TODO: set data directly ?
        await queryClient.invalidateQueries(['activities', { id }])
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: removeActivity, isLoading: removeActivityLoading } =
    useMutation(() => deleteActivity(id), {
      onSuccess: () => {
        showInfoMessage(snackbar, t('Activity deleted'))
        navigate(ACTIVITIES_PATH)
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const { mutate: copyActivity, isLoading: duplicateActivityLoading } =
    useMutation(() => duplicateActivity(id), {
      onSuccess: () => {
        showInfoMessage(snackbar, t('Activity duplicated'))
        navigate(ACTIVITIES_PATH)
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const duplicateActivity = async (id: string) => {
    const activity = await getActivity(id)
    const newActivity = await postActivity({
      ...activity,
      organization: activity.organization.id,
      status: 'draft',
      tags: activity.tags.map((tag) => tag.name),
      categories: activity.categories.map((category) => category.name),
      name: `${activity.name} (copie)`,
    })
    return newActivity
  }

  const handleActivityStatusChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: ActivityStatus,
    rejectedReason?: string
  ) => {
    if (activity) {
      if (newStatus === 'rejected') {
        setIsRejectedReasonDialogOpen(true)
      } else {
        updateActivity({ id: activity.id, status: newStatus, rejectedReason })
      }
    }
  }

  const handleExportRegistrations = async () => {
    try {
      const registrations = await Promise.all(
        selectedIds.map((id) => getRegistration(id))
      )
      const csvData = registrationsToCSV(registrations)
      return downloadString(csvData, 'text/csv', 'registrations.csv')
    } catch (e) {
      showError(snackbar, e, t)
    }
  }

  const handleReject = (rejectedReason?: string) => {
    if (activity) {
      updateActivity({ id: activity.id, status: 'rejected', rejectedReason })
    }
  }

  const handleRegistrationsStatusChange = (newStatus: RegistrationStatus) => {
    updateRegistrations(selectedIds.map((id) => ({ id, status: newStatus })))
  }

  const handleActivityHighlightChange = () => {
    if (activity) {
      updateActivity({ id: activity.id, highlight: !activity.highlight })
    }
  }

  const handleAddCommunication = (communication: CreateCommunication) => {
    if (activity) {
      setCreateCommunicationDialogOpen(false)
      addCommunication({
        communication: {
          ...communication,
          activityChildren:
            selectedIds.length > 0
              ? selectedIds
              : activity.registrations.map((r) => r.id),
        },
      })
    }
  }

  const isLoading =
    activityIsLoading ||
    updateActivityLoading ||
    updateActivityChildLoading ||
    removeActivityLoading ||
    duplicateActivityLoading ||
    updateRegistrationsIsLoading ||
    addCommunicationIsLoading

  return (
    <NavigationMenu
      progress={isLoading}
      title={
        <Breadcrumbs sx={{ color: 'inherit' }}>
          <Link
            underline="hover"
            variant="h6"
            color="inherit"
            component={RouterLink}
            to={ACTIVITIES_PATH}
          >
            {t('Activities')}
          </Link>
          {activity && <Typography variant="h6">{activity.name}</Typography>}
        </Breadcrumbs>
      }
      toolbarChildren={
        activity && (
          <Stack direction="row" spacing={1}>
            <Tooltip title={String(t('Edit'))}>
              <IconButton
                data-role="edit-button"
                color="inherit"
                onClick={() => setIsEditActivityDialogOpen(true)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title={String(t('Duplicate'))}>
              <IconButton
                color="inherit"
                onClick={() => setDuplicateActivityDialogOpen(true)}
              >
                <CopyAll />
              </IconButton>
            </Tooltip>
            <Tooltip title={String(t('Delete'))}>
              <IconButton
                color="inherit"
                onClick={() => setDeleteActivityDialogOpen(true)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Stack>
        )
      }
    >
      <Container>
        {activity && (
          <Stack spacing={3} mt={4} mb={4}>
            <Card>
              <CardContent>
                <Stack direction="column" spacing={1}>
                  <Grid item xs={8}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '1.7rem',
                        fontWeight: '600',
                      }}
                    >
                      {t(activity.name)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{t(activity.organization.id ?? '')}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={{ fontWeight: '600' }}>
                      {t(activity.type)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack>
                      {activity.status === 'published' && (
                        <Grid item xs={3}>
                          <Stack direction="row" spacing={1}>
                            <Typography>
                              {t('Status')}
                              {': '}
                              {t(activity.status)}
                            </Typography>
                            <Tooltip title={String(t('Published'))}>
                              <Done sx={{ color: 'success.main' }} />
                            </Tooltip>
                          </Stack>
                        </Grid>
                      )}
                      {activity.status === 'to be validated' && (
                        <Grid item xs={3}>
                          <Stack direction="row" spacing={1}>
                            <Typography>
                              {t('Status')}
                              {': '}
                              {t(activity.status)}
                            </Typography>
                            <Tooltip title={String(t('To be validated'))}>
                              <PendingOutlined
                                sx={{ color: 'secondary.main' }}
                              />
                            </Tooltip>
                          </Stack>
                        </Grid>
                      )}
                      {activity.status === 'rejected' && (
                        <Grid item xs={3}>
                          <Stack direction="row" spacing={1}>
                            <Typography>
                              {t('Status')}
                              {': '}
                              {t(activity.status)}
                            </Typography>
                            <Tooltip title={String(t('Rejected'))}>
                              <Block sx={{ color: 'error.main' }} />
                            </Tooltip>
                          </Stack>
                        </Grid>
                      )}
                      {activity.status === 'closed' && (
                        <Grid item xs={3}>
                          <Stack direction="row" spacing={1}>
                            <Typography>
                              {t('Status')}
                              {': '}
                              {t(activity.status)}
                            </Typography>
                            <Tooltip title={String(t('Closed'))}>
                              <Close sx={{ color: 'error.main' }} />
                            </Tooltip>
                          </Stack>
                        </Grid>
                      )}
                      {activity.status === 'draft' && (
                        <Grid item xs={3}>
                          <Stack direction="row" spacing={1}>
                            <Typography>
                              {t('Status')}
                              {': '}
                              {t(activity.status)}
                            </Typography>
                            <Tooltip title={String(t('Draft'))}>
                              <Edit sx={{ color: '#808080' }} />
                            </Tooltip>
                          </Stack>
                        </Grid>
                      )}
                    </Stack>
                  </Grid>
                </Stack>
              </CardContent>
            </Card>
            <Stack direction="row" spacing={3}>
              <Card sx={{ width: '100%' }}>
                <CardContent>
                  <Grid item xs={8}>
                    <Typography
                      sx={{
                        paddingBottom: '0.5rem',
                        fontSize: '1.3rem',
                        fontWeight: '600',
                        color: 'text.primary',
                      }}
                    >
                      {t('Details')}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LabelValue
                      label={t('Age')}
                      direction="row"
                      value={`${activity.minAge || 0} - ${
                        activity.maxAge || 18
                      } ${t('years')}`}
                      style={{
                        justifyContent: 'space-between',
                        paddingBottom: '0.5rem',
                        paddingTop: '0.5rem',
                      }}
                      fontStyle={{ fontWeight: '600' }}
                    />
                  </Grid>
                  <Divider />
                  <Grid item xs={4} justifySelf="flex-end">
                    <LabelValue
                      label={t('Dates')}
                      direction="row"
                      value={`${formatDateToSlash(activity.fromDate)} ${
                        activity.toDate
                          ? ` - ${formatDateToSlash(activity.toDate)}`
                          : ''
                      }`}
                      style={{
                        justifyContent: 'space-between',
                        paddingBottom: '0.5rem',
                        paddingTop: '0.5rem',
                      }}
                      fontStyle={{ fontWeight: '600' }}
                    />
                  </Grid>
                  <Divider />
                  <Grid item xs={12}>
                    <Stack
                      spacing={1}
                      direction="row"
                      sx={{
                        justifyContent: 'space-between',
                        paddingBottom: '0.5rem',
                        paddingTop: '0.5rem',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                      >
                        {t('Prices')}
                      </Typography>
                      <Stack spacing={1}>
                        {activity.prices && activity.prices.length > 0 ? (
                          activity.prices.map((price) => (
                            <Stack
                              key={price.price}
                              direction="row"
                              spacing={1}
                              alignItems="baseline"
                            >
                              <Typography sx={{ fontWeight: 400 }}>
                                {price.price}
                                {ISO3ToSymbol(price.currency)}
                              </Typography>
                              <Typography color="text.secondary">
                                {price.description}
                              </Typography>
                            </Stack>
                          ))
                        ) : (
                          <Typography color="text.secondary">
                            {t('Empty')}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Grid>
                  <Divider />
                  <Grid item xs={12}>
                    <LabelValue
                      label={t('Address')}
                      value={
                        activity.address
                          ? `${activity.address.street.replace(/''/g, "'")}, ${
                              activity.address.postalCode
                            } ${activity.address.city.replace(/''/g, "'")}`
                          : undefined
                      }
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingTop: '0.5rem',
                      }}
                      fontStyle={{ fontWeight: 600 }}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                  <Stack spacing={1}>
                    <Typography variant="subtitle1" color="text.secondary">
                      {t('Tags')}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      {activity.tags.length > 0 ? (
                        activity.tags.map((tag) => (
                          <Chip
                            key={tag.name}
                            variant="outlined"
                            size="small"
                            label={tag.name}
                          />
                        ))
                      ) : (
                        <Typography color="text.secondary">
                          {t('Empty')}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Grid> */}
                </CardContent>
              </Card>
              <Stack spacing={3}>
                {activity.image ? (
                  <Card
                    sx={{
                      width: 300,
                      height: 200,
                    }}
                  >
                    <Grid>
                      <Box
                        component="img"
                        src={activity.image}
                        alt={activity.image}
                        sx={{
                          width: 300,
                          height: 200,
                          backgroundColor: 'LightGray',
                          objectFit: 'cover',
                        }}
                      />
                    </Grid>
                  </Card>
                ) : (
                  <Card
                    sx={{
                      width: 300,
                      height: 200,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Grid>
                      <ImageNotSupported
                        sx={{
                          color: 'text.secondary',
                          width: '100px',
                          height: '100px',
                        }}
                      />
                    </Grid>
                  </Card>
                )}
                <Card sx={{ padding: '1rem' }}>
                  <Grid item>
                    <Typography
                      sx={{
                        paddingBottom: '0.5rem',
                        fontSize: '1.3rem',
                        fontWeight: '500',
                      }}
                    >
                      {t('Divers')}
                    </Typography>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <IconOptionalBoolean value={activity.externalApproval} />
                      <Typography>{t('ONE approval')}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <IconOptionalBoolean
                        value={activity.reducedMobilityAccess}
                      />
                      <Typography>{t('PMR access')}</Typography>
                    </Stack>
                  </Grid>
                </Card>
              </Stack>
            </Stack>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <LabelValue
                      label={t('Description')}
                      value={activity.description}
                      fontStyle={{ fontWeight: '600', color: 'text.primary' }}
                    />
                  </Grid>
                  {/* {activity.status === 'rejected' && (
                    <Grid item xs={12}>
                      <LabelValue
                        label={t('Rejection reason')}
                        value={activity.rejectedReason}
                      ></LabelValue>
                    </Grid>
                  )} */}
                </Grid>
              </CardContent>
            </Card>
            <Paper>
              <RegistrationsTable
                registrations={activity.registrations}
                onStatusChange={(id, status) =>
                  updateRegistration({ id, status })
                }
                selected={selectedIds}
                onSelect={setSelectedIds}
                onSelectedStatusChange={handleRegistrationsStatusChange}
                onSendCommunication={() =>
                  setCreateCommunicationDialogOpen(true)
                }
                onExport={handleExportRegistrations}
              />
            </Paper>
          </Stack>
        )}

        {activity && (
          <>
            {isEditActivityDialogOpen && (
              <EditActivityDialog
                open={isEditActivityDialogOpen}
                onClose={() => setIsEditActivityDialogOpen(false)}
                activity={activity}
              />
            )}
            <DuplicateConfirmationDialog
              open={isDuplicateActivityDialogOpen}
              onClose={() => setDuplicateActivityDialogOpen(false)}
              onConfirm={copyActivity}
              entityName={activity.name}
            />
            <DeleteConfirmationDialog
              open={isDeleteActivityDialogOpen}
              onClose={() => setDeleteActivityDialogOpen(false)}
              onConfirm={removeActivity}
              entityName={activity.name}
            />
            <CreateCommunicationDialog
              open={isCreateCommunicationDialogOpen}
              onClose={() => setCreateCommunicationDialogOpen(false)}
              onSendCommunication={handleAddCommunication}
            />
            <RejectedReasonDialog
              open={isRejectedReasonDialogOpen}
              onClose={() => setIsRejectedReasonDialogOpen(false)}
              onReject={(reason) => handleReject(reason)}
            />
          </>
        )}
      </Container>
    </NavigationMenu>
  )
}

export default ActivityPage
