import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, Grid } from '@mui/material'
import FormDatePicker from 'components/FormDatePicker'
import FormTextField from 'components/FormTextField'
import ResponsiveDialog from 'components/ResponsiveDialog'
import { putRegistration } from 'data/api'
import { Registration } from 'data/models/Registration'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { showError, showInfoMessage } from 'utils/notifier'

type AddPaymentForm = {
  amount: number
  date: Date
}

const AddPaymentDialog = ({
  registration,
  open,
  onClose,
}: {
  registration: Registration
  open: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const snackbar = useSnackbar()

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    reset,
  } = useForm<AddPaymentForm>({
    mode: 'all',
    defaultValues: {
      amount: 0,
    },
  })

  const { mutate: addPayment, isLoading: addPaymentLoading } = useMutation(
    putRegistration,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['registrations'])
        showInfoMessage(snackbar, t('Payment added'))
        onClose()
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const isFormValid = isDirty && isValid
  const isLoading = addPaymentLoading

  const onSubmit = (data: AddPaymentForm): void => {
    const payment = {
      amount: Number(data.amount),
      createdAt: data.date.toLocaleDateString('en-GB'),
      registration: registration.id,
      child: registration.child.id,
    }

    const sum = Number(registration.paid) + Number(data.amount)

    if (!registration.payments) registration.payments = []

    addPayment({
      id: registration.id,
      paid: sum,
      payments: [...registration.payments, payment],
    })

    reset()
  }

  return (
    <>
      <ResponsiveDialog title={t('Add payment')} open={open} onClose={onClose}>
        <DialogContent>
          <Grid container mt={1} spacing={2}>
            <Grid item xs={12}>
              <FormDatePicker
                control={control}
                name="date"
                label={t('Date of payment')}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name="amount"
                label={t('Amount')}
                type="number"
                min={0}
                inputProps={{ min: 0 }}
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('Cancel')}</Button>

          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            disabled={!isFormValid}
            loading={isLoading}
          >
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </ResponsiveDialog>
    </>
  )
}

export default AddPaymentDialog
