import { Delete, Edit } from '@mui/icons-material'
import {
  Breadcrumbs,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { USERS_PATH } from 'AppRoutes'
import { deleteUser, getUser, putUser } from 'data/api'
import DeleteConfirmationDialog from 'features/components/DeleteConfirmationDialog'
import InactiveButtonGroup from 'features/components/InactiveButtonGroup'
import NavigationMenu from 'features/menu/NavigationMenu'
import { useMe } from 'features/ProtectedRoute'
import EditUserDialog from 'features/users/EditUserDialog'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
import { showError, showInfoMessage } from 'utils/notifier'
import Child from '../components/Child'
import User from '../components/User'
import UserOrganizationsTable from './UserOrganizationsTable'

const UserPage = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams<keyof { id: string }>() as { id: string }
  const isSmallScren = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )
  const queryClient = useQueryClient()
  const { user } = useMe()

  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false)
  const [isDeleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false)

  const { data: userProfile, isLoading: userIsLoading } = useQuery(
    ['users', { id }],
    () => getUser(id),
    {
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: updateUser, isLoading: updateUserIsLoading } = useMutation(
    putUser,
    {
      onSuccess: async () => {
        //TODO: set data directly ?
        await queryClient.invalidateQueries(['users', { id }])
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: removeUser, isLoading: removeUserIsLoading } = useMutation(
    async () => deleteUser(id),
    {
      onSuccess: () => {
        showInfoMessage(snackbar, t('User deleted'))
        navigate(USERS_PATH)
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const handleInactiveChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: boolean
  ) => {
    updateUser({ id, inactive: newValue })
  }

  const isLoading = userIsLoading || updateUserIsLoading || removeUserIsLoading

  return (
    <NavigationMenu
      progress={isLoading}
      title={
        <Breadcrumbs sx={{ color: 'inherit' }}>
          <Link
            underline="hover"
            variant="h6"
            color="inherit"
            component={RouterLink}
            to={USERS_PATH}
          >
            {t('Users')}
          </Link>
          {userProfile && (
            <Typography variant="h6">{`${user.firstName} ${user.lastName}`}</Typography>
          )}
        </Breadcrumbs>
      }
      toolbarChildren={
        userProfile &&
        user.role === 'admin' && (
          <Stack direction="row" spacing={1}>
            <IconButton
              color="inherit"
              onClick={() => setIsEditUserDialogOpen(true)}
            >
              <Edit />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => setDeleteUserDialogOpen(true)}
            >
              <Delete />
            </IconButton>
          </Stack>
        )
      }
    >
      <Container>
        {userProfile && (
          <Stack spacing={4} mt={4} mb={4}>
            <Card>
              <CardContent>
                <User user={userProfile} />
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction={isSmallScren ? 'column' : 'row'}
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={2}
                  mb={1}
                >
                  <Grid>
                    <InactiveButtonGroup
                      onChange={handleInactiveChange}
                      value={user.inactive}
                    />
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
            {userProfile.children.length > 0 && (
              <Stack spacing={2}>
                <Typography variant="h6" color="text.secondary">
                  {t('Children')}
                </Typography>

                {userProfile.children.map((child) => (
                  <Card key={child.id}>
                    <CardContent>
                      <Child child={child} />
                    </CardContent>
                  </Card>
                ))}
              </Stack>
            )}
            {(user.role === 'organizer' ||
              user.role === 'organizer-premium' ||
              user.role === 'admin') && (
              <Paper>
                <UserOrganizationsTable
                  userOrganizations={userProfile.organizations}
                />
              </Paper>
            )}
          </Stack>
        )}

        {userProfile && (
          <>
            <EditUserDialog
              open={isEditUserDialogOpen}
              onClose={() => setIsEditUserDialogOpen(false)}
              user={userProfile}
            />
            <DeleteConfirmationDialog
              open={isDeleteUserDialogOpen}
              onClose={() => setDeleteUserDialogOpen(false)}
              onConfirm={removeUser}
              entityName={`${userProfile.firstName} ${userProfile.lastName}`}
            />
          </>
        )}
      </Container>
    </NavigationMenu>
  )
}

export default UserPage
