import { User as FirebaseUser } from 'firebase/auth'
import { Address } from './Address'
import { Child } from './Child'
import { UserOrganization } from './OrganizationUser'

export type User = {
  id: string
  createdAt: string
  firstName: string
  lastName: string
  email: string
  address?: Address
  phoneNumber?: string
  role: UserRole | null
  inactive: boolean
  nationalRegistrationNumber?: string
  sex?: ['male', 'female', 'other'] | null
  mutuality?: string
}

export const userRoles = [
  'admin',
  'organizer-premium',
  'organizer',
  'parent',
] as const
export type UserRole = typeof userRoles[number]

export type UserDetails = User & {
  children: Child[]
  organizations: UserOrganization[]
}

export type UsersQuery = {
  offset: number
  limit: number
  name?: string
  administrativeArea?: string
  role?: UserRole
  inactive?: boolean
}

export type SessionUser = Omit<FirebaseUser, 'phoneNumber'> & {
  token: string
} & UserDetails

export type UpdateUser = Partial<CreateUser> & { id: string }

export type CreateUser = Omit<User, 'createdAt'>
