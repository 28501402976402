import { AccountCircle } from '@mui/icons-material'
import {
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
} from '@mui/material'
import FormPassword from 'components/FormPassword'
import FormTextField from 'components/FormTextField'
import config from 'config/config'
import { CreateUser } from 'data/models/User'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

export type SignUpForm = {
  email: string
  password: string
  isLegalChecked: boolean
} & CreateUser

const SignUp = ({
  onSignUp,
  isLoading = false,
}: {
  onSignUp: (data: Omit<SignUpForm, 'isLegalChecked'>) => void
  isLoading: boolean
}) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    control,
    formState: { isValid: isFormValid },
  } = useForm<SignUpForm>({ mode: 'all' })

  const submitHandler = (data: SignUpForm) => {
    const { isLegalChecked, ...properties } = data
    onSignUp(properties)
  }

  return (
    <Stack
      direction="column"
      spacing={4}
      onSubmit={handleSubmit(submitHandler)}
      mt={4}
      justifyContent="center"
      alignItems="center"
      component="form"
    >
      <Avatar sx={{ width: 80, height: 80 }}>
        <AccountCircle sx={{ width: 50, height: 50 }} />
      </Avatar>

      <FormTextField
        control={control}
        name="email"
        required
        fullWidth
        label={t('Email address')}
      />
      <FormPassword
        control={control}
        name="password"
        rules={{
          minLength: {
            value: 6,
            message: t('Password must have at least 6 characters'),
          },
        }}
        required
        fullWidth
        label={t('Password')}
      />
      <FormTextField
        control={control}
        name="firstName"
        label={t('First name')}
        fullWidth
        required
      />
      <FormTextField
        control={control}
        name="lastName"
        label={t('Last name')}
        fullWidth
        required
      />
      <Controller
        name="isLegalChecked"
        control={control}
        defaultValue={false}
        rules={{
          required: t('Required'),
        }}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            control={<Checkbox checked={value} onChange={onChange} />}
            label={
              <Trans i18nKey="acceptTerms">
                {t('I have read and agree to the')}{' '}
                <Link href={config.termsUrl} target="_blank">
                  {t('Terms and Conditions')}
                </Link>{' '}
                {t('and the')}{' '}
                <Link href={config.privacyUrl} target="_blank">
                  {t('Disclaimer')}
                </Link>
              </Trans>
            }
          />
        )}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={isLoading || !isFormValid}
      >
        {t('Sign-Up')}
      </Button>
    </Stack>
  )
}
export default SignUp
