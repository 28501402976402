import { TFunction } from 'i18next'
import { ProviderContext } from 'notistack'

export const showError = (
  snackbar: ProviderContext,
  e: unknown,
  t: TFunction,
  hide = !(e instanceof Error && e.name == 'Bad Request')
) => {
  const isProduction = window.location.href.includes('web.happykids.app')
  if (e instanceof Error) {
    if (isProduction && hide) {
      showErrorMessage(snackbar, t('Oops, something went wrong...'))
    } else if (e.name === 'Bad Request') {
      showErrorMessage(snackbar, e.message)
    } else {
      showErrorMessage(snackbar, `${e.name}: ${e.message}`)
    }
  } else if (typeof e === 'string') {
    if (isProduction && hide) {
      showErrorMessage(snackbar, t('Oops, something went wrong...'))
    } else {
      showErrorMessage(snackbar, t(e))
    }
  } else {
    showErrorMessage(snackbar, t('Oops, something went wrong...'))
  }
}

export const showErrorMessage = (snackbar: ProviderContext, message: string) =>
  snackbar.enqueueSnackbar(message, {
    variant: 'error',
    persist: true,
    style: { whiteSpace: 'pre-line' },
  })

export const showInfoMessage = (snackbar: ProviderContext, message: string) =>
  snackbar.enqueueSnackbar(message, {
    variant: 'info',
    persist: false,
  })
