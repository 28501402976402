import LoadingButton from '@mui/lab/LoadingButton'
import { DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import { getOrganizationPath } from 'AppRoutes'
import FormSelect from 'components/FormSelect'
import FormTextField from 'components/FormTextField'
import ResponsiveDialog from 'components/ResponsiveDialog'
import { postOrganization, putOrganization } from 'data/api'
import { administrativeAreas } from 'data/models/Address'
import {
  CreateOrganization,
  OrganizationDetails,
} from 'data/models/Organization'
import ImageDialog from 'features/components/ImageDialog'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { showError, showInfoMessage } from 'utils/notifier'

type EditOrganizationForm = CreateOrganization

const EditOrganizationDialog = ({
  organization,
  open,
  onClose,
}: {
  organization?: OrganizationDetails
  open: boolean
  edit?: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const [defaultAdministrativeArea, setDefaultAdministrativeArea] = useState('')

  useEffect(() => {
    setDefaultAdministrativeArea('')
  }, [t])

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isDirty },
  } = useForm<EditOrganizationForm>({
    mode: 'all',
    defaultValues: {
      ...organization,
      address: organization?.address
        ? {
            ...organization?.address,
          }
        : undefined,
    },
  })
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const snackbar = useSnackbar()
  const [isOrganizationImageDialogOpen, setIsOrganizationImageDialogOpen] =
    useState(false)

  const { mutate: addOrganization, isLoading: addOrganizationLoading } =
    useMutation(postOrganization, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['organizations'])
        showInfoMessage(snackbar, t('Organization added'))
        onClose()
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const { mutate: updateOrganization, isLoading: updateOrganizationLoading } =
    useMutation(putOrganization, {
      onSuccess: async (data, variables) => {
        if (variables.id !== data.id) {
          navigate(getOrganizationPath(data.id), {
            replace: true,
          })
        }
        await queryClient.invalidateQueries([
          'organizations',
          { id: variables.id },
        ])
        showInfoMessage(snackbar, t('Organization updated'))
        onClose()
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const onSubmit = (data: EditOrganizationForm): void => {
    const phoneNumbers = data.phoneNumbers?.filter((v) => v)
    const websites = data.websites?.filter((v) => v)
    const editOrganization = { ...data, phoneNumbers, websites }
    const name = data.id.trim()

    organization
      ? updateOrganization({
          id: organization.id.trim(),
          organization: editOrganization,
        })
      : addOrganization({
          ...data,
          id: name,
        })
  }

  const handleImageSelected = (image: string) => {
    setIsOrganizationImageDialogOpen(false)
    setValue('logo', image, { shouldDirty: true })
  }

  const isFormValid = isDirty
  const isLoading = addOrganizationLoading || updateOrganizationLoading

  return (
    <ResponsiveDialog
      title={t('Edit organization')}
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name="id"
              label={t('Name')}
              data-role="name-input"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name="description"
              label={t('Description')}
              data-role="description-input"
              fullWidth
              multiline
              rows={10}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name="facebook"
              label={t('Facebook')}
              data-role="facebook-input"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name="email"
              label={t('Email')}
              data-role="email-input"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name="phoneNumbers[0]"
              label={t('Phone number')}
              data-role="phone-input"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name="phoneNumbers[1]"
              label={t('Phone number')}
              data-role="phone-input"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name="websites[0]"
              label={t('Website')}
              data-role="website-input"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name="websites[1]"
              label={t('Website')}
              data-role="website-input"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <Typography variant="subtitle1" color="text.secondary">
              {t('Address')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name="address.street"
              label={t('Street')}
              data-role="street-input"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              control={control}
              name="address.postalCode"
              label={t('Postal code')}
              data-role="postCode-input"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              control={control}
              name="address.city"
              label={t('City')}
              data-role="city-input"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              control={control}
              name="address.administrativeArea"
              label={t('Administrative area')}
              data-role="area-input"
              options={Object.values(administrativeAreas)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              control={control}
              name="address.country"
              label={t('Country')}
              data-role="country-input"
              options={[t('Belgium')]}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          data-role="submit-button"
          disabled={!isFormValid}
          loading={isLoading}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
      <ImageDialog
        open={isOrganizationImageDialogOpen}
        onClose={() => setIsOrganizationImageDialogOpen(false)}
        onImageSelected={handleImageSelected}
      />
    </ResponsiveDialog>
  )
}

export default EditOrganizationDialog
