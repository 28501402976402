import { Organization } from './Organization'
import { User } from './User'

export const organizerRoles = ['owner', 'editor'] as const
export type OrganizerRole = typeof organizerRoles[number]

export type UserOrganization = {
  organization: Organization
  role: OrganizerRole
}

export type OrganizationUser = {
  user: User
  role: OrganizerRole
}

export type CreateOrganizer = {
  user: string
  organization: string
  role: OrganizerRole
}
